export default {
  name: '313c',
  recruitment_game: 'Recruitment Game',
  back: 'Back',
  cancel: 'Cancel',
  continue: 'Continue',
  skip: 'Skip',
  start: 'Start',
  close: 'Close',
  help: 'Help',
  time_left: 'Remaining time',
  progress: 'Progress',
  privacy_policy: 'Privacy policy',
  cookies_policy: 'Cookies policy & Conditions',
  faq: 'Questions & Answers',
  messages: 'Messages',
  unread: 'unread',
  start_recording: 'Start recording',
  stop_recording: 'Stop recording',
  remove_record: 'Remove record',
};
