<template>
  <div class="back-button">
    <div
      class="back-button__content"
      @click="goBack"
    >
      <div class="back-button__icon">
        <Icon name="arrow-right" />
      </div>
      <div>{{ $t("game.back") }}</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Icon from '@/components/Icon/Icon';

export default {
  name: 'BackButton',
  components: {
    Icon,
  },
  methods: {
    ...mapActions('flow', ['redirectToCurrentStage']),
    async goBack() {
      if (window.history.length <= 1) {
        await this.redirectToCurrentStage();

        return;
      }
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.back-button {
  display: flex;
  color: var(--primary-color);
  &__content {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.6rem;
    height: 4.6rem;
    border-color: var(--primary-color);
    @include media-min($lg) {
      border: 2px solid;
      border-radius: 50%;
      margin-right: 1.6rem;
    }
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
