<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="label"
    :rules="rules"
    mode="eager"
  >
    <Label
      :label="label"
      :tooltip="tooltip"
      :required="required"
    />
    <div
      class="base-phone-input"
      :class="{ 'vue-tel-input--error': errors.length }"
    >
      <vue-tel-input
        v-model="innerValue"
        v-bind="options"
        @input="savePhoneNumberWithPrefix"
        @country-changed="countryCodeChanged"
      />
      <ValidationError :errors="errors" />
    </div>
  </ValidationProvider>
</template>
<script>
import { mapGetters } from 'vuex';
import { VueTelInput } from 'vue-tel-input';
import Label from '@/components/Form/Partials/Label';
import ValidationError from '@/components/Form/Partials/ValidationError';
import * as localeTypes from '@/names/locales.names';

const TYPES_LANG_TO_COUNTRY_CODES = {
  EN: 'GB',
  CS: 'CZ',
  DE: 'DE',
  FR: 'FR',
  HU: 'HU',
  IT: 'IT',
  NL: 'NL',
  RO: 'RO',
  SK: 'SK',
  PL: 'PL',
  HR: 'HR',
  PT: 'PT',
  SL: 'SI',
  UA: 'UA',
};

export default {
  name: 'BasePhoneInput',
  components: {
    VueTelInput,
    Label,
    ValidationError,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {
      innerValue: '',
      phoneNumberLength: 15,
      options: {
        customValidate: /^[0-9 -]*$/,
        autoFormat: true,
        defaultCountry: localeTypes.PL,
        preferredCountries: [localeTypes.PL],
        inputOptions: {
          placeholder: '',
          maxlength: 12,
        },
        dropdownOptions: {
          showFlags: true,
          showDialCodeInSelection: true,
          showDialCodeInList: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters('settings', ['locale']),
  },
  created() {
    if (this.placeholder) {
      this.options.inputOptions.placeholder = this.placeholder;
    }
    this.setDefaultCountry();
  },
  methods: {
    savePhoneNumberWithPrefix(number, phoneObject) {
      this.$emit('input', phoneObject.number);
    },
    countryCodeChanged(countryObject) {
      const countryCodeLength = countryObject.dialCode.length + 1;
      this.options.inputOptions.maxlength = this.phoneNumberLength - countryCodeLength;
    },
    setDefaultCountry() {
      this.options.defaultCountry = this.convertLangToCountry();
      this.options.preferredCountries = [this.convertLangToCountry()];
    },
    convertLangToCountry() {
      if (TYPES_LANG_TO_COUNTRY_CODES[this.locale] !== undefined) {
        return TYPES_LANG_TO_COUNTRY_CODES[this.locale];
      }

      return localeTypes.PL;
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
.base-phone-input {
  position: relative;
  &--error {
    position: absolute;
    font-size: 1.2rem;
    line-height: 1.6rem;
    left: 0;
    top: 100%;
    padding: 1rem 0;
    color: $red;
  }
}
.vue-tel-input--error {
  .vue-tel-input,
  .vue-tel-input:focus-within {
    border: 1px solid $red;
    input {
      color: $red;
    }
  }
}
.vue-tel-input {
  border-radius: 4px;
  border: none;
  padding: 1.3rem 1.6rem;
  border: 1px solid $gray400;
  transition: 0.3s all;
  &:focus-within {
    box-shadow: none;
    border: 1px solid var(--primary-color);
  }
}
.vti__dropdown:hover {
  background-color: $white;
}
.vti__dropdown {
  padding-left: 0;
  &.open {
    background-color: $white;
    .vti__dropdown-arrow {
      color: var(--primary-color);
    }
  }
}
.vti__selection {
  .vti__country-code {
    color: $black;
    font-size: 1.6rem;
    margin: 0 0.8rem;
  }
  .vti__dropdown-arrow {
    color: $gray600;
    transform: scaleY(0.7);
    transition: 0.3s color;
  }
}
.vti__dropdown-list {
  margin-top: 6px;
  max-height: 300px;
  max-width: 600px;
  min-width: 300px;
  border: none;
  box-shadow: 0px 8px 16px rgba(134, 151, 177, 0.48);
}
.vti__dropdown-item {
  padding: 1rem 2rem;
  .vti__flag {
    margin-right: 1rem;
  }
  strong {
    font-weight: 400;
  }
}
.vti__input {
  padding-left: 1.2rem;
  &:focus {
    .vue-tel-input {
      border: 1px solid var(--primary-color) !important;
    }
  }
}
</style>
