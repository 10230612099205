export default {
  name: '313C',
  recruitment_game: 'Prova di Recruimento',
  back: 'Indietro',
  cancel: 'Annulla',
  continue: 'Continua',
  skip: 'Salta',
  start: 'Inizio',
  close: 'Chiusura',
  help: 'Aiuto',
  time_left: 'Tempo rimasto',
  progress: 'Progresso',
  privacy_policy: 'Politica sulla riservatezza',
  cookies_policy: 'Cookie policy e Condizioni',
  faq: 'Domande e risposte',
  messages: 'Messaggi',
  unread: 'non letta',
  start_recording: 'Avviare la registrazione',
  stop_recording: 'Interrompere la registrazione',
  remove_record: 'Rimuovere la registrazione',
};
