export default {
  isVideoRecordingWorks() {
    const platform = window.navigator.platform.toLowerCase();
    const userAgent = window.navigator.userAgent.toLowerCase();

    const isWindows = platform.includes('win');
    const isMacOS = platform.includes('mac') || platform.includes('darwin');
    const isUbuntu = platform.includes('linux');
    const isAndroid = platform.includes('android');
    const isIOS = platform.includes('iphone') || platform.includes('ipad');
    const isChrome = userAgent.includes('chrome') && !userAgent.includes('miuibrowser');
    const isFirefox = userAgent.includes('firefox');
    const isOpera = userAgent.includes('opr') || userAgent.includes('opera');
    const isEdge = userAgent.includes('edg');
    const isSafari = userAgent.includes('safari');

    const isSupportedOS = (isMacOS && (isChrome || isFirefox || isOpera || isSafari || isEdge))
      || (isWindows && (isChrome || isFirefox || isOpera || isEdge))
      || (isUbuntu && (isChrome || isFirefox || isOpera))
      || (isAndroid && (isChrome || isFirefox || isOpera))
      || (isIOS && isSafari);

    return isSupportedOS;
  },
};
