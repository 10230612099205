<template>
  <div>
    <video
      ref="video"
      playsinline
      @play="startPlaying"
      @pause="stopPlaying"
      @click="togglePlay"
      @volumechange="changeVolume"
      @ended="$emit('end-video')"
      @contextmenu.prevent
    >
      <source
        v-for="(video, index) in videos"
        :key="index"
        :type="video.type"
        :src="video.url"
      >
    </video>
    <PlayerStatus
      :is-playing="isPlaying"
      :is-started="isStarted"
    />
    <PlayerToolbar
      :is-playing="isPlaying"
      :is-started="isStarted"
      :is-muted="isMuted"
      :close-text="$t('game.skip')"
      @toggle-play="togglePlay"
      @toggle-sound="toggleSound"
      @toggle-replay="toggleReplay"
      @close-video="$emit('close-video')"
    />
  </div>
</template>

<script>
import PlayerStatus from '@/components/Video/Partials/PlayerStatus';
import PlayerToolbar from '@/components/Video/Partials/PlayerToolbar';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'Player',
  components: {
    PlayerStatus,
    PlayerToolbar,
  },
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      variables,
      isStarted: false,
      isPlaying: false,
      isMuted: false,
    };
  },
  mounted() {
    document.addEventListener('keyup', this.listenForPressingKeys);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.listenForPressingKeys);
  },
  methods: {
    listenForPressingKeys(e) {
      if (e.keyCode === 32) {
        this.togglePlay();
      }
    },
    async playVideo() {
      const { video } = this.$refs;
      if (!video) return;
      try {
        await video.play();
        this.startPlaying();
      } catch (err) {
        this.stopPlaying();
      }
    },
    setDefaultTime() {
      const { video } = this.$refs;
      video.currentTime = 0;
    },
    startPlaying() {
      this.isStarted = true;
      this.isPlaying = true;
    },
    stopPlaying() {
      this.isPlaying = false;
    },
    changeVolume() {
      const { video } = this.$refs;
      this.isMuted = video.muted;
    },
    togglePlay() {
      const { video } = this.$refs;

      if (video.paused) {
        this.playVideo();

        return;
      }

      video.pause();
    },
    toggleSound() {
      const { video } = this.$refs;
      video.muted = !video.muted;
    },
    toggleReplay() {
      const { video } = this.$refs;
      this.setDefaultTime();

      if (video.paused) {
        video.play();
      }
    },
  },
};
</script>
