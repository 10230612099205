<template>
  <div
    v-if="!isLoading && !error && !progress"
    class="recorder-controls"
  >
    <div class="recorder-controls__buttons">
      <button
        v-if="!finished && !isRecording"
        class="recorder-controls__button recorder-controls__button--primary"
        @click="$emit('start-recording')"
      >
        <Icon
          name="camera"
          :color="variables.white"
        />
        <span>{{ $t("game.start_recording") }}</span>
      </button>
      <button
        v-if="!finished && isRecording"
        class="recorder-controls__button recorder-controls__button--primary"
        @click="$emit('stop-recording')"
      >
        <Icon
          name="stop"
          :color="variables.white"
        />
        <span>{{ $t("game.stop_recording") }}</span>
      </button>
      <button
        v-if="finished"
        class="recorder-controls__button"
        @click="$emit('reset-video')"
      >
        <Icon
          name="delete"
          :color="interfaceColor"
        />
        <span>{{ $t("game.remove_record") }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon/Icon';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'RecorderControls',
  components: {
    Icon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isRecording: {
      type: Boolean,
      required: true,
    },
    finished: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    variables,
  }),
  computed: {
    ...mapGetters('settings', ['interfaceColor']),
  },
};
</script>
<style lang="scss" scoped>
.recorder-controls {
  margin-top: 1.8rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @include media-min($sm) {
    flex-direction: row;
  }
  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__button {
    margin-right: 1rem;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 0.4rem;
    background: $white;
    width: 100%;
    height: 5.5rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
    justify-content: space-between;
    gap: 1.2rem;
    white-space: nowrap;
    padding: 0 2rem;

    svg {
      display: block;
      min-width: 2.4rem;
      min-height: 2.4rem;
      width: 2.4rem;
      height: 2.4rem;
    }

    &--primary {
      background: var(--primary-color);

      span {
        color: $white;
      }
    }
  }
}
</style>
<style>
.vjs-record .vjs-device-button.vjs-control {
  display: none;
}
</style>
