<template>
  <div class="player-toolbar__wrapper">
    <div class="player-toolbar__controls">
      <button
        class="player-toolbar__icon"
        @click="$emit('toggle-play')"
      >
        <Icon
          :name="isPlaying ? 'pause' : 'play'"
          :color="variables.white"
        />
      </button>
      <button
        class="player-toolbar__icon"
        @click="$emit('toggle-sound')"
      >
        <Icon
          :name="isMuted ? 'volume-muted' : 'volume-high'"
          :color="variables.white"
        />
      </button>
      <button
        class="player-toolbar__icon"
        @click="$emit('toggle-replay')"
      >
        <Icon
          name="replay"
          :color="variables.white"
        />
      </button>
    </div>
    <div class="player-toolbar__controls">
      <button @click="$emit('close-video')">
        <span>{{ closeText }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/Icon/Icon';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'PlayerToolbar',
  components: {
    Icon,
  },
  props: {
    isPlaying: {
      type: Boolean,
      required: true,
    },
    isStarted: {
      type: Boolean,
      required: true,
    },
    isMuted: {
      type: Boolean,
      required: true,
    },
    closeText: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    variables,
  }),
};
</script>
<style lang="scss" scoped>
.player-toolbar {
  &__wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover ~ ::v-deep .player-gradient {
      opacity: 0.7;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    margin: 10px 40px 10px 20px;
    button {
      background: transparent;
      outline: none;
    }
    span {
      color: $white;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
  }

  &__close {
    position: fixed;
    top: 10px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    width: 4rem;
    height: 4rem;

    svg {
      fill: $gray400;
      transition: 0.2s fill;
      &:hover {
        fill: $white;
      }
    }
  }
}
</style>
