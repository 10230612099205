export default {
  name: '313C',
  recruitment_game: 'Рекрутингова гра',
  back: 'назад',
  cancel: 'Скасувати',
  continue: 'Продовжити',
  skip: 'Пропустити',
  start: 'Старт',
  close: 'Закрити',
  help: 'Довідка',
  time_left: 'Залишилось часу',
  progress: 'Прогрес',
  privacy_policy: 'Політика конфіденційності',
  cookies_policy: 'Політика використання файлів cookie і Умови',
  faq: 'Питання і відповіді',
  messages: 'Повідомлення',
  unread: 'непрочитана',
  start_recording: 'Почати запис',
  stop_recording: 'Зупинити запис',
  remove_record: 'Видалити запис',
};
