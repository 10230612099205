export default {
  checkbox_required: 'Označit: %{name}',
  required: 'Zadejte: %{name}',
  phone_incorrect: 'Zadejte platné telefonní číslo',
  min_length: 'Minimální počet znaků je: %{length}',
  max_length: 'Maximální počet znaků je: %{length}',
  max_file_size: 'Maximální velikost souboru je %{size} mb',
  recaptcha: 'Označte ReCaptcha',
  email_incorrect: 'Nesprávný formát',
  max_value: 'Maximální hodnota musí být %{value} nebo méně',
  min_value: 'Minimální hodnota musí být %{value} nebo více',
  numeric: 'Nesprávný formát čísla',
  numeric_required: '%{name} je povinné a může obsahovat pouze celá čísla',
};
