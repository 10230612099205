<template>
  <div>
    <transition
      appear
      @before-enter="beforeEnter"
      @enter="enter"
    >
      <component
        :is="stageComponent.name"
        v-if="currentStage"
        :key="currentStage.id"
        :is-loading="isLoading"
        v-bind="stageComponent.params"
        @completed-show-badges="stageCompletedShowAllBadges"
        @completed="stageCompleted"
      />
    </transition>
    <Loader
      v-if="areAllStagesCompleted"
      :is-component-loader="true"
    />
  </div>
</template>
<script>
import gsap from 'gsap';
import { mapGetters, mapActions } from 'vuex';
import alerts from '@/common/alerts/alerts';
import { BADGES } from '@/router/routes.names';
import BestWorstGameQuestion from '@/domain/entities/flow/BestWorstGameQuestion';
import ClosedGameQuestion from '@/domain/entities/flow/ClosedGameQuestion';
import GameMessage from '@/domain/entities/flow/GameMessage';
import GameVideo from '@/domain/entities/flow/GameVideo';
import GameImage from '@/domain/entities/flow/GameImage';
import GameRecord from '@/domain/entities/flow/GameRecord';
import { HTTP_UNAUTHORIZED } from '@/common/axios/codes';

export default {
  name: 'Game',
  components: {
    Loader: () => import('@/components/Loader/Loader'),
    BestWorstGameQuestion: () => import('@/components/Stage/BestWorstGameQuestion'),
    ClosedGameQuestion: () => import('@/components/Stage/ClosedGameQuestion'),
    GameMessage: () => import('@/components/Message/GameMessage'),
    GameVideo: () => import('@/components/Stage/GameVideo'),
    GameImage: () => import('@/components/Stage/GameImage'),
    GameRecord: () => import('@/components/Stage/GameRecord'),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('flow', [
      'currentStage',
      'questionsCount',
      'completedQuestionsCount',
      'messagesToCurrentStage',
      'areAllStagesCompleted',
    ]),
    stageComponent() {
      switch (true) {
        case this.currentStage instanceof BestWorstGameQuestion:
          return {
            name: 'BestWorstGameQuestion',
            params: {
              stage: this.currentStage,
              questionsCount: this.questionsCount,
              currentQuestionNumber: this.completedQuestionsCount + 1,
            },
          };
        case this.currentStage instanceof ClosedGameQuestion:
          return {
            name: 'ClosedGameQuestion',
            params: {
              stage: this.currentStage,
              questionsCount: this.questionsCount,
              currentQuestionNumber: this.completedQuestionsCount + 1,
            },
          };
        case this.currentStage instanceof GameMessage:
          return {
            name: 'GameMessage',
            params: {
              stage: this.currentStage,
              messages: this.messagesToCurrentStage,
            },
          };
        case this.currentStage instanceof GameVideo:
          return {
            name: 'GameVideo',
            params: {
              stage: this.currentStage,
            },
          };
        case this.currentStage instanceof GameImage:
          return {
            name: 'GameImage',
            params: {
              stage: this.currentStage,
            },
          };
        case this.currentStage instanceof GameRecord:
          return {
            name: 'GameRecord',
            params: {
              stage: this.currentStage,
              questionsCount: this.questionsCount,
              currentQuestionNumber: this.completedQuestionsCount + 1,
            },
          };
        default:
          throw new Error(
            `Unknown component to render for stage ${this.currentStage.constructor.name} type`,
          );
      }
    },
  },
  mounted() {
    document.addEventListener('copy', this.blockCopyListener);
  },
  beforeDestroy() {
    this.removeBlockCopyListener();
  },
  methods: {
    ...mapActions('flow', ['markStageAsCompleted', 'redirectToCurrentStage']),
    ...mapActions('session', ['markGameAsFinished']),
    ...alerts,
    async stageCompleted() {
      await this.completeGameStage();
      await this.redirectToCurrentStage();
    },
    async stageCompletedShowAllBadges() {
      await this.completeGameStage();
      await this.$router.push({ name: BADGES, params: this.$route.params });
    },
    async completeGameStage() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        await this.markStageAsCompleted(this.currentStage);
        if (this.areAllStagesCompleted) {
          await this.markGameAsFinished();
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          return;
        }
        this.warningAlert();
        throw new Error(JSON.stringify('meta' in (e.response?.data || {}) ? (({ meta, ...errors }) => errors)(e.response.data) : e.response?.data));
      } finally {
        this.isLoading = false;
      }
    },
    beforeEnter(el) {
      gsap.set(el, { autoAlpha: 0 });
    },
    enter(el, done) {
      const animatedStages = ['BestWorstGameQuestion', 'ClosedGameQuestion', 'RecordQuestion'];
      if (animatedStages.includes(this.stageComponent.name)) {
        gsap
          .timeline()
          .fromTo(el, {
            autoAlpha: 0,
          }, {
            autoAlpha: 1,
            duration: 0.4,
          })
          .from('.heading__content', {
            autoAlpha: 0,
            duration: 0.8,
            ease: 'power3.out',
          }, '<')
          .from('.heading__intro', {
            autoAlpha: 0,
            duration: 0.8,
            ease: 'power3.out',
          }, '<')
          .from('.heading__title', {
            autoAlpha: 0,
            duration: 0.8,
            ease: 'power3.out',
          }, 0.2)
          .from('.question-image', {
            autoAlpha: 0,
            duration: 0.8,
            ease: 'power3.out',
          }, '<')
          .from('.instruction', {
            autoAlpha: 0,
            x: 5,
            duration: 0.8,
            ease: 'power3.out',
          }, '<')
          .fromTo('.answers', {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.8,
            ease: 'power3.out',
            stagger: {
              amount: 0.4,
            },
          }, '<')
          .from('.question__buttons', {
            autoAlpha: 0,
            duration: 0.8,
            ease: 'power3.out',
            onComplete: done,
          }, 0.8);

        return;
      }

      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.4,
          onComplete: done,
        },
      );
    },
    blockCopyListener(e) {
      e.preventDefault();
    },
    removeBlockCopyListener() {
      document.removeEventListener('copy', this.blockCopyListener);
    },
  },
};
</script>
