export default {
  choose: 'Choose',
  badges: {
    title: 'Badges',
    badge: 'Badge',
    my_badges: 'My Badges',
    received: 'You get a badge',
    continue: 'Continue playing',
    show_badges: 'View badges',
    show_badge: 'See the reward',
  },
  record_test: {
    intro: 'CAMERA AND MICROPHONE SETTINGS TEST',
    content: 'Before starting the tool, test your settings. In the field below, you should see the image from your camera. If you see it, it means that your settings are most likely correct - to be sure, record and playback a video. If you do not see the image from the camera, you cannot playback the recording, or you have other doubts, click the “TROUBLESHOOT” button.',
    troubleshoot: 'TROUBLESHOOT',
  },
  questions: {
    progress: 'Question',
    accept: 'Confirm',
    question_has_already_been_answered: 'This question has already been answered.',
  },
  not_found: {
    code: '404',
    title: 'This page does not exist',
    content: 'The page you are looking for has not been found. It could have been removed, its name was changed or it did not exist at all',
  },
};
