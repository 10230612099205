export default {
  warning: {
    title: 'Prišlo je do napake',
    content: 'Poskusite znova, če se napaka ponavlja, nas obvestite na',
    content_without_email: 'Poskusite znova, če se napaka ponavlja, se obrnite na tehnično podporo.',
    confirm: 'Zapri',
  },
  end_time: {
    title: 'Prijavite napako',
    content: 'Če med vašo igro pride do napake, nas kontaktirajte na e-poštnem naslovu:',
  },
  record_video_error: {
    title: 'Ni dostopa do kamere in/ali mikrofona.',
    content: 'Da dokončate to nalogo, spremenite svoje nastavitve in dovolite aplikaciji dostop do kamere in/ali mikrofona.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Ne podprta naprava</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Za nadaljevanje uporabite <strong>drugo napravo in/ali brskalnik.</strong></p>
        <p>Orodje za zaposlovanje, ki ga nameravate uporabiti, vključuje vsaj eno nalogo, pri kateri morate odgovor posneti v video formatu. Žal vsi naprave in/ali brskalniki tega ne omogočajo pravilno.</p>
        <p><strong>Če vidite to zaslon, to pomeni, da vaša naprava in/ali brskalnik ne zagotavljata pravilnega snemanja in pošiljanja video datoteke.</strong> Spodaj boste našli priporočene nastavitve.</p>
        <br>
        <p><strong>Namizne naprave:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Prenosne naprave:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Ste prepričani, da želite preskočiti nalogo?',
    content: '<p>Snemanje videoposnetka odgovora pri tej nalogi ni obvezno, vendar priporočeno - pomagalo nam bo, da vas bolje spoznamo. Ste prepričani, da želite preskočiti snemanje videoposnetka odgovora?</p>',
  },
  skip_stage_with_record: {
    title: 'Ste prepričani, da želite preskočiti nalogo?',
    content: '<p>Nameravate preskočiti shranjevanje posnetega videoodgovora - ne bo na voljo osebi, ki izvaja postopek izbora. Nalaganje videoodgovora za to nalogo ni obvezno, vendar je priporočljivo - pomagalo nam bo, da vas bolje spoznamo. Ste prepričani, da želite preskočiti shranjevanje posnetega videoodgovora?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Odprava težav s kamero in mikrofonom</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Če snemanje ali predvajanje ni pravilno, lahko sledite nekaj korakom, ki bi morali rešiti problem. Najpogostejši vzroki težav izhajajo iz nastavitev naprave, vključno z drugimi programi ali aplikacijami, ki hkrati uporabljajo kamero in/ali mikrofon.</p>
      <p>Možne rešitve:</p>
      <ol>
        <li>Prepričajte se, da ima brskalnik dovoljenja za uporabo mikrofona in kamere. Če ne, spremenite nastavitve in osvežite stran.</li>
        <li>Zagotovite, da nobena druga aplikacija ali program trenutno ne uporablja kamere/mikrofona (npr. MS Teams, Photo Booth ali FaceTime) - zaprite aplikacije, ki blokirajo možnost uporabe kamere preko brskalnika in osvežite stran.</li>
        <li>Preverite, ali je vaš brskalnik posodobljen na najnovejšo različico - če ne, izvedite posodobitev in ponovno izvedite test.</li>
        <li>Če zgornji koraki niso pomagali, poskusite uporabiti drug brskalnik ali napravo.</li>
      </ol>
      <p>Če težava še vedno obstaja, nam sporočite na <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> z informacijami o napravi in brskalniku, na katerih se pojavi težava.</p>
      </div>
      <hr>
    `,
  },
};
