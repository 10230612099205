<template>
  <div class="select-lang">
    <div
      v-for="(lang, index) in languages"
      :key="`language-${index}`"
      class="select-lang__item"
    >
      <label
        :class="{
          active: selectedLang === lang.value,
        }"
      >
        <input
          v-model="selectedLang"
          class="hidden"
          type="radio"
          :value="lang.value"
        >
        <div class="select-lang__wrapper">
          <img
            :src="require(`@/assets/images/countries/${lang.value}.png`)"
            :alt="lang.name"
          >
          <span>
            {{ lang.name }}
          </span>
        </div>
        <CheckmarkAnimated :is-active="selectedLang === lang.value" />
      </label>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n';
import CheckmarkAnimated from '@/components/Icon/Animated/Checkmark';

export default {
  name: 'SelectLang',
  components: {
    CheckmarkAnimated,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedLang: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['locale']),
  },
  watch: {
    selectedLang(newVal) {
      i18n.locale = newVal;
      this.setLocale(newVal);
      this.$emit('input', newVal);
    },
  },
  created() {
    this.setDefaultLang();
  },
  methods: {
    ...mapActions('settings', ['setLocale']),
    setDefaultLang() {
      if (this.locale) {
        this.selectedLang = this.locale;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-lang {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  &__item {
    display: flex;
    width: 100%;
    min-height: 68px;
    @include media-min($sm) {
      width: 49%;
    }
    @include media-min($lg) {
      width: 100%;
    }
    @include media-min($xxl) {
      width: 32.5%;
    }
    img {
      width: 3rem;
      height: 2.2rem;
      object-fit: cover;
    }
    label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border: 1px solid $gray400;
      padding: 1.2rem 1rem  1.2rem 2.2rem;
      background: $white;
      border-radius: 4px;
      transition: all 0.3s ease;
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
      span {
        font-size: 1.4rem;
        padding: 0.2rem 1.5rem 0;
      }
      &.active {
        border: 1px solid var(--primary-color);
      }
      &:hover {
        border: 1px solid var(--primary-color);
      }
      &:focus {
        outline: none;
      }
      input {
        display: none;
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
  }
}
</style>
