import Axios from 'axios';
import { errorResponseHandler } from '@/common/axios/error';
import { env } from '@/helper';

const instance = Axios.create({
  baseURL: env.VUE_APP_API_URL,
  auth: env.VUE_APP_BASIC_AUTH_USERNAME ? {
    username: env.VUE_APP_BASIC_AUTH_USERNAME,
    password: env.VUE_APP_BASIC_AUTH_PASSWORD,
  } : null,
  withCredentials: true,
  params: {
    timestamp: new Date().getTime(),
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseHandler(error),
);

export default instance;
