export default {
  checkbox_required: 'Zaznacz: %{name}',
  required: 'Wpisz: %{name}',
  phone_incorrect: 'Wpisz poprawny numer telefonu',
  min_length: 'Minimalna ilość znaków wynosi: %{length}',
  max_length: 'Maksymalna ilość znaków wynosi: %{length}',
  max_file_size: 'Maksymalny rozmiar pliku wynosi %{size} mb',
  recaptcha: 'Zaznacz Recaptcha',
  email_incorrect: 'Nieprawidłowy format',
  max_value: 'Maksymalna wartość musi wynosić %{value} lub mniej',
  min_value: 'Minimalna wartość musi wynosić %{value} lub więcej',
  numeric: 'Nieprawidłowy format',
  numeric_required: '%{name} jest wymagane i może zawierać wyłącznie liczby całkowite',
};
