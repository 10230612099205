import alerts from '@/trans/cs/alerts';
import cookies from '@/trans/cs/cookies';
import general from '@/trans/cs/general';
import game from '@/trans/cs/game';
import validation from '@/trans/cs/validation';
import auth from '@/trans/cs/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
