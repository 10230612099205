export default {
  name: '313C',
  recruitment_game: 'Rekrutációs Játék',
  back: 'Vissza',
  cancel: 'Mégse',
  continue: 'Folytatás',
  skip: 'Átugrás',
  start: 'Indítás',
  close: 'Bezárás',
  help: 'Segítség',
  time_left: 'Hátralévő Idő',
  progress: 'Előrehaladás',
  privacy_policy: 'Adatvédelmi irányelvek',
  cookies_policy: 'Cookie-kra vonatkozó szabályzat és Feltételek',
  faq: 'Kérdések és Válaszok',
  messages: 'Üzenetek',
  unread: 'nem olvasott',
  start_recording: 'Felvétel indítása',
  stop_recording: 'Felvétel leállítása',
  remove_record: 'Felvétel eltávolítása',
};
