<template>
  <div :style="colorProps">
    <Loader
      v-if="isLoading"
      :is-component-loader="false"
    />
    <template v-else>
      <router-view />
    </template>
    <CookieBar v-if="hasGameData" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import colors from '@/common/colors/colors';
import CookieBar from '@/components/Document/CookieBar';
import Loader from '@/components/Loader/Loader';

export default {
  name: 'App',
  components: {
    Loader,
    CookieBar,
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor', 'hasGameData']),
    ...mapState('layout', ['isLoading']),
    colorProps() {
      return {
        '--primary-color': this.convertColorToRgb(this.interfaceColor),
        '--light-primary-color': this.convertColorToRgb(this.interfaceColor, 0.4),
        '--lighter-primary-color': this.convertColorToRgb(this.interfaceColor, 0.2),
        '--lightest-primary-color': this.convertColorToRgb(this.interfaceColor, 0.05),
      };
    },
  },
  methods: {
    ...colors,
  },
};
</script>
<style lang="scss">
h4, a {
  color: var(--primary-color);
}
</style>
