<template>
  <div class="cookies-links">
    <div @click="goToPrivacyPolicy">
      {{ $t("game.privacy_policy") }}
    </div>
    <div class="cookies-links__dot">
      •
    </div>
    <div @click="goToCookies">
      {{ $t("game.cookies_policy") }}
    </div>
  </div>
</template>
<script>
import { PRIVACY_POLICY, COOKIES } from '@/router/routes.names';

export default {
  name: 'DocumentLink',
  methods: {
    goToPrivacyPolicy() {
      this.$router.push({ name: PRIVACY_POLICY, params: this.$route.params });
    },
    goToCookies() {
      this.$router.push({ name: COOKIES, params: this.$route.params });
    },
  },
};
</script>
<style lang="scss" scoped>
.cookies-links {
  display: flex;
  color: var(--primary-color);
  cursor: pointer;
  &__dot {
    padding: 0 0.4rem;
    color: var(--primary-color);
  }
}
</style>
