import alerts from '@/trans/pt/alerts';
import cookies from '@/trans/pt/cookies';
import general from '@/trans/pt/general';
import game from '@/trans/pt/game';
import validation from '@/trans/pt/validation';
import auth from '@/trans/pt/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
