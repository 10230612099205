export default {
  choose: 'Виберіть',
  badges: {
    title: 'Значки',
    badge: 'Значок',
    my_badges: 'Мої Значки',
    received: 'Ви отримали значок',
    continue: 'Грайте далі',
    show_badges: 'Переглянути значки',
    show_badge: 'побачти нагороду',
  },
  record_test: {
    intro: 'ТЕСТ НАЛАШТУВАНЬ КАМЕРИ ТА МІКРОФОНА',
    content: 'Перед початком користування інструментом протестуйте свої налаштування. У полі нижче ви повинні бачити зображення з вашої камери. Якщо ви його бачите, це означає, що ваші налаштування, ймовірно, правильні - щоб бути впевненими, запишіть та відтворіть відео. Якщо ви не бачите зображення з камери, не можете відтворити запис або маєте інші сумніви, натисніть кнопку «ВИРІШЕННЯ ПРОБЛЕМ».',
    troubleshoot: 'ВИРІШЕННЯ ПРОБЛЕМ',
  },
  questions: {
    progress: 'Питання',
    accept: 'Підтвердити',
    question_has_already_been_answered: 'Це питання вже було відповідане.',
  },
  not_found: {
    code: '404',
    title: 'Сторінка не існує',
    content: 'Сторінку, яку ви шукали, не знайдено. Можливо, її видалили, змінили назву або вона взагалі не існує',
  },
};
