import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { env } from '@/helper';
import MainLayout from '@/layouts/MainLayout';
import authRoutes from '@/router/auth';
import welcomeRoutes from '@/router/welcome';
import finishRoutes from '@/router/finish';
import gameRoutes from '@/router/game';
import documentsRoutes from '@/router/documents';
import recordTestRoutes from '@/router/recordTest';
import errorRoutes from '@/router/error';
import {
  CHOOSE_LANG,
  REGISTER,
  NOT_FOUND,
} from '@/router/routes.names';
import { HTTP_BAD_REQUEST, HTTP_NOT_FOUND, HTTP_UNAUTHORIZED } from '@/common/axios/codes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:recruitmentProcessId/:sessionId?',
    component: MainLayout,
    redirect: {
      name: REGISTER,
    },
    beforeEnter: async (to, from, next) => {
      try {
        store.dispatch('layout/startLoading');
        store.dispatch('session/setCurrentRoute', { currentRoute: to });
        await store.dispatch('settings/fetchData', { recruitmentProcessId: to.params.recruitmentProcessId, sessionId: to.params.sessionId });
        const gameHasCollections = store.getters['settings/gameHasCollections'];
        if (to.params.sessionId) {
          await store.dispatch('session/fetchSession', { sessionId: to.params.sessionId });
        }
        const sessionLocale = store.getters['session/sessionLocale'];
        if (gameHasCollections && to.name !== CHOOSE_LANG) {
          if (!to.params.sessionId) {
            next({ name: CHOOSE_LANG, params: { recruitmentProcessId: to.params.recruitmentProcessId } });

            return;
          }
          if (sessionLocale === null) {
            next({ name: CHOOSE_LANG, params: { recruitmentProcessId: to.params.recruitmentProcessId, sessionId: to.params.sessionId } });

            return;
          }
        }

        next();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          next();

          return;
        }
        if (e.response?.status === HTTP_NOT_FOUND || e.response?.status === HTTP_BAD_REQUEST) {
          next({ name: NOT_FOUND });

          return;
        }
        throw new Error(e);
      } finally {
        store.dispatch('layout/stopLoading');
      }
    },
    children: [
      ...authRoutes,
      welcomeRoutes,
      gameRoutes,
      finishRoutes,
      documentsRoutes,
      recordTestRoutes,
    ],
  },
  ...errorRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: env.BASE_URL,
  routes,
});

export default router;
