<template>
  <div class="badge">
    <template v-if="badge.isCompleted">
      <BaseTooltip>
        <template #trigger>
          <IconExternal
            :url="badge.iconUrl"
            :color="badgeColor"
          />
        </template>
        <template #content>
          {{ badge.body }}
        </template>
      </BaseTooltip>
    </template>
    <template v-else>
      <IconExternal
        :url="badge.iconUrl"
        :color="badgeColor"
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IconExternal from '@/components/Icon/IconExternal';
import BaseTooltip from '@/components/Form/BaseTooltip';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'BadgeItem',
  components: {
    IconExternal,
    BaseTooltip,
  },
  props: {
    badge: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      variables,
    };
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor']),

    badgeColor() {
      return this.badge.isCompleted ? this.interfaceColor : this.variables.gray500;
    },
  },
};
</script>
<style lang="scss" scoped>
.badge {
  padding: 0 0.7rem;
}
</style>
