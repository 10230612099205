import { immerable, produce } from 'immer';

export default class Badge {
  [immerable] = true;

  /**
   * @param {String} body
   * @param {String} description
   * @param {String} icon
   * @param {String} iconUrl
   * @param {String} imageUrl
   * @param {String} video
   * @param {Boolean} isCompleted
   */
  constructor({
    body, description, icon, iconUrl, imageUrl, video, isCompleted,
  }) {
    this._body = body;
    this._description = description;
    this._icon = icon;
    this._iconUrl = iconUrl;
    this._imageUrl = imageUrl;
    this._video = video;
    this._isCompleted = isCompleted;
  }

  /**
   * @return {Badge}
   */
  markAsCompleted() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      draft._isCompleted = true;
    });
  }

  /**
   * @return {Array}
   */
  get videos() {
    if (!this.isVideoBadge) return [];

    return [
      {
        url: this._video.WEBM,
        type: 'video/webm',
      },
      {
        url: this._video.MP4,
        type: 'video/mp4',
      },
    ];
  }

  /**
   * @return {boolean}
   */
  get isVideoBadge() {
    return this._video !== null;
  }

  /**
   * @return {String}
   */
  get description() {
    return this._description;
  }

  /**
   * @return {String}
   */
  get body() {
    return this._body;
  }

  /**
   * @return {String}
   */
  get icon() {
    return this._icon;
  }

  /**
   * @return {String}
   */
  get iconUrl() {
    return this._iconUrl;
  }

  /**
   * @return {String}
   */
  get imageUrl() {
    return this._imageUrl;
  }

  /**
   * @return {Object}
   */
  get video() {
    return this._video;
  }

  /**
   * @return {Boolean}
   */
  get isCompleted() {
    return this._isCompleted;
  }
}
