import alerts from '@/trans/ua/alerts';
import cookies from '@/trans/ua/cookies';
import general from '@/trans/ua/general';
import game from '@/trans/ua/game';
import validation from '@/trans/ua/validation';
import auth from '@/trans/ua/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
