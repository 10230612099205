export default {
  choose: 'Escolher',
  badges: {
    title: 'Distintivos',
    badge: 'Distintivo',
    my_badges: 'Minhas Distintivos',
    received: 'Você recebeu um distintivo',
    continue: 'Continue jogando',
    show_badges: 'Ver distintivos',
    show_badge: 'Ver a recompensa',
  },
  record_test: {
    intro: 'TESTE DE CONFIGURAÇÕES DE CÂMERA E MICROFONE',
    content: 'Antes de começar a usar a ferramenta, teste suas configurações. No campo abaixo, você deve ver a imagem da sua câmera. Se você a vê, significa que suas configurações provavelmente estão corretas - para ter certeza, grave e reproduza um vídeo. Se você não vê a imagem da câmera, não consegue reproduzir a gravação ou tem outras dúvidas, clique no botão "RESOLVER PROBLEMAS".',
    troubleshoot: 'RESOLVER PROBLEMAS',
  },
  questions: {
    progress: 'Pergunta',
    accept: 'Confirmar',
    question_has_already_been_answered: 'Esta pergunta já foi respondida.',
  },
  not_found: {
    code: '404',
    title: 'Página não existe',
    content: 'A página que você procurava não foi encontrada. Pode ter sido excluída, renomeada ou nunca existiu',
  },
};
