<template>
  <div>
    <div class="base-tooltip">
      <button
        ref="tooltip"
        class="base-tooltip__trigger"
        type="button"
      >
        <slot name="trigger" />
      </button>
      <div
        class="base-tooltip__content base-tooltip__content--bottom"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseTooltip',
};
</script>
<style lang="scss" scoped>
.base-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 9998;
  &__content {
    position: absolute;
    background: var(--primary-color);
    color: $white;
    visibility: hidden;
    opacity: 0;
    border-radius: 2px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 1.4rem;
    width: 260px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.3s;
    @media (hover: none) {
      display: none;
    }
    @media (max-width: 767px) {
      display: none;
    }
    &::before {
      content: "";
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__content--top {
    bottom: 180%;
    &::before {
      border-top: 15px solid var(--primary-color);
      top: 100%;
    }
  }
  &__content--bottom {
    top: calc(100% + 2.5rem);
    &::before {
      border-bottom: 15px solid var(--primary-color);
      bottom: 100%;
    }
  }
  &__trigger {
    padding: 0;
    color: var(--primary-color);
    background: $white;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.6rem;
  }
  @media (hover: hover) and (min-width: 768px) {
    &:hover > &__content {
      visibility: visible;
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
}
</style>
