export default {
  checkbox_required: 'Selecteren %{name}',
  required: 'Typ %{name}',
  phone_incorrect: 'Voer een geldig telefoonnummer in',
  min_length: 'Minimum aantal tekens is: %{length}',
  max_length: 'Maximum aantal tekens is: %{length}',
  max_file_size: 'Maximum bestandsgrootte is %{size} mb',
  recaptcha: 'Markeer ReCaptcha',
  email_incorrect: 'Ongeldig formaat',
  max_value: 'De maximale waarde moet %{value} of minder zijn',
  min_value: 'De minimale waarde moet %{value} of meer zijn',
  numeric: 'Ongeldige nummeropmaak',
  numeric_required: '%{name} is verplicht en mag alleen hele getallen bevatten',
};
