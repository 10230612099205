import store from '@/store';

const setFavicon = () => {
  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = store.getters['settings/favicon'];
  document.getElementsByTagName('head')[0].appendChild(link);
};

const setTitle = () => {
  document.title = store.getters['settings/metaTitle'] || store.getters['settings/gameTitle'];
};

const setSiteData = () => {
  setFavicon();
  setTitle();
};

export default setSiteData;
