export default {
  warning: {
    title: 'Došlo je do pogreške',
    content: 'Pokušajte ponovno, a ako se greška nastavi, obavijestite nas na',
    content_without_email: 'Pokušajte ponovno, a ako se greška nastavi, kontaktirajte tehničku podršku.',
    confirm: 'Zatvori',
  },
  end_time: {
    title: 'Prijavi pogrešku',
    content: 'Ako se tijekom igre pojavi greška, molimo vas da nas kontaktirate na e-mailu:',
  },
  record_video_error: {
    title: 'Nema pristupa kameri i/ili mikrofonu.',
    content: 'Da biste završili ovaj zadatak, promijenite svoje postavke i dopustite aplikaciji pristup vašoj kameri i/ili mikrofonu.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nepodržani uređaj</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Da biste nastavili, molimo vas da koristite <strong>drugi uređaj i/ili drugi preglednik.</strong></p>
        <p>Alat za regrutaciju koji nameravate koristiti uključuje najmanje jedan zadatak koji zahteva da snimite svoj odgovor u video formatu. Nažalost, nisu svi uređaji i/ili preglednici u mogućnosti to ispravno omogućiti.</p>
        <p><strong>Ako vidite ovaj ekran, to znači da vaš uređaj i/ili preglednik ne garantuje pravilno snimanje i slanje video datoteke.</strong> U nastavku ćetepronaćipreporučene postavke.</p>
        <br>
        <p><strong>Desktop uređaji:/strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobilni uređaji:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Jeste li sigurni da želite preskočiti zadatak?',
    content: '<p>Snimanje video odgovora za ovaj zadatak nije obavezno, ali se preporučuje - pomoći će nam da vas bolje upoznamo. Jeste li sigurni da želite preskočiti snimanje video odgovora?</p>',
  },
  skip_stage_with_record: {
    title: 'Jeste li sigurni da želite preskočiti zadatak?',
    content: '<p>Namjeravate preskočiti spremanje snimljenog video odgovora - neće biti dostupan osobi koja provodi postupak zapošljavanja. Učitavanje video odgovora za ovaj zadatak nije obavezno, ali se preporučuje - pomoći će nam da vas bolje upoznamo. Jeste li sigurni da želite preskočiti spremanje snimljenog video odgovora?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Rješavanje problema s kamerom i mikrofonom</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Ako snimanje ili reprodukcija nije ispravna, možete pratiti nekoliko koraka koji bi trebali riješiti problem. Najčešći uzroci problema proizlaze iz postavki uređaja, uključujući druge programe ili aplikacije koje istovremeno koriste kameru i/ili mikrofon.</p>
      <p>Moguća rješenja problema:</p>
      <ol>
        <li>Provjerite ima li preglednik dopuštenja za korištenje mikrofona i kamere. Ako ne, promijenite postavke i osvježite stranicu.</li>
        <li>Provjerite ne koristi li neka druga aplikacija ili program trenutačno kameru/mikrofon (npr. MS Teams, Photo Booth ili FaceTime) - zatvorite aplikacije koje blokiraju mogućnost korištenja kamere preglednikom i osvježite stranicu.</li>
        <li>Provjerite je li vaš preglednik ažuriran na najnoviju verziju - ako nije, izvršite ažuriranje i ponovno pokrenite test.</li>
        <li>Ako prethodni koraci nisu pomogli, pokušajte koristiti drugi preglednik ili uređaj.</li>
      </ol>
      <p>Ako problem i dalje postoji, obavijestite nas na <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> šaljući informacije o uređaju i pregledniku na kojima se problem pojavljuje.</p>
      </div>
      <hr>
    `,
  },
};
