<template>
  <div
    ref="badge"
    class="badge"
    :class="{ 'badge--disabled': !badge.isCompleted }"
  >
    <div class="badge__container">
      <div class="badge__wrapper">
        <div
          v-if="badge.isVideoBadge"
          class="badge__video"
          @mouseenter="playVideo"
          @mouseleave="pauseVideo"
          @click="showVideo"
        >
          <FadeIn appear>
            <div
              v-show="showBadgeImage"
              class="badge__image"
              :style="{ backgroundImage: `url(${badge.imageUrl})` }"
            />
          </FadeIn>
          <video
            ref="previewVideo"
            loop
            muted
            class="badge__clip"
          >
            <source
              v-for="(video, index) in badge.videos"
              :key="index"
              :type="video.type"
              :src="video.url"
            >
          </video>
          <div
            v-show="badge.isCompleted && badge.isVideoBadge"
            class="badge__video__overlay"
          >
            <div class="badge__video__play-icon">
              <Icon
                name="play-big"
                :color="variables.white"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!badge.isVideoBadge"
          class="badge__image"
          :style="{ backgroundImage: `url(${badge.imageUrl})` }"
        />
        <div
          v-if="!badge.isCompleted"
          class="badge__lock-icon"
        >
          <Icon
            name="lock"
            :color="variables.white"
          />
        </div>
        <div
          v-if="badge.isVideoBadge && badge.isCompleted"
          class="badge__is-video-icon"
        >
          <Icon
            name="camera"
            :color="variables.white"
          />
        </div>
      </div>
    </div>
    <div class="badge__title">
      <template v-if="badge.description">
        <BaseTooltip>
          <template #trigger>
            {{ badge.body }}
          </template>
          <template #content>
            {{ badge.description }}
          </template>
        </BaseTooltip>
      </template>
      <template v-else>
        {{ badge.body }}
      </template>
    </div>
    <div
      v-if="badge.isCompleted"
      class="badge__desc"
    >
      {{ badge.description }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import checkDevice from '@/common/checkDevice/checkDevice';
import Icon from '@/components/Icon/Icon';
import BaseTooltip from '@/components/Form/BaseTooltip';
import FadeIn from '@/components/Transition/FadeInTransition';
import variables from '@/assets/styles/utilities/_colors.scss';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'BadgeListItem',
  components: {
    Icon,
    BaseTooltip,
    FadeIn,
  },
  props: {
    badge: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      variables,
      isMobile: false,
      showBadgeImage: true,
      scrollTrigger: null,
      elementInCenter: false,
      isImageLoaded: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor']),
  },
  created() {
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
  },
  mounted() {
    if (this.isMobile && this.badge.isVideoBadge && this.badge.isCompleted) {
      this.initScrollTrigger();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
  methods: {
    ...checkDevice,
    checkDevice() {
      this.isMobile = this.isMobileDevice() && window.innerWidth < 768;
    },
    playVideo() {
      if (!this.badge.isCompleted || !this.$refs.previewVideo) return;

      this.$refs.previewVideo.play();
      this.showBadgeImage = false;
    },
    pauseVideo() {
      if (!this.badge.isCompleted || !this.$refs.previewVideo) return;

      this.$refs.previewVideo.pause();
      this.showBadgeImage = true;
    },
    initScrollTrigger() {
      gsap.fromTo(
        this.$refs.badge,
        { opacity: 0.5, scale: 0.98 },
        {
          opacity: 1,
          scale: 1,
          stagger: 0.2,
          duration: 1,
          ease: 'elastic.out(1,0.3)',
          scrollTrigger: {
            trigger: this.$refs.badge,
            start: 'top center',
            end: 'bottom center',
            onEnter: () => {
              this.elementInCenter = true;
              this.handleElementInCenter();
            },
            onEnterBack: () => {
              this.elementInCenter = true;
              this.handleElementInCenter();
            },
            onLeave: () => {
              this.elementInCenter = false;
              this.handleElementNotInCenter();
            },
            onLeaveBack: () => {
              this.elementInCenter = false;
              this.handleElementNotInCenter();
            },
          },
        },
      );
    },
    handleElementInCenter() {
      this.playVideo();
    },
    handleElementNotInCenter() {
      this.pauseVideo();
    },
    showVideo() {
      if (!this.badge.isCompleted && this.badge.isVideoBadge) return;

      this.$emit('show-video', this.badge);
    },
  },
};
</script>
<style lang="scss" scoped>
.badge {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 3rem;
  position: relative;
  @include media-min($md) {
    flex-basis: 50%;
  }
  @include media-min($lg) {
    flex-basis: 33.3%;
  }
  @include media-min($xxxl) {
    flex-basis: 16.6%;
  }
  &--disabled {
    .badge {
      &__wrapper {
        border: none;
      }
      &__image,
      &__preview-image {
        opacity: 0.2;
      }
      &__video {
        cursor: default;
      }
      &__lock-icon {
        display: flex;
      }
      &__title {
        display: none;
      }
    }
  }
  &__container {
    position: relative;
    width: 20rem;
    height: 20rem;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    overflow: hidden;
  }
  &__image,
  &__preview-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
  &__video {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media only screen and (orientation : landscape){
      &:hover > video {
        opacity: 0.8;
      }
      &:hover > .badge__video__overlay {
        opacity: 1;
      }
      &:hover .badge__video__play-icon {
        transform: scale(1);
        transition: all 0.6s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
    &__overlay {
      background-color: var(--light-primary-color);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }
    &__play-icon {
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }
  }
  &__lock-icon {
    position: absolute;
    width: 3.6rem;
    height: 3.6rem;
    background: $black;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    justify-content: center;
    align-items: center;
  }
  &__is-video-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 3.6rem;
    height: 3.6rem;
    background: var(--primary-color);
    border-radius: 50%;
    top: 1.4rem;
    left: 1.4rem;
  }
  &__title {
    position: relative;
    margin-top: 1.6rem;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    padding: 0 0.5rem;
    color: var(--primary-color);
  }
  &__desc {
    text-align: center;
    padding: 1.6rem 2.5rem 0;
    @media (hover: hover) and (min-width: 768px) {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }
}
</style>
