<template>
  <BaseLayout :side-image="sideImageOption">
    <template #main>
      <div class="choose-lang__content__shadow">
        <div class="choose-lang__content">
          <div class="choose-lang__content__wrapper">
            <h2>{{ $t("auth.select_language") }}</h2>
            <ChooseLangForm
              :is-loading="isLoading"
              :error="error"
              @handle-submit="submit"
            />
          </div>
        </div>
      </div>
    </template>
  </BaseLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import alerts from '@/common/alerts/alerts';
import BaseLayout from '@/layouts/BaseLayout';
import ChooseLangForm from '@/components/ChooseLang/Form';
import { HTTP_BAD_REQUEST } from '@/common/axios/codes';
import { REGISTER } from '@/router/routes.names';
import { REGISTRATION } from '@/names/pages.names';

export default {
  name: 'ChooseLang',
  components: {
    BaseLayout,
    ChooseLangForm,
  },
  data() {
    return {
      isLoading: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['sideImage']),
    sideImageOption() {
      return this.sideImage(REGISTRATION);
    },
  },
  methods: {
    ...alerts,
    ...mapActions('session', ['setSessionLocale']),
    ...mapActions('settings', ['fetchData']),

    async submit({ locale }) {
      try {
        this.error = false;
        this.isLoading = true;
        const response = await this.setSessionLocale({
          locale,
          recruitmentProcessId: this.$route.params.recruitmentProcessId,
          sessionId: this.$route.params.sessionId,
        });
        const sessionId = this.$route.params.sessionId !== undefined ? response.gameSession.data.id : null;
        const recruitmentProcessId = response.recruitmentProcess.data.id;
        await this.fetchData({
          sessionId,
          recruitmentProcessId,
        });
        this.$router.push({
          name: REGISTER,
          params: sessionId ? { recruitmentProcessId, sessionId } : { recruitmentProcessId },
        });
      } catch (e) {
        if (e.response?.status === HTTP_BAD_REQUEST) {
          this.error = true;

          return;
        }
        this.warningAlert();
        throw new Error(JSON.stringify('meta' in (e.response?.data || {}) ? (({ meta, ...errors }) => errors)(e.response.data) : e.response?.data));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.choose-lang {
  &__content {
    overflow-y: auto;
    &__shadow {
      position: relative;
      &::before,
      &::after {
        display: block;
        height: 5rem;
        left: 0;
        right: 1.5rem;
        position: absolute;
      }
      &::before {
        content: "";
        box-shadow: 0px 50px 20px -20px $white inset;
        top: 0;
      }
      &::after {
        content: "";
        box-shadow: 0px -50px 20px -20px $white inset;
        bottom: 0;
      }
    }
    &__wrapper {
      height: calc(100vh - 82px);
      height: calc(100svh - 82px);
      display: block;
      max-width: 880px;
      padding: 4rem 1.5rem;
      @include media-min($lg) {
        height: calc(100vh - 90px - 140px);
        height: calc(100svh - 90px - 140px);
      }
    }
  }
}
</style>
