export default {
  warning: {
    title: 'Do hibát észleltünk',
    content: 'Próbálja meg újra, és ha a hiba ismételten előfordul, küldjön nekünk értesítést',
    content_without_email: 'Próbálja meg újra, és ha a hiba ismételten előfordul, adja meg nekünk a műszaki támogatásunkon keresztül',
    confirm: 'Bezárás',
  },
  end_time: {
    title: 'Hibabejelentés',
    content: 'Ha a játék során hibát tapasztal, kérjük, lépjen kapcsolatba velünk e-mailen az alábbi címen:',
  },
  record_video_error: {
    title: 'Nincs hozzáférés a kamerához és/vagy mikrofonhoz.',
    content: 'A feladat befejezéséhez módosítsa a beállításait, és engedélyezze az alkalmazásnak a kamera és/vagy mikrofon használatát.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nem támogatott eszköz</span>
      <hr>
      <div class="swal2-wrapper">
        <p>A folytatáshoz használjon <strong>másik eszközt és/vagy böngészőt.</strong></p>
        <p>A kiválasztott toborzási eszköz legalább egy olyan feladatot tartalmaz, amelyben válaszát videó formátumban kell rögzítenie. Sajnos nem minden eszköz és/vagy böngésző teszi lehetővé ezt a helyes módon.</p>
        <p><strong>Ha látja ezt a képernyőt, az azt jelenti, hogy az Ön eszköze és/vagy böngészője nem garantálja a helyes videófájl rögzítését és elküldését.</strong> Az alábbiakban találja az ajánlott beállításokat.</p>
        <br>
        <p><strong>Asztali eszközök:/strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobil eszközök:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Biztosan ki szeretnéd hagyni a feladatot?',
    content: '<p>A videó válasz rögzítése ebben a feladatban nem kötelező, de ajánlott - segít nekünk jobban megismerni Önt. Biztosan át szeretné ugrani a videó válasz rögzítését?</p>',
  },
  skip_stage_with_record: {
    title: 'Biztosan ki szeretnéd hagyni a feladatot?',
    content: '<p>Az ön szándékában áll kihagyni a rögzített videó válasz mentését - nem lesz elérhető a toborzást végző személy számára. A videó válasz feltöltése ennek a feladatnak nem kötelező, de ajánlott - segít nekünk jobban megismerni Önt. Biztosan szeretné kihagyni a rögzített videó válasz mentését?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Kamera és Mikrofon Hiba Elhárítása</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Ha a felvétel vagy lejátszás helytelen, követhet néhány lépést, amelyek megoldhatják a problémát. A problémák leggyakoribb okai a készülék beállításaiból adódnak, beleértve az egyidejűleg futó egyéb programokat vagy alkalmazásokat, amelyek használják a kamerát és/vagy a mikrofont.</p>
      <p>Lehetséges megoldások:</p>
      <ol>
        <li>Győződjön meg arról, hogy a böngészőnek van engedélye a mikrofon és a kamera használatára. Ha nincs, módosítsa a beállításokat és frissítse az oldalt.</li>
        <li>Győződjön meg arról, hogy nincs más alkalmazás vagy program, amely éppen használja a kamerát/mikrofont (pl. MS Teams, Photo Booth vagy FaceTime) - zárja be azokat az alkalmazásokat, amelyek blokkolják a kamera böngésző általi használatát, és frissítse az oldalt.</li>
        <li>Ellenőrizze, hogy a böngészője frissítve van-e a legújabb verzióra - ha nem, végezzen frissítést és indítsa újra a tesztet.</li>
        <li>Ha a fenti lépések nem segítettek, próbáljon meg egy másik böngészőt vagy eszközt használni.</li>
      </ol>
      <p>Ha a probléma továbbra is fennáll, tájékoztasson minket a <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> címen, küldje el az eszköz és a böngésző adatait, ahol a probléma előfordul.</p>
      </div>
      <hr>
    `,
  },
};
