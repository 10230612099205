export default {
  name: '313C',
  recruitment_game: 'Igra za zapošljavanje',
  back: 'Natrag',
  cancel: 'Otkaži',
  continue: 'Nastavi',
  skip: 'Preskoči',
  start: 'Početak',
  close: 'Zatvori',
  help: 'Pomoć',
  time_left: 'Preostalo vrijeme',
  progress: 'Napredak',
  privacy_policy: 'Pravila o privatnosti',
  cookies_policy: 'Pravila o kolačićima i uvjeti',
  faq: 'Pitanja i odgovori',
  messages: 'Poruke',
  unread: 'nepročitano',
  start_recording: 'Započnite snimanje',
  stop_recording: 'Zaustavi snimanje',
  remove_record: 'Ukloni zapis',
};
