export default {
  choose: 'Wählen',
  badges: {
    title: 'Auszeichnungen',
    badge: 'Auszeichnung',
    my_badges: 'Meine Auszeichnungen',
    received: 'Du bekommst eine Auszeichnung',
    continue: 'Spiele weiter',
    show_badges: 'Sieh dir Auszeichnungen an',
    show_badge: 'Sieh dir die belohnung an',
  },
  record_test: {
    intro: 'KAMERA- UND MIKROFON-EINSTELLUNGSTEST',
    content: 'Bevor Sie mit dem Tool beginnen, testen Sie Ihre Einstellungen. Im untenstehenden Feld sollten Sie das Bild Ihrer Kamera sehen. Wenn Sie es sehen, bedeutet dies, dass Ihre Einstellungen höchstwahrscheinlich korrekt sind - um sicherzugehen, nehmen Sie ein Video auf und spielen es ab. Wenn Sie das Bild der Kamera nicht sehen, die Aufnahme nicht abspielen können oder andere Zweifel haben, klicken Sie auf die Schaltfläche „PROBLEMLÖSUNG“.',
    troubleshoot: 'PROBLEMLÖSUNG',
  },
  questions: {
    progress: 'Frage',
    accept: 'Bestätigen',
    question_has_already_been_answered: 'Diese Frage wurde bereits beantwortet.',
  },
  not_found: {
    code: '404',
    title: 'Seite existiert nicht',
    content: 'Die Seite, die Sie suchten, wurde nicht gefunden. Es könnte gelöscht, umbenannt oder gar nicht existiert haben.',
  },
};
