export default {
  checkbox_required: 'Select: %{name}',
  required: 'Enter: %{name}',
  phone_incorrect: 'Please enter a valid phone number',
  min_length: 'The minimum number of characters is: %{length}',
  max_length: 'The maximum number of characters is: %{length}',
  max_file_size: 'The maximum file size is %{size} mb',
  recaptcha: 'Select Recaptcha',
  email_incorrect: 'Invalid format',
  max_value: 'The maximum value must be %{value} or less',
  min_value: 'The minimum value must be %{value} or more',
  numeric: 'Incorrect format of the number',
  numeric_required: '%{name} is required and can only contain whole numbers',
};
