import Vue from 'vue';
import VueCookies from 'vue-cookies';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import Gallery from 'vue-easy-lightbox';
import Multiselect from 'vue-multiselect';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import VueTippy, { TippyComponent } from 'vue-tippy';
import 'external-svg-loader';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/translucent.css';

Vue.use(VueCookies);
Vue.use(vueAwesomeCountdown, 'BaseCountdown');
Vue.use(VueTippy, {
  directive: 'tippy',
  flipDuration: 0,
});
Vue.component('tippy', TippyComponent);
Vue.component('BaseGallery', Gallery);
Vue.component('Multiselect', Multiselect);
Vue.component('Calendar', Calendar);
Vue.component('DatePicker', DatePicker);
