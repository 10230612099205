<template>
  <ul class="accordion">
    <transition
      appear
      @before-enter="beforeEnter"
      @enter="enter"
    >
      <div>
        <slot />
      </div>
    </transition>
  </ul>
</template>
<script>
import gsap from 'gsap';

export default {
  name: 'Accordion',
  props: {
    activeIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      Accordion: {
        count: 0,
        active: null,
      },
    };
  },
  created() {
    this.Accordion.active = this.activeIndex;
  },
  provide() {
    return { Accordion: this.Accordion };
  },
  methods: {
    beforeEnter(el) {
      gsap.set(el, { autoAlpha: 0 });
    },
    enter(el, done) {
      gsap
        .fromTo(el, {
          autoAlpha: 0,
        }, {
          autoAlpha: 1,
          duration: 0.4,
          onComplete: done,
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 8px 0px rgb(176,190,197,0.24);
}
</style>
