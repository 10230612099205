<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="submit">
      <div class="register-form__inputs">
        <component
          :is="fieldComponentForType(field.type, field.form_name)"
          v-for="field in formData.basicFields"
          :key="field.id"
          v-model="field.value"
          :rules="validationRulesForType(field)"
          :label="field.name"
          :tooltip="field.tooltip"
          :placeholder="field.placeholder"
          :vid="field.form_name"
          required
        />
      </div>
      <div
        v-if="formData.additionalFields.length"
        class="register-form__inputs"
      >
        <component
          :is="fieldComponentForType(field.type)"
          v-for="field in formData.additionalFields"
          :key="field.id"
          v-model="field.value"
          :class="extraClassForComponent(field.type)"
          :rules="validationRulesForType(field)"
          :label="field.name"
          :tooltip="field.tooltip"
          :placeholder="field.placeholder"
          :required="field.required"
          :vid="`${field.form_name}-${field.id}`"
          v-bind="extraParamsForComponent(field)"
        />
      </div>
      <VueRecaptcha
        class="register-form__recaptcha"
        :sitekey="recaptchaKey"
        :language="locale"
        :load-recaptcha-script="true"
        @verify="onReCaptchaVerify"
        @expired="onReCaptchaExpire"
      />
      <span
        v-if="recaptchaError"
        class="validation-error"
      >
        {{ $t("validation.recaptcha") }}
      </span>
      <BackendErrors :backend-errors="backendErrors" />
      <div class="register-form__button">
        <BaseButton
          :is-loading="isLoading"
          type="submit"
        >
          {{ continueBtnName }}
        </BaseButton>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { env } from '@/helper';
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/Form/BaseButton';
import BasePhoneInput from '@/components/Form/BasePhoneInput';
import BaseFileUpload from '@/components/Form/BaseFileUpload';
import BaseInput from '@/components/Form/BaseInput';
import BaseNumber from '@/components/Form/BaseNumber';
import BaseTextarea from '@/components/Form/BaseTextarea';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseCheckbox from '@/components/Form/BaseCheckbox';
import BaseCalendar from '@/components/Form/BaseCalendar';
import BaseHeader from '@/components/Form/BaseHeader';
import Icon from '@/components/Icon/Icon';
import BackendErrors from '@/components/Form/Partials/BackendErrors';
import * as fieldTypes from '@/names/gameFields.names';

const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const PHONE_NUMBER = 'phone_number';
const EMAIL = 'email';

const FIELD_COMPONENTS = {
  TEXT: 'BaseInput',
  NUMBER: 'BaseNumber',
  TEXTAREA: 'BaseTextarea',
  SELECT: 'BaseSelect',
  MULTISELECT: 'BaseSelect',
  FILE: 'BaseFileUpload',
  CHECKBOX: 'BaseCheckbox',
  DATE: 'BaseCalendar',
  HEADER: 'BaseHeader',
  PHONE_NUMBER: 'BasePhoneInput',
};

const TYPES_VALIDATION_RULES = {
  TEXT: 'max:255',
  TEXTAREA: 'max:3000',
  SELECT: '',
  MULTISELECT: '',
  FILE: 'size:10240',
  CHECKBOX: '',
  DATE: '',
  HEADER: '',
  FIRST_NAME: 'min:3|max:255',
  LAST_NAME: 'min:3|max:255',
  PHONE_NUMBER: 'min:8|max:15',
  EMAIL: 'min:3|max:255|email',
};

export default {
  name: 'RegisterForm',
  components: {
    VueRecaptcha,
    BaseButton,
    BasePhoneInput,
    BaseFileUpload,
    BaseInput,
    BaseNumber,
    BaseTextarea,
    BaseSelect,
    BaseCheckbox,
    BaseCalendar,
    BaseHeader,
    Icon,
    BackendErrors,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    continueBtnName: {
      type: String,
      required: true,
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      recaptchaError: false,
      recaptchaVerified: false,
      recaptchaToken: null,
      recaptchaExpired: false,
      formData: {
        basicFields: [],
        additionalFields: [],
      },
    };
  },
  computed: {
    ...mapGetters('settings', [
      'registerFormBasicFields',
      'registerFormAdditionalFields',
      'locale',
    ]),
    recaptchaKey() {
      return env.VUE_APP_RECAPTCHA_KEY;
    },
  },
  created() {
    this.formData.basicFields = this.registerFormBasicFields.map((field) => ({
      ...field,
      value: '',
    }));
    this.formData.additionalFields = this.registerFormAdditionalFields.map((field) => ({
      ...field,
      value: field.type === fieldTypes.MULTISELECT ? [] : '',
    }));
  },
  methods: {
    validationRulesForType(field) {
      if (field.type === fieldTypes.NUMBER) {
        return `numeric|min_value:${field.min}|max_value:${field.max}${field.required ? '|numeric_required' : ''}`;
      }

      return `${
        TYPES_VALIDATION_RULES[
          [FIRST_NAME, LAST_NAME, EMAIL, PHONE_NUMBER].includes(field.form_name)
            ? field.form_name.toUpperCase()
            : field.type
        ]
      }${field.required ? '|required' : ''}`;
    },
    fieldComponentForType(type, formName = null) {
      return FIELD_COMPONENTS[[PHONE_NUMBER].includes(formName) ? formName.toUpperCase() : type];
    },
    extraClassForComponent(type) {
      const { CHECKBOX, HEADER, TEXTAREA } = fieldTypes;
      if ([CHECKBOX, HEADER, TEXTAREA].includes(type)) {
        return 'full-width';
      }

      return null;
    },
    extraParamsForComponent({
      type, description = null, options = [], min = 0, step = 0,
    }) {
      const {
        CHECKBOX, SELECT, MULTISELECT, NUMBER,
      } = fieldTypes;
      switch (type) {
        case SELECT:
          return {
            items: options,
          };
        case MULTISELECT:
          return {
            items: options,
            multiple: true,
          };
        case CHECKBOX:
          return {
            description,
          };
        case NUMBER:
          return {
            min,
            step,
          };
        default:
          return null;
      }
    },
    async submit() {
      if (this.isLoading) return;
      this.recaptchaError = false;
      const validationStatus = await this.$refs.form.validate();
      if (!validationStatus) {
        const invalidField = this.$refs.form.$children.find((field) => !field.valid);
        if (invalidField) {
          invalidField.$el.scrollIntoView({ behavior: 'smooth' });
        }

        return;
      }
      const recaptchaStatus = this.recaptchaVerified && !this.recaptchaExpired;
      if (!recaptchaStatus) {
        this.recaptchaError = true;

        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const { recruitmentProcessId, sessionId } = this.$route.params;
      const phoneNumber = this.formData.basicFields.find(
        (field) => field.form_name === 'phone_number',
      ).value;
      const formData = new FormData();
      this.basicFields(formData);
      if (this.formData.additionalFields.length) {
        this.additionalFields(formData);
      }
      formData.set('g-recaptcha-response', this.recaptchaToken);

      return {
        recruitmentProcessId,
        sessionId,
        phoneNumber,
        params: formData,
      };
    },
    basicFields(formData) {
      return this.formData.basicFields.map((basicField, index) => {
        formData.set(`basic[${index}][form_name]`, basicField.form_name);
        formData.set(`basic[${index}][value]`, basicField.value);

        return basicField;
      });
    },
    additionalFields(formData) {
      return this.formData.additionalFields.map((additionalField, index) => {
        if (additionalField.type === fieldTypes.HEADER) return false;

        formData.set(`additional[${index}][id]`, additionalField.id);
        if (additionalField.type === fieldTypes.CHECKBOX || additionalField.type === fieldTypes.NUMBER) {
          return formData.set(`additional[${index}][value]`, +additionalField.value);
        }
        if (additionalField.type === fieldTypes.MULTISELECT) {
          if (!additionalField.value.length) {
            return formData.set(`additional[${index}][value]`, additionalField.value);
          }

          return additionalField.value.map((field, multiselectIndex) => {
            formData.set(`additional[${index}][value][${multiselectIndex}]`, field);

            return field;
          });
        }
        formData.set(`additional[${index}][value]`, additionalField.value);

        return additionalField;
      });
    },
    onReCaptchaVerify(token) {
      this.recaptchaError = false;
      this.recaptchaVerified = true;
      this.recaptchaToken = token;
      this.recaptchaExpired = false;
    },
    onReCaptchaExpire() {
      this.recaptchaError = true;
      this.recaptchaVerified = false;
      this.recaptchaToken = null;
      this.recaptchaExpired = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.register-form {
  &__inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem 2rem;
    max-width: 1100px;
    padding: 1.5rem 0;
    @include media-min($lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__recaptcha {
    margin-top: 2rem;
  }
  &__input {
    position: relative;
    width: 100%;
  }
  &__button {
    @include media-min($lg) {
      padding: 3rem 0 10rem;
    }
  }
}
.full-width {
  grid-column: 1 / 2;
  @include media-min($lg) {
    grid-column: 1 / 3;
  }
}
.validation-error {
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding-top: 1rem;
  color: $red;
}
</style>
