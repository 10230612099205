import alerts from '@/trans/fr/alerts';
import cookies from '@/trans/fr/cookies';
import general from '@/trans/fr/general';
import game from '@/trans/fr/game';
import validation from '@/trans/fr/validation';
import auth from '@/trans/fr/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
