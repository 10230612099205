export default {
  name: '313C',
  recruitment_game: 'Hra na zaměstnance',
  back: 'Zpět',
  cancel: 'Zrušit',
  continue: 'Pokračovat',
  skip: 'Přeskočit',
  start: 'Začít',
  close: 'Zavřít',
  help: 'Pomoc',
  time_left: 'Zbývající čas',
  progress: 'Postup',
  privacy_policy: 'Zásady ochrany osobních údajů',
  cookies_policy: 'Zásady cookies a Podmínky',
  faq: 'Otázky a odpovědi',
  messages: 'Zprávy',
  unread: 'nepřečtené',
  start_recording: 'Spuštění nahrávání',
  stop_recording: 'Zastavit nahrávání',
  remove_record: 'Odstranění záznamu',
};
