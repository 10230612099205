export default {
  checkbox_required: 'Jelölje be %{name}',
  required: 'Írja be %{name}',
  phone_incorrect: 'Adja meg a helyes telefonszámot',
  min_length: 'A minimum karakterszáma van: %{length}',
  max_length: 'A maximum karakterszáma van: %{length}',
  max_file_size: 'A legnagyobb fájlméret %{size} mb',
  recaptcha: 'Válassza a recaptcha lehetőséget',
  email_incorrect: 'Érvénytelen formátum',
  max_value: 'A maximális érték legfeljebb %{value} lehet',
  min_value: 'A minimális érték legalább %{value} kell legyen',
  numeric: 'Helytelen számformátum',
  numeric_required: '%{name} mező kötelező és csak egész számokat tartalmazhat',
};
