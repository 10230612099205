export default {
  choose: 'Vybrat',
  badges: {
    title: 'Odznaky',
    badge: 'Odznak',
    my_badges: 'Moje odznaky',
    received: 'Získáte odznak',
    continue: 'Hrajte dál',
    show_badges: 'Podívejte se na odznaky',
    show_badge: 'Uvidět odmeňu',
  },
  record_test: {
    intro: 'TEST NASTAVENÍ KAMERY A MIKROFONU',
    content: 'Před spuštěním nástroje si otestujte svá nastavení. V poli níže byste měli vidět obraz ze své kamery. Pokud jej vidíte, znamená to, že vaše nastavení jsou pravděpodobně správná - pro jistotu nahrajte a přehrajte video. Pokud obraz z kamery nevidíte, nemůžete přehrát nahrávku nebo máte jiné pochybnosti, klikněte na tlačítko „ŘEŠENÍ PROBLÉMŮ“.',
    troubleshoot: 'ŘEŠENÍ PROBLÉMŮ',
  },
  questions: {
    progress: 'Otázka',
    accept: 'Potvrdit',
    question_has_already_been_answered: 'Tato otázka již byla zodpovězena.',
  },
  not_found: {
    code: '404',
    title: 'Stránka neexistuje',
    content: 'Stránka, kterou hledáte, nebyla nalezena. Mohla být odstraněna, změnila se její název nebo vůbec neexistovala',
  },
};
