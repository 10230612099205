<template>
  <div class="base-header">
    {{ label }}
  </div>
</template>
<script>
export default {
  name: 'BaseHeader',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.base-header {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: var(--primary-color);
  padding: 1.6rem 0;
  margin-top: 1rem;
  border-bottom: 1px solid #E6E6E6;
}
</style>
