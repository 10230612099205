import i18n from '@/i18n';
import * as names from '@/store/settings/mutations.names';

export default {
  [names.SET_GAME_DATA](state, gameData) {
    state.data = gameData;
  },
  [names.SET_LOCALE](state, locale) {
    i18n.locale = locale;
    state.locale = locale;
  },
  [names.UPDATE_PAGE_TRANSLATION](state, pageData) {
    const index = state.data.pages.findIndex((item) => item.data.type === pageData.type);
    if (index !== -1) {
      state.data.pages[index].data = { ...pageData };

      return;
    }

    state.data.pages.push({ data: { ...pageData } });
  },
};
