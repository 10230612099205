export default {
  name: '313C',
  recruitment_game: 'Náborová hra',
  back: 'Spáát',
  cancel: 'Zrušiť',
  continue: 'Pokračovať',
  skip: 'Preskočiť',
  start: 'Štart',
  close: 'Zavrieť',
  help: 'Pomoc',
  time_left: 'Zostávajúci čas',
  progress: 'Postup',
  privacy_policy: 'Ochrana osobných údajov',
  cookies_policy: 'Politika cookies a Podmienky',
  faq: 'Otázky a odpovede',
  messages: 'Správy',
  unread: 'neprecitnutá',
  start_recording: 'Spustenie nahrávania',
  stop_recording: 'Zastaviť nahrávanie',
  remove_record: 'Odstrániť záznam',
};
