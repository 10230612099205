<template>
  <div class="badge-list">
    <BadgeListItem
      v-for="(badge, index) in badges"
      :key="`badge-${index}`"
      :badge="badge"
      @show-video="handleShowVideo"
    />
    <ModalPlayer
      v-if="showBageVideoModal"
      :badge="activeBadge"
      @close-video="handleCloseVideo"
    />
  </div>
</template>
<script>
import gsap from 'gsap';
import BadgeListItem from '@/components/Badge/BadgeListItem';
import ModalPlayer from '@/components/Video/ModalPlayer';

export default {
  name: 'BadgeList',
  components: {
    BadgeListItem,
    ModalPlayer,
  },
  props: {
    badges: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeBadge: null,
      showBageVideoModal: false,
    };
  },
  mounted() {
    gsap.fromTo(
      '.badge__container',
      { scale: 0.95 },
      {
        scale: 1,
        duration: 1.2,
        ease: 'elastic.out(1,0.3)',
        stagger: {
          each: 0.1,
        },
      },
    );
  },
  methods: {
    handleShowVideo(badge) {
      this.showBageVideoModal = true;
      this.activeBadge = badge;
    },
    handleCloseVideo() {
      this.showBageVideoModal = false;
      this.activeBadge = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.badge-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0 5rem 0;
  margin-bottom: 5rem;
}
</style>
