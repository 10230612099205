import Stage from '@/domain/entities/flow/Stage';

/**
 * @abstract
 */
export default class GameQuestion extends Stage {
  /**
   * @param {String} id
   * @param {String} type
   * @param {Number} order
   * @param {String} body
   * @param {?Date} completedAt
   * @param {String} questionId
   * @param {?Badge} badge
   * @param {?String} instruction
   * @param {?String} imageUrl
   * @param {String} sideImageUrl
   * @param {String} header
   */
  constructor({
    id, type, order, body, completedAt, questionId, badge, instruction, imageUrl, sideImageUrl, header,
  }) {
    super({
      id, type, order, completedAt, badge,
    });

    this._body = body;
    this._questionId = questionId;
    this._instruction = instruction;
    this._imageUrl = imageUrl;
    this._sideImageUrl = sideImageUrl;
    this._header = header;
  }

  /**
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  updateSessionAnswers() {
  }

  /**
   * @return {boolean}
   */
  hasChosenAnswer() {
    return this._answers.some((answer) => answer.isChosen);
  }

  /**
   * @return {boolean}
   */
  hasSessionAnswer() {
    return this._answers.some((answer) => answer.sessionAnswer);
  }

  /**
   * @abstract
   * @return {Array}
   */
  // eslint-disable-next-line class-methods-use-this
  chosenAnswersRequestData() {
  }

  /**
   * @return {String}
   */
  get body() {
    return this._body;
  }

  /**
   * @return {String}
   */
  get questionId() {
    return this._questionId;
  }

  /**
   * @return {?String}
   */
  get instruction() {
    return this._instruction;
  }

  /**
   * @return {?String}
   */
  get imageUrl() {
    return this._imageUrl;
  }

  /**
   * @return {?String}
   */
  get header() {
    return this._header;
  }
}
