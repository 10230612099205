export default {
  choose: 'Izberite',
  badges: {
    title: 'Značke',
    badge: 'Značka',
    my_badges: 'Moje značke',
    received: 'Prejmete značko',
    continue: 'Nadaljuj z igro',
    show_badges: 'Oglejte si značk',
    show_badge: 'Glej nagrado',
  },
  record_test: {
    intro: 'TEST NASTAVITEV KAMERE IN MIKROFONA',
    content: 'Preden začnete uporabljati orodje, preizkusite svoje nastavitve. V spodnjem polju bi morali videti sliko iz svoje kamere. Če jo vidite, to pomeni, da so vaše nastavitve verjetno pravilne - za prepričanje posnemite in predvajajte video. Če slike iz kamere ne vidite, ne morete predvajati posnetka ali imate druge dvome, kliknite na gumb "ODPRAVA TEŽAV".',
    troubleshoot: 'ODPRAVA TEŽAV',
  },
  questions: {
    progress: 'Vprašanje',
    accept: 'Potrdi',
    question_has_already_been_answered: 'To vprašanje je že bilo odgovorjeno.',
  },
  not_found: {
    code: '404',
    title: 'Stran ne obstaja',
    content: 'Stran, ki ste jo iskali, ni bila najdena. Lahko je bila izbrisana, spremenjeno ime ali sploh ni obstajala',
  },
};
