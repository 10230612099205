import Badge from '@/domain/entities/flow/Badge';
import BestWorstGameQuestion from '@/domain/entities/flow/BestWorstGameQuestion';
import BestWorstGameQuestionAnswer from '@/domain/entities/flow/BestWorstGameQuestionAnswer';
import ClosedGameQuestion from '@/domain/entities/flow/ClosedGameQuestion';
import ClosedGameQuestionAnswer from '@/domain/entities/flow/ClosedGameQuestionAnswer';
import Feedback from '@/domain/entities/flow/Feedback';
import Flow from '@/domain/aggregates/Flow';
import GameMessage from '@/domain/entities/flow/GameMessage';
import GameVideo from '@/domain/entities/flow/GameVideo';
import GameRecord from '@/domain/entities/flow/GameRecord';
import GameImage from '@/domain/entities/flow/GameImage';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';
import GameRecordSessionRecord from '@/domain/entities/flow/GameRecordSessionRecord';

/**
 * @param {Object} badge
 * @param {Boolean} isCompleted
 * @return {Badge}
 */
const badgeFactory = (badge, isCompleted) => new Badge({
  body: badge.data.body,
  description: badge.data.description,
  icon: badge.data.icon,
  iconUrl: badge.data.icon_url,
  imageUrl: badge.data.image_url,
  video: badge.data.video,
  isCompleted,
});

/**
 * @param {Object} stage
 * @return {{id, type, order, completedAt, badge, sideImageUrl}}
 */
const stageBasicData = (stage) => ({
  id: stage.id,
  type: stage.stageable.data.type,
  order: stage.order,
  completedAt: ('session_stage' in stage) ? (stage.session_stage.data.completed_at && new Date(stage.session_stage.data.completed_at)) : null,
  badge: stage.badge ? badgeFactory(stage.badge, !!stage.session_stage) : null,
  sideImageUrl: stage.side_image_url,
});

/**
 * @param {Object} sessionAnswer
 * @return {GameQuestionSessionAnswer}
 */
const sessionAnswerFactory = ({ data: { answer, created_at: createdAt } }) => new GameQuestionSessionAnswer({
  createAt: new Date(createdAt),
  answer,
});

/**
 * @param {Object} sessionRecord
 * @return {GameRecordSessionRecord}
 */
const sessionRecordFactory = ({ data: { created_at: createdAt } }) => new GameRecordSessionRecord({
  createAt: new Date(createdAt),
});

/**
 * @param {Object} feedback
 * @return {Feedback}
 */
const feedbackFactory = (feedback) => new Feedback({
  title: feedback.data.body,
  imageUrl: feedback.data.image_url,
});

/**
 * @param {Array<Object>} answers
 * @return {Array<GameQuestionAnswer>}
 */
const closedQuestionAnswersFactory = (answers) => answers.sort(() => Math.random() - 0.5).map((answer) => new ClosedGameQuestionAnswer({
  ...answer,
  feedback: answer.feedback ? feedbackFactory(answer.feedback) : null,
  badge: answer.badge ? badgeFactory(answer.badge, !!answer.session_answer) : null,
  sessionAnswer: answer.session_answer ? sessionAnswerFactory(answer.session_answer) : null,
  choosen: !!answer.session_answer,
}));

/**
 * @param {Array<Object>} answers
 * @return {Array<BestWorstGameQuestionAnswer>}
 */
const bestWorstQuestionAnswersFactory = (answers) => answers.sort(() => Math.random() - 0.5).map((answer) => new BestWorstGameQuestionAnswer({
  ...answer,
  feedback: answer.feedback ? feedbackFactory(answer.feedback) : null,
  badge: answer.badge
    ? badgeFactory(answer.badge, answer.session_answer?.data.answer === BestWorstGameQuestionAnswer.BEST_TYPE_NAME) : null,
  sessionAnswer: answer.session_answer ? sessionAnswerFactory(answer.session_answer) : null,
  choosenAsBestAnswer: answer.session_answer?.data.answer === BestWorstGameQuestionAnswer.BEST_TYPE_NAME,
  choosenAsWorstAnswer: answer.session_answer?.data.answer === BestWorstGameQuestionAnswer.WORST_TYPE_NAME,
}));

/**
 * @param {Object} stage
 * @return {Object}
 */
const questionBasicData = (stage) => ({
  ...stageBasicData(stage),
  body: stage.stageable.data.body,
  questionId: stage.stageable.data.id,
  instruction: stage.stageable.data.instruction,
  imageUrl: stage.stageable.data.image_url,
  header: stage.stageable.data.header,
});

/**
 * @param {Object} stage
 * @return {Object}
 */
const recordBasicData = (stage) => ({
  ...stageBasicData(stage),
  body: stage.stageable.data.body,
  recordId: stage.stageable.data.id,
  instruction: stage.stageable.data.instruction,
  header: stage.stageable.data.header,
  feedback: stage.stageable.data.feedback ? feedbackFactory(stage.stageable.data.feedback) : null,
  sessionRecord: stage.stageable.data.session_record ? sessionRecordFactory(stage.stageable.data.session_record) : null,
});

const FLOW_FACTORIES = {
  gameQuestion: (stage) => {
    const gameQuestionFactories = {
      CLOSED: () => new ClosedGameQuestion({
        ...questionBasicData(stage),
        answers: closedQuestionAnswersFactory(stage.stageable.data.answers.data),
      }),
      BEST_WORST: () => new BestWorstGameQuestion({
        ...questionBasicData(stage),
        answers: bestWorstQuestionAnswersFactory(stage.stageable.data.answers.data),
      }),
    };

    if (!(stage.stageable.data.type in gameQuestionFactories)) {
      throw new Error(`Missing factory definition for question game type ${stage.flowable.type}`);
    }

    return gameQuestionFactories[stage.stageable.data.type]();
  },
  gameMessage: (stage) => new GameMessage({
    ...stageBasicData(stage),
    title: stage.stageable.data.title,
    body: stage.stageable.data.body,
    feedback: stage.stageable.data.feedback ? feedbackFactory(stage.stageable.data.feedback) : null,
  }),
  gameVideo: (stage) => new GameVideo({
    ...stageBasicData(stage),
    title: stage.stageable.data.title,
    files: stage.stageable.data.files,
  }),
  gameImage: (stage) => new GameImage({
    ...stageBasicData(stage),
    title: stage.stageable.data.title,
    imageUrl: stage.stageable.data.image_url,
    continueBtn: stage.stageable.data.continue_btn,
  }),
  gameRecord: (stage) => new GameRecord({
    ...recordBasicData(stage),
    title: stage.stageable.data.title,
    required: stage.stageable.data.required,
    timeLimit: stage.stageable.data.time_limit,
  }),
};

/**
 * @param {Array<Object>} flowData
 * @return {Flow}
 */
export default function (flowData) {
  const stages = flowData.map((stage) => {
    if (!(stage.stageable_type in FLOW_FACTORIES)) {
      throw new Error(`Missing factory definition for ${stage.stageable_type} stage type`);
    }

    return FLOW_FACTORIES[stage.stageable_type](stage);
  });

  return new Flow(stages);
}
