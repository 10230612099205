import * as names from '@/store/session/mutations.names';

export default {
  [names.SET_SESSION](state, session) {
    state.session = session;
  },
  [names.SET_FINISHED_DATE](state, gameFinishedDate) {
    state.session.game_finished_at = gameFinishedDate;
  },
  [names.SET_TIME_LIMIT](state, gameTimeLimit) {
    state.gameTimeLimit = gameTimeLimit;
  },
  [names.SET_CURRENT_ROUTE](state, currentRoute) {
    state.currentRoute = currentRoute;
  },
};
