<template>
  <div class="progressbar__loader">
    <div
      class="progressbar__percentage"
      :style="{ width: `${percentage}%` }"
    />
  </div>
</template>
<script>
export default {
  name: 'ProgressBarMobile',
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percent() {
      return this.percentage.toFixed();
    },
  },
};
</script>
<style lang="scss" scoped>
.progressbar {
  &__loader {
    position: relative;
    width: 100%;
    height: 16px;
    background: $gray400;
    @include media-min($xl) {
      display: block;
    }
  }
  &__percentage {
    background-color: var(--primary-color);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition: 0.3s width;
  }
}
</style>
