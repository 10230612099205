import { immerable, produce } from 'immer';
import GameQuestion from '@/domain/entities/flow/GameQuestion';
import GameRecord from '@/domain/entities/flow/GameRecord';
import GameMessage from '@/domain/entities/flow/GameMessage';

export default class Flow {
  [immerable] = true

  /**
   * @param {Array<Stage>} stages
   */
  constructor(stages) {
    this._stages = stages;
  }

  updateStage(stage) {
    const stageIndex = this._stages.findIndex((findStage) => findStage.id === stage.id);

    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      draft._stages[stageIndex] = stage;
    });
  }

  /**
   * @return {Array<GameMessage>}
   */
  messagesToCurrentStage() {
    return this._stages.filter(
      (stage, index) => (stage instanceof GameMessage && (stage.isCompleted() || index === this.currentStageIndex())),
    );
  }

  /**
   * @return {number}
   */
  percentageCompleted() {
    return Math.round((this.completedStagesCount() / this._stages.length) * 100) / 100;
  }

  /**
   * @return {number}
   */
  completedStagesCount() {
    return this._stages.filter((stage) => stage.isCompleted()).length;
  }

  /**
   * @return {number}
   */
  questionsCount() {
    return this._stages.filter((stage) => (stage instanceof GameQuestion || stage instanceof GameRecord)).length;
  }

  /**
   * @return {boolean}
   */
  hasQuestions() {
    return !!this._stages.filter((stage) => (stage instanceof GameQuestion || stage instanceof GameRecord)).length;
  }

  /**
   * @return {number}
   */
  completedQuestionsCount() {
    return this._stages.filter((stage) => ((stage instanceof GameQuestion || stage instanceof GameRecord) && stage.isCompleted())).length;
  }

  /**
   * @return {Stage}
   */
  currentStage() {
    return this._stages.find((stage) => !stage.isCompleted());
  }

  /**
   * @return {number}
   */
  currentStageIndex() {
    return this._stages.findIndex((stage) => !stage.isCompleted());
  }

  /**
   * @return {boolean}
   */
  areAllStagesCompleted() {
    return this._stages.every((stage) => stage.isCompleted());
  }

  /**
   * @return {number}
   */
  completedBadgesCount() {
    return this.badges().filter((badge) => badge.isCompleted).length;
  }

  /**
   * @return {Array<Badge>}
   */
  badges() {
    return this._stages
      .filter((stage) => stage.possibleBadge())
      .map((stage) => stage.possibleBadge());
  }

  /**
   * @return {boolean}
   */
  hasGameRecords() {
    return !!this._stages.filter((stage) => (stage instanceof GameRecord)).length;
  }
}
