export default {
  name: '313C',
  recruitment_game: 'Rekrutierungs-Spiel',
  back: 'Zurück',
  cancel: 'Abbrechen',
  continue: 'Fortsetzen',
  skip: 'Überspringen',
  start: 'Start',
  close: 'Schließen',
  help: 'Hilfe',
  time_left: 'Verbleibende Zeit',
  progress: 'Fortschritt',
  privacy_policy: 'Datenschutzrichtlinien',
  cookies_policy: 'Cookie-Richtlinien und -Bedingungen',
  faq: 'Frag- und Antworten',
  messages: 'Nachrichten',
  unread: 'ungelesen',
  start_recording: 'Aufzeichnung starten',
  stop_recording: 'Aufzeichnung stoppen',
  remove_record: 'Aufzeichnung entfernen',
};
