import store from '@/store';
import ChooseLang from '@/views/ChooseLang';
import Login from '@/views/Login';
import Register from '@/views/Register';
import {
  CHOOSE_LANG,
  LOGIN,
  REGISTER,
  NOT_FOUND,
} from '@/router/routes.names';
import {
  HTTP_NOT_FOUND,
  HTTP_UNAUTHORIZED,
} from '@/common/axios/codes';

const checkRegisterStatus = async (to, from, next) => {
  try {
    if (to.params.sessionId) {
      const isRegistered = store.getters['settings/isRegistered'];
      if (!isRegistered && to.name === LOGIN) {
        next({ name: REGISTER, params: { recruitmentProcessId: to.params.recruitmentProcessId, sessionId: to.params.sessionId } });

        return;
      }
      if (isRegistered && to.name === REGISTER) {
        next({ name: LOGIN, params: { recruitmentProcessId: to.params.recruitmentProcessId, sessionId: to.params.sessionId } });

        return;
      }
    }
    if (!to.params.sessionId) {
      if (to.name === LOGIN) {
        next({ name: REGISTER, params: { recruitmentProcessId: to.params.recruitmentProcessId } });

        return;
      }
    }

    next();
  } catch (e) {
    if (e.response?.status === HTTP_UNAUTHORIZED) {
      next();

      return;
    }
    if (e.response?.status === HTTP_NOT_FOUND) {
      next({ name: NOT_FOUND });

      return;
    }
    throw new Error(e);
  }
};

const auth = [
  {
    path: 'choose-language',
    name: CHOOSE_LANG,
    component: ChooseLang,
    beforeEnter: (to, from, next) => {
      try {
        const gameHasCollections = store.getters['settings/gameHasCollections'];
        const sessionLocale = store.getters['session/sessionLocale'];
        if (!gameHasCollections || (sessionLocale && gameHasCollections)) {
          next({ name: REGISTER, params: { recruitmentProcessId: to.params.recruitmentProcessId, sessionId: to.params.sessionId } });

          return;
        }

        next();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          next();

          return;
        }
        throw new Error(e);
      }
    },
  },
  {
    path: 'login',
    name: LOGIN,
    component: Login,
    beforeEnter: checkRegisterStatus,
  },
  {
    path: 'register',
    name: REGISTER,
    component: Register,
    beforeEnter: checkRegisterStatus,
  },
];

export default auth;
