import actions from '@/store/settings/actions';
import getters from '@/store/settings/getters';
import mutations from '@/store/settings/mutations';
import state from '@/store/settings/state';

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
