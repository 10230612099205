<template>
  <div>
    <div class="scroll-wrapper">
      <slot />
    </div>
    <FadeIn appear>
      <div
        v-if="isAbleToScroll"
        class="scroll-button"
        @click="scroll"
      >
        <Icon
          name="scroll-arrow"
          :color="variables.white"
          :class="isScrolledToBottom && 'reverse'"
        />
      </div>
    </FadeIn>
  </div>
</template>
<script>
import { install } from 'resize-observer';
import Icon from '@/components/Icon/Icon';
import FadeIn from '@/components/Transition/FadeInTransition';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'ScrollButton',
  components: {
    Icon,
    FadeIn,
  },
  data() {
    return {
      variables,
      isScrolledToBottom: false,
      isAbleToScroll: false,
      scrollElement: null,
      resizeObserver: null,
    };
  },
  mounted() {
    if (!window.ResizeObserver) install();
    window.addEventListener('scroll', this.detectScrollable, true);
    this.resizeObserver = new ResizeObserver(() => {
      this.detectScrollable();
    });
    this.scrollElement = this.$slots.default[0].elm;
    this.resizeObserver.observe(this.scrollElement);
    this.detectScrollable();
  },
  destroyed() {
    window.removeEventListener('scroll', this.detectScrollable, true);
    this.resizeObserver.unobserve(this.scrollElement);
  },
  methods: {
    detectScrollable() {
      const element = this.scrollElement;
      const elementScrollHeight = element.scrollHeight;
      const actual = Math.abs(element.scrollTop);
      const elementHeight = element.clientHeight;
      this.isScrolledToBottom = elementScrollHeight - actual <= elementHeight;
      this.isAbleToScroll = element.scrollHeight > element.clientHeight;
    },
    scroll() {
      this.scrollElement.scroll({
        top: this.isScrolledToBottom ? 0 : this.scrollElement.scrollHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll-button {
  width: 48px;
  height: 48px;
  background-color: var(--primary-color);
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 4rem;
  bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  z-index: 9996;
  box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24);
  cursor: pointer;
  svg {
    transition: transform 0.3s ease-out;
    width: 50%;
    height: 50%;
    &.reverse {
      transform: rotate(180deg);
    }
  }
}
</style>
