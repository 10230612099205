import CS from '@/trans/cs';
import DE from '@/trans/de';
import EN from '@/trans/en';
import FR from '@/trans/fr';
import HU from '@/trans/hu';
import IT from '@/trans/it';
import NL from '@/trans/nl';
import PL from '@/trans/pl';
import RO from '@/trans/ro';
import SK from '@/trans/sk';
import HR from '@/trans/hr';
import PT from '@/trans/pt';
import SL from '@/trans/sl';
import UA from '@/trans/ua';

export default {
  CS,
  DE,
  EN,
  FR,
  HU,
  IT,
  NL,
  PL,
  RO,
  SK,
  HR,
  PT,
  SL,
  UA,
};
