export default {
  warning: {
    title: 'Er is een fout opgetreden',
    content: 'Probeer het opnieuw en als de fout blijft optreden, laat het ons weten per',
    content_without_email: 'Probeer het opnieuw en als de fout blijft aanhouden, neem dan contact op met de technische ondersteuning.',
    confirm: 'Sluiten',
  },
  end_time: {
    title: 'Fout melden',
    content: 'Als er tijdens uw spel een fout optreedt, neem dan contact op via het volgende e-mailadres:',
  },
  record_video_error: {
    title: 'Geen toegang tot de camera en/of microfoon.',
    content: 'Om deze taak te voltooien, wijzig uw instellingen en sta de applicatie toe om toegang te krijgen tot uw camera en/of microfoon.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Niet-ondersteund apparaat</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Om verder te gaan, gelieve een <strong>ander apparaat en/of browser te gebruiken.</strong></p>
        <p>De wervingshulpmiddel die u van plan bent te gebruiken bevat minstens één taak waarbij u uw antwoord in videoformaat moet opnemen. Helaas staan niet alle apparaten en/of browsers dit correct toe.</p>
        <p><strong>Als u dit scherm ziet, betekent dit dat uw apparaat en/of browser niet de correcte opname en verzending van een videobestand kunnen garanderen.</strong> Hieronder vindt u de aanbevolen instellingen.</p>
        <br>
        <p><strong>Desktopapparaten:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobiele apparaten:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Weet u zeker dat u de taak wilt overslaan?',
    content: '<p>Het opnemen van een video-antwoord voor deze taak is niet verplicht, maar wordt aanbevolen - het zal ons helpen u beter te leren kennen. Weet u zeker dat u het opnemen van een video-antwoord wilt overslaan?</p>',
  },
  skip_stage_with_record: {
    title: 'Weet u zeker dat u de taak wilt overslaan?',
    content: '<p>U hebt de bedoeling om het opslaan van de opgenomen video-antwoord over te slaan - het zal niet toegankelijk zijn voor de persoon die het wervingsproces uitvoert. Het uploaden van een video-antwoord voor deze taak is niet verplicht, maar wordt aanbevolen - het zal ons helpen u beter te leren kennen. Bent u zeker dat u het opslaan van het opgenomen video-antwoord wilt overslaan?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Probleemoplossing Camera en Microfoon</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Als de opname of het afspelen niet correct is, kunt u enkele stappen volgen die het probleem zouden moeten oplossen. De meest voorkomende oorzaken van problemen zijn te wijten aan de instellingen van het apparaat, inclusief andere programma's of applicaties die tegelijkertijd de camera en/of microfoon gebruiken.</p>
      <p>Mogelijke oplossingen:</p>
      <ol>
        <li>Zorg ervoor dat de browser toestemming heeft om de microfoon en camera te gebruiken. Zo niet, wijzig de instellingen en ververs de pagina.</li>
        <li>Zorg ervoor dat geen enkele andere applicatie of programma momenteel de camera/microfoon gebruikt (bijv. MS Teams, Photo Booth, of FaceTime) - sluit de applicaties die het gebruik van de camera door de browser blokkeren en ververs de pagina.</li>
        <li>Controleer of uw browser is bijgewerkt naar de nieuwste versie - zo niet, voer een update uit en herhaal de test.</li>
        <li>Als de bovenstaande stappen niet hebben geholpen, probeer dan een andere browser of apparaat te gebruiken.</li>
      </ol>
      <p>Als het probleem aanhoudt, laat het ons weten op <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> door informatie over het apparaat en de browser waarop het probleem voorkomt te sturen.</p>
      </div>
      <hr>
    `,
  },
};
