<template>
  <div
    v-if="hasErrors"
    class="backend-errors"
  >
    <span
      v-for="(error, index) in backendErrors"
      :key="`error-${index}`"
    >
      {{ error }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'BackendErrors',
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasErrors() {
      return !!this.backendErrors.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.backend-errors {
  padding-top: 1rem;
  span {
    display: block;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $red;
  }
}
</style>
