export default {
  checkbox_required: 'Označi %{name}',
  required: 'Vnesite %{name}',
  phone_incorrect: 'Vnesite pravilno telefonsko številko',
  min_length: 'Minimalno število znakov je: %{length}',
  max_length: 'Maksimalno število znakov je: %{length}',
  max_file_size: 'Največja velikost datoteke je %{size} mb',
  recaptcha: 'Označi Recaptcha',
  email_incorrect: 'Nepravilen format',
  max_value: 'Največja vrednost mora biti %{value} ali manj',
  min_value: 'Najmanjša vrednost mora biti %{value} ali več',
  numeric: 'Napačen format številke',
  numeric_required: '%{name} je obvezno in lahko vsebuje le cela števila',
};
