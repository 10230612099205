export default {
  choose: 'Izaberi',
  badges: {
    title: 'Značke',
    badge: 'Značka',
    my_badges: 'Moje značke',
    received: 'Dobivate značku',
    continue: 'Nastavi igrati',
    show_badges: 'Pogledaj značke',
    show_badge: 'Pogledaj nagradu',
  },
  record_test: {
    intro: 'TESTIRANJE POSTAVKI KAMERE I MIKROFONA',
    content: 'Prije nego što započnete s alatom, testirajte svoje postavke. U polju ispod trebali biste vidjeti sliku sa svoje kamere. Ako je vidite, to znači da su vaše postavke vjerojatno ispravne - da biste bili sigurni, snimite i reproducirajte video. Ako ne vidite sliku s kamere, ne možete reproducirati snimku ili imate druge sumnje, kliknite na gumb „RJEŠAVANJE PROBLEMA“.',
    troubleshoot: 'RJEŠAVANJE PROBLEMA',
  },
  questions: {
    progress: 'Pitanje',
    accept: 'Potvrdi',
    question_has_already_been_answered: 'Ovo pitanje već je odgovoreno.',
  },
  not_found: {
    code: '404',
    title: 'Stranica ne postoji',
    content: 'Stranica koju tražite nije pronađena. Mogla je biti izbrisana, promijenjeno joj je ime ili uopće nije postojala',
  },
};
