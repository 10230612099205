export default class Feedback {
  /**
   * @param {String} body
   * @param {String} imageUrl
   */
  constructor({ title, imageUrl }) {
    this._body = title;
    this._imageUrl = imageUrl;
  }

  /**
   * @return {String}
   */
  get imageUrl() {
    return this._imageUrl;
  }

  /**
   * @return {String}
   */
  get body() {
    return this._body;
  }
}
