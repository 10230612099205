export default class GameRecordSessionRecord {
  /**
   * @param {Date} createAt
   */
  constructor({ createAt }) {
    this._createdAt = createAt;
  }

  /**
   * @return {Date}
   */
  get createdAt() {
    return this._createdAt;
  }
}
