import Stage from '@/domain/entities/flow/Stage';

export default class GameMessage extends Stage {
  /**
   * @param {String} id
   * @param {String} type
   * @param {Number} order
   * @param {?Date} completedAt
   * @param {String} title
   * @param {String} body
   * @param {?Feedback} feedback
   * @param {?Badge} badge
   * @param {String} sideImageUrl
   */
  constructor({
    id,
    type,
    order,
    completedAt,
    title,
    body,
    feedback,
    badge,
    sideImageUrl,
  }) {
    super({
      id,
      type,
      order,
      completedAt,
      sideImageUrl,
    });

    this._title = title;
    this._body = body;
    this._feedback = feedback;
    this._badge = badge;
  }

  /**
   * @return {String}
   */
  get title() {
    return this._title;
  }

  /**
   * @return {String}
   */
  get body() {
    return this._body;
  }

  /**
   * @return {?Feedback}
   */
  get feedback() {
    return this._feedback;
  }
}
