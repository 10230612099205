import '@ungap/global-this';
import '@/assets/styles/main.scss';
import Vue from 'vue';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import '@/common/axios/api';
import '@/common/sentry/sentry';
import '@/common/global/components';
import '@/common/validation/validator';
import App from '@/App';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
