export default {
  name: '313C',
  recruitment_game: 'Igra za zaposlitev',
  back: 'Nazaj',
  cancel: 'Prekliči',
  continue: 'Nadaljuj',
  skip: 'Preskoči',
  start: 'Začni',
  close: 'Zapri',
  help: 'Pomoč',
  time_left: 'Preostali čas',
  progress: 'Napredek',
  privacy_policy: 'Pravilnik o zasebnosti',
  cookies_policy: 'Pravilnik o piškotkih in pogoji',
  faq: 'Vprašanja in odgovori',
  messages: 'Sporočila',
  unread: 'neprebrano',
  start_recording: 'Začetek snemanja',
  stop_recording: 'Ustavitev snemanja',
  remove_record: 'Odstranitev zapisa',
};
