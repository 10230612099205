import alerts from '@/trans/hr/alerts';
import cookies from '@/trans/hr/cookies';
import general from '@/trans/hr/general';
import game from '@/trans/hr/game';
import validation from '@/trans/hr/validation';
import auth from '@/trans/hr/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
