export default {
  checkbox_required: 'Позначте %{name}',
  required: 'Введіть %{name}',
  phone_incorrect: 'Введіть правильний номер телефону',
  min_length: 'Мінімальна кількість символів становить: %{length}',
  max_length: 'Максимальна кількість символів становить: %{length}',
  max_file_size: 'Максимальний розмір файлу становить %{size} mb',
  recaptcha: 'Позначте Recaptcha',
  email_incorrect: 'Невірний формат',
  max_value: 'Максимальне значення має бути %{value} або менше',
  min_value: 'Мінімальне значення має бути %{value} або більше',
  numeric: 'Неправильний формат номера',
  numeric_required: '%{name} є обов\'язковим і може містити лише цілі числа',
};
