export default {
  warning: {
    title: 'Errore riscontrato',
    content: 'Prova di nuovo e se l\'errore persiste, facci sapere per ',
    content_without_email: 'Prova ancora e se l\'errore persiste, contatta il supporto tecnico',
    confirm: 'Chiusura',
  },
  end_time: {
    title: 'Segnala errore',
    content: 'Se durante la tua partita si è verificato un errore, contattaci all\'indirizzo email:',
  },
  record_video_error: {
    title: 'Nessun accesso alla fotocamera e/o al microfono.',
    content: "Per completare questo compito, modifica le tue impostazioni e consenti all'applicazione di accedere alla fotocamera e/o al microfono.",
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Dispositivo non supportato</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Per continuare, ti preghiamo di utilizzare un <strong>dispositivo e/o un browser diverso.</strong></p>
        <p>Lo strumento di reclutamento che intendi utilizzare include almeno un compito che richiede la registrazione della tua risposta in formato video. Purtroppo, non tutti i dispositivi e/o browser consentono di farlo correttamente.</p>
        <p><strong>Se visualizzi questa schermata, significa che il tuo dispositivo e/o browser non garantisce la corretta registrazione e invio di un file video.</strong> Di seguito troverai le impostazioni consigliate.</p>
        <br>
        <p><strong>Dispositivi desktop:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Dispositivi mobili:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Sei sicuro di voler saltare il compito?',
    content: '<p>La registrazione di una risposta video per questo compito non è obbligatoria, ma consigliata - ci aiuterà a conoscerti meglio. Sei sicuro di voler saltare la registrazione di una risposta video?</p>',
  },
  skip_stage_with_record: {
    title: 'Sei sicuro di voler saltare il compito?',
    content: '<p>Hai intenzione di saltare il salvataggio della risposta video registrata: essa non sarà accessibile alla persona che conduce il processo di selezione. Il caricamento di una risposta video per questo compito non è obbligatorio, ma consigliato - ci aiuterà a conoscerti meglio. Sei sicuro di voler saltare il salvataggio della risposta video registrata?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Risoluzione dei Problemi di Camera e Microfono</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Se la registrazione o la riproduzione non è corretta, puoi seguire alcuni passaggi che dovrebbero risolvere il problema. Le cause più comuni dei problemi derivano dalle impostazioni del dispositivo, inclusi altri programmi o applicazioni che utilizzano contemporaneamente la camera e/o il microfono.</p>
      <p>Soluzioni possibili:</p>
      <ol>
        <li>Assicurati che il browser abbia i permessi per usare il microfono e la camera. Se non è così, cambia le impostazioni e aggiorna la pagina.</li>
        <li>Assicurati che nessun'altra applicazione o programma stia attualmente usando la camera/il microfono (es. MS Teams, Photo Booth o FaceTime) - chiudi le applicazioni che bloccano la possibilità di utilizzare la camera tramite il browser e aggiorna la pagina.</li>
        <li>Controlla se il tuo browser è aggiornato all'ultima versione - se non lo è, effettua un aggiornamento e ripeti il test.</li>
        <li>Se i passaggi sopra non hanno aiutato, prova a utilizzare un altro browser o dispositivo.</li>
      </ol>
      <p>Se il problema persiste, faccelo sapere a <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> inviando informazioni sul dispositivo e sul browser in cui si verifica il problema.</p>
      </div>
      <hr>
    `,
  },
};
