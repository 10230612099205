export default {
  /**
   * @return {Boolean}
   */
  hasSessionData(store) {
    return store.session !== null;
  },
  /**
   * @return {String}
   */
  remainingTime(store) {
    return store.session.game_remaining_time;
  },
  /**
   * @return {Boolean}
   */
  isGameStarted(store) {
    return !!store.session.game_started_at;
  },
  /**
   * @return {Boolean}
   */
  isGameFinished(store) {
    return !!store.session.game_finished_at;
  },
  /**
   * @return {Boolean}
   */
  hasGameTimeLimit(store) {
    return !!store.gameTimeLimit;
  },
  sessionId(store) {
    return store.session !== null ? store.session.id : null;
  },
  currentRoute(store) {
    return store.currentRoute;
  },
  sessionLocale(store) {
    return store.session !== null ? store.session.locale : null;
  },
};
