<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="submit">
      <span
        v-if="error"
        class="login-form__error"
      >
        {{ $t('validation.phone_incorrect') }}
      </span>
      <div
        class="login-form__phone-input"
      >
        <BasePhoneInput
          v-model="formData.phoneNumber"
          :label="$t('auth.phone')"
          mode="eager"
          rules="min:8|max:15|required"
        />
      </div>
      <div class="login-form__button">
        <BaseButton
          :is-loading="isLoading"
          type="submit"
        >
          {{ continueBtnName }}
        </BaseButton>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import BaseButton from '@/components/Form/BaseButton';
import BasePhoneInput from '@/components/Form/BasePhoneInput';

export default {
  name: 'LoginForm',
  components: {
    BaseButton,
    BasePhoneInput,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
    continueBtnName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        phoneNumber: '',
      },
    };
  },
  methods: {
    async submit() {
      if (this.isLoading) return;
      const validationStatus = await this.$refs.form.validate();
      if (!validationStatus) return;

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      return {
        phoneNumber: this.formData.phoneNumber,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  &__phone-input {
    position: relative;
    margin: 8.5rem 0 0;
    max-width: 450px;
    @include media-min($lg) {
      margin: 6.5rem 0 0;
      max-width: 350px;
    }
    @include media-min($xl) {
      max-width: 450px;
    }
  }
  &__button {
    margin-top: 5.8rem;
    @include media-min($lg) {
      margin-top: 7.2rem;
      padding-bottom: 10rem;
    }
  }
  &__error {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding-top: 1rem;
    color: $red;
  }
}
</style>
