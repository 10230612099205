import state from '@/store/session/state';
import actions from '@/store/session/actions';
import mutations from '@/store/session/mutations';
import getters from '@/store/session/getters';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
