<template>
  <button
    v-tippy="{ animation : 'fade' }"
    class="tooltip"
    :content="tooltip"
    type="button"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.456 7.4L9.736 8.136C9.16 8.712 8.8 9.2 8.8 10.4H7.2V10C7.2 9.112 7.56 8.312 8.136 7.736L9.128 6.728C9.424
        6.44 9.6 6.04 9.6 5.6C9.6 4.712 8.88 4 8 4C7.57565 4 7.16869 4.16857 6.86863 4.46863C6.56857 4.76869 6.4 5.17565
        6.4 5.6H4.8C4.8 4.75131 5.13714 3.93737 5.73726 3.33726C6.33737 2.73714 7.15131 2.4 8 2.4C8.84869 2.4 9.66263
        2.73714 10.2627 3.33726C10.8629 3.93737 11.2 4.75131 11.2 5.6C11.2 6.304 10.912 6.936 10.456 7.4ZM8.8
        13.6H7.2V12H8.8V13.6ZM8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028
        2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601
        14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571
        13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 3.576 12.4 0 8 0Z"
        fill="#979797"
      />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'Tooltip',
  props: {
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
.tooltip {
  display: flex;
  align-items: center;
  background: none;
  padding-bottom: 1px;
}
.tippy-tooltip {
  padding: 1.4rem;
}
.tippy-backdrop {
  border-radius: 2px;
}
.tippy-tooltip {
  font-size: 12px;
  line-height: 16px;
}
</style>
