import store from '@/store';
import router from '@/router';
import { HTTP_UNAUTHORIZED } from '@/common/axios/codes';
import { LOGIN } from '@/router/routes.names';

// eslint-disable-next-line import/prefer-default-export
export const errorResponseHandler = async (error) => {
  if (error.response?.status === HTTP_UNAUTHORIZED) {
    const currentRoute = store.getters['session/currentRoute'];

    await router.push({
      name: LOGIN,
      params: { recruitmentProcessId: currentRoute.params.recruitmentProcessId, sessionId: currentRoute.params.sessionId },
    });
  }

  return Promise.reject(error);
};
