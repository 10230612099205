export default {
  checkbox_required: 'Markieren %{name}',
  required: 'Geben Sie ein %{name}',
  phone_incorrect: 'Geben Sie eine gültige Telefonnummer ein',
  min_length: 'Mindestanzahl der Zeichen beträgt: %{length}',
  max_length: 'Maximale Anzahl der Zeichen beträgt: %{length}',
  max_file_size: 'Maximaler Dateigröße beträgt %{size} mb',
  recaptcha: 'Recaptcha bestätigen',
  email_incorrect: 'Ungültiges Format',
  max_value: 'Der maximale Wert muss %{value} oder weniger betragen',
  min_value: 'Der minimale Wert muss %{value} oder mehr betragen',
  numeric: 'Falsches Zahlenformat',
  numeric_required: '%{name} ist erforderlich und kann nur Ganzzahlen enthalten',
};
