import GameQuestion from '@/domain/entities/flow/GameQuestion';
import { immerable, produce } from 'immer';

export default class ClosedGameQuestion extends GameQuestion {
  [immerable] = true;

  /**
   * @param {String} id
   * @param {String} type
   * @param {Number} order
   * @param {String} body
   * @param {Array<ClosedGameQuestionAnswer>} answers
   * @param {?Date} completedAt
   * @param {String} questionId
   * @param {?Badge} badge
   * @param {?String} instruction
   * @param {?String} imageUrl
   * @param {String} sideImageUrl
   * @param {String} header
   */
  constructor({
    id,
    type,
    order,
    body,
    answers,
    completedAt,
    questionId,
    badge,
    instruction,
    imageUrl,
    sideImageUrl,
    header,
  }) {
    super({
      id,
      type,
      order,
      body,
      completedAt,
      questionId,
      badge,
      instruction,
      imageUrl,
      sideImageUrl,
      header,
    });

    this._answers = answers;
  }

  /**
   * @return {ClosedGameQuestion}
   */
  markBadgeAsCompleted() {
    const chosenAnswer = this.chosenAnswer();

    return produce(this, (draft) => {
      if (chosenAnswer.badge) {
        const answerIndex = this._answers.findIndex(
          (findAnswer) => findAnswer.id === chosenAnswer.id,
        );

        // eslint-disable-next-line no-param-reassign
        draft._answers[answerIndex] = draft._answers[answerIndex].markBadgeAsCompleted();

        return;
      }

      // eslint-disable-next-line no-param-reassign
      draft._badge = draft._badge?.markAsCompleted();
    });
  }

  /**
   * @return {ClosedGameQuestionAnswer}
   */
  chosenAnswer() {
    return this._answers.find((answer) => answer.isChosen);
  }

  /**
   * @param {ClosedGameQuestionAnswer} answer
   * @return {ClosedGameQuestion}
   */
  chooseAnswer(answer) {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._answers = draft._answers.map((mapAnswer) => (mapAnswer.id === answer.id ? mapAnswer.choose() : mapAnswer.unChoose()));
    });
  }

  /**
   * @return {{answer: null, answer_id: String}[]}
   */
  chosenAnswersRequestData() {
    const chosenAnswer = {
      answer_id: this.chosenAnswer().id,
      answer: null,
    };

    return [chosenAnswer];
  }

  /**
   * @param sessionAnswers{Array<GameQuestionSessionAnswer>}
   * @return {BestWorstGameQuestion}
   */
  updateSessionAnswers(sessionAnswers) {
    const sessionAnswer = sessionAnswers.find((findSessionAnswer) => findSessionAnswer);

    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._answers = draft._answers.map((mapAnswer) => (mapAnswer.id === this.chosenAnswer().id
        ? mapAnswer.updateSessionAnswer(sessionAnswer)
        : mapAnswer));
    });
  }

  /**
   * @return {?Badge}
   */
  possibleBadge() {
    return this._badge || this._answers.find((answer) => answer.badge)?.badge;
  }

  /**
   * @return {Array<ClosedGameQuestionAnswer>}
   */
  get answers() {
    return this._answers;
  }
}
