<template>
  <div class="menu">
    <div class="menu__options">
      <div
        v-if="hasSessionData && !isGameFinished && isGameStarted && !isBadgeRoute && badges.length"
        class="menu__option"
        @click="chooseMenuLink(badgesRoute)"
      >
        <span class="menu__badge--title">{{ $t("general.badges.title") }}:</span>
        <BadgeItem
          v-for="(badge, index) in badges"
          :key="`badge-${index}`"
          :badge="badge"
        />
      </div>
      <div
        v-if="!isFaqRoute && hasFrequentlyAskedQuestionsData"
        class="menu__option"
        @click="chooseMenuLink(faqRoute)"
      >
        <div class="menu__icon">
          <Icon name="help" />
        </div>
        <span class="menu__option--title">
          {{ $t("game.help") }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon/Icon';
import BadgeItem from '@/components/TopBar/Badge/BadgeItem';
import { BADGES, FAQ } from '@/router/routes.names';

export default {
  name: 'Menu',
  components: {
    Icon,
    BadgeItem,
  },
  data() {
    return {
      faqRoute: FAQ,
      badgesRoute: BADGES,
    };
  },
  computed: {
    ...mapGetters('settings', ['hasFrequentlyAskedQuestionsData']),
    ...mapGetters('session', ['hasSessionData', 'isGameStarted', 'isGameFinished']),
    ...mapGetters('flow', ['badges']),
    isFaqRoute() {
      return this.$route.name === this.faqRoute.name;
    },
    isBadgeRoute() {
      return this.$route.name === this.badgesRoute.name;
    },
  },
  methods: {
    chooseMenuLink(routeName) {
      this.$router.push({ name: routeName, params: this.$route.params });
      this.$emit('change-route');
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
  @include media-min($lg) {
    display: none;
  }
  &__options {
    padding: 1.5rem;
    flex: 1;
  }
  &__option {
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 1px solid $gray200;
    cursor: pointer;
    &--title {
      flex: 1;
      padding: 0 1.2rem;
    }
  }
  &__badge {
    padding: 0 0.7rem;
    &--title {
      font-weight: 500;
      padding-right: 0.5rem;
    }
  }
  &__icon {
    svg {
      width: 46px;
      height: 46px;
    }
  }
}
</style>
