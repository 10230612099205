import Stage from '@/domain/entities/flow/Stage';

export default class GameVideo extends Stage {
  /**
   * @param {String} id
   * @param {String} type
   * @param {Number} order
   * @param {?Date} completedAt
   * @param {String} title
   * @param {Object} files
   */
  constructor({
    id,
    type,
    order,
    completedAt,
    title,
    files,
  }) {
    super({
      id,
      type,
      order,
      completedAt,
    });

    this._title = title;
    this._files = files;
  }

  /**
   * @return {Array}
   */
  videos() {
    return [
      {
        url: this._files.WEBM,
        type: 'video/webm',
      },
      {
        url: this._files.MP4,
        type: 'video/mp4',
      },
    ];
  }

  /**
   * @return {String}
   */
  get title() {
    return this._title;
  }

  /**
   * @return {Object}
   */
  get files() {
    return this._files;
  }
}
