import GameQuestionAnswer from '@/domain/entities/flow/GameQuestionAnswer';
import { immerable, produce } from 'immer';

const BEST_TYPE_NAME = 'BEST';
const WORST_TYPE_NAME = 'WORST';

export default class BestWorstGameQuestionAnswer extends GameQuestionAnswer {
  [immerable] = true

  /**
   * @param {String} id
   * @param {?String} body
   * @param {?Feedback} feedback
   * @param {?Badge} badge
   * @param {?GameQuestionSessionAnswer} sessionAnswer
   * @param {Boolean} choosenAsBestAnswer
   * @param {Boolean} choosenAsWorstAnswer
   */
  constructor({
    id, body, feedback, badge, sessionAnswer, choosenAsBestAnswer, choosenAsWorstAnswer,
  }) {
    super({
      id, body, feedback, badge, sessionAnswer,
    });

    this._choosenAsBestAnswer = choosenAsBestAnswer;
    this._choosenAsWorstAnswer = choosenAsWorstAnswer;
  }

  /**
   * @return {BestWorstGameQuestionAnswer}
   */
  chooseBestAnswer() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._choosenAsBestAnswer = true;
    });
  }

  /**
   * @return {BestWorstGameQuestionAnswer}
   */
  unChooseBestAnswer() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._choosenAsBestAnswer = false;
    });
  }

  /**
   * @return {BestWorstGameQuestionAnswer}
   */
  chooseWorstAnswer() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._choosenAsWorstAnswer = true;
    });
  }

  /**
   * @return {BestWorstGameQuestionAnswer}
   */
  unChooseWorstAnswer() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._choosenAsWorstAnswer = false;
    });
  }

  /**
   * @return {BestWorstGameQuestionAnswer}
   */
  unChoose() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._choosenAsBestAnswer = false;
      // eslint-disable-next-line no-param-reassign
      draft._choosenAsWorstAnswer = false;
    });
  }

  /**
   * @param {GameQuestionSessionAnswer} sessionAnswer
   * @return {BestWorstGameQuestionAnswer}
   */
  updateSessionAnswer(sessionAnswer) {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._sessionAnswer = sessionAnswer;
    });
  }

  /**
   * @return {boolean}
   */
  get isChosenAsBestAnswer() {
    return this._choosenAsBestAnswer;
  }

  /**
   * @return {boolean}
   */
  get isChosenAsWorstAnswer() {
    return this._choosenAsWorstAnswer;
  }

  /**
   * @return {String}
   */
  static get BEST_TYPE_NAME() {
    return BEST_TYPE_NAME;
  }

  /**
   * @return {String}
   */
  static get WORST_TYPE_NAME() {
    return WORST_TYPE_NAME;
  }

  /**
   * @return {?GameQuestionSessionAnswer}
   */
  get sessionAnswer() {
    return this._sessionAnswer;
  }
}
