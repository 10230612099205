import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

if (process.env.VUE_APP_ENVIRONMENT !== 'local') {
  const ignoreErrors = [
    /navigation/gm,
    'Network Error',
  ];
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors,
  });
}

export default Sentry;
