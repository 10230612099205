export default {
  checkbox_required: 'Selecionar %{name}',
  required: 'Digite %{name}',
  phone_incorrect: 'Digite um número de telefone válido',
  min_length: 'O número mínimo de caracteres é: %{length}',
  max_length: 'O número máximo de caracteres é: %{length}',
  max_file_size: 'O tamanho máximo do arquivo é %{size} mb',
  recaptcha: 'Marque a Recaptcha',
  email_incorrect: 'Formato inválido',
  max_value: 'O valor máximo deve ser %{value} ou menos',
  min_value: 'O valor mínimo deve ser %{value} ou mais',
  numeric: 'Formato incorreto do número',
  numeric_required: '%{name} é obrigatório e pode conter apenas números inteiros',
};
