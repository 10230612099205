<template>
  <div>
    <div
      class="burger"
      :class="{ active: isOpen }"
      @click="toggleMenu"
    >
      <button
        class="burger__icon"
        type="button"
      >
        <span class="burger__bar" />
        <span class="burger__bar" />
        <span class="burger__bar" />
      </button>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <Menu
        v-if="isOpen"
        @change-route="toggleMenu"
      />
    </transition>
  </div>
</template>
<script>
import Menu from '@/components/MobileMenu/Menu';

export default {
  name: 'Burger',
  components: {
    Menu,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
.burger {
  &.active .burger__icon {
    transform: rotate(-180deg);
  }
  &.active .burger__bar:nth-of-type(1) {
    transform: rotate(45deg);
  }
  &.active .burger__bar:nth-of-type(2) {
    opacity: 0;
  }
  &.active .burger__bar:nth-of-type(3) {
    transform: rotate(-45deg) scaleX(1);
    transform-origin: 50% 50%;
  }
  &__icon {
    position: relative;
    height: 30px;
    width: 34px;
    display: block;
    z-index: 60;
    border: 0;
    border-radius: 0;
    background-color: $white;
    pointer-events: all;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &__bar {
    background-color: $black;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    margin-top: -1px;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
      background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &__bar:nth-of-type(1) {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  &__bar:nth-of-type(3) {
    transform-origin: 100% 50%;
    transform: translateY(6px) scaleX(0.8);
  }
}
</style>
