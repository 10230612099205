<template>
  <transition
    appear
    @before-enter="beforeEnter"
    @enter="enter"
  >
    <div class="badges">
      <div class="badges__wrapper">
        <div class="badges__nav">
          <BackButton class="badges__button" />
          <span class="badges__title--desktop">
            {{ $t("general.badges.my_badges") }}
          </span>
          <div class="badges__counter">
            <span class="badges__counter--number">
              {{ completedBadgesCount }}/{{ badges.length }}
            </span>
            <span>
              {{ $t("general.badges.badge") }}
            </span>
          </div>
        </div>
        <div class="badges__content">
          <span class="badges__title--mobile">
            {{ $t("general.badges.my_badges") }}
          </span>
          <BadgeList :badges="badges" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import gsap from 'gsap';
import { mapGetters } from 'vuex';
import BadgeList from '@/components/Badge/BadgeList';
import BackButton from '@/components/Content/BackButton';

export default {
  name: 'Badges',
  components: {
    BadgeList,
    BackButton,
  },
  computed: {
    ...mapGetters('flow', ['badges', 'completedBadgesCount']),
  },
  methods: {
    beforeEnter(el) {
      gsap.set(el, { autoAlpha: 0 });
    },
    enter(el, done) {
      gsap
        .fromTo(el, {
          autoAlpha: 0,
        }, {
          autoAlpha: 1,
          duration: 0.4,
          onComplete: done,
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.badges {
  padding: 0;
  @include media-min($lg) {
    padding: 2rem 5rem 20rem;
  }
  &__wrapper {
    max-width: 1500px;
    max-height: none;
    padding: 1.5rem;
    margin: 0 auto;
    @include media-min($lg) {
      padding: 0 2rem;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 1rem 0 2rem;
    border-bottom: 1px solid $gray400;
    @include media-min($lg) {
      padding: 2rem 0;
      justify-content: center;
      margin-bottom: 5rem;
    }
  }
  &__button {
      @include media-min($lg) {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  &__title {
    &--mobile {
      color: var(--primary-color);
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 2rem;
      margin: 3.6rem 0 2rem;
      text-align: center;
      @include media-min($lg) {
        display: none;
      }
    }
    &--desktop {
      color: var(--primary-color);
      display: none;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 2rem;
      padding: 1rem 0;
      @include media-min($lg) {
        display: block;
      }
    }
  }
  &__counter {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @include media-min($lg) {
      flex-direction: row;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &--number {
      color: var(--primary-color);
      background-color: var(--lighter-primary-color);
      display: block;
      padding: 0.2rem 1rem;
      margin-right: 1rem;
      border-radius: 4px;
      margin-left: 1rem;
    }
  }
  &__content {
    font-size: 1.6rem;
    line-height: 2.4rem;
    h4 {
      font-weight: 500;
    }
    p {
      font-weight: 400;
    }
  }
}
</style>
