import { immerable, produce } from 'immer';

/**
 * @abstract
 */
export default class Stage {
  [immerable] = true

  /**
   * @param {String} id
   * @param {String} type
   * @param {Number} order
   * @param {?Date} completedAt
   * @param {?Badge} badge
   * @param {String} sideImageUrl
   */
  constructor({
    id, type, order, completedAt, badge, sideImageUrl,
  }) {
    this._id = id;
    this._type = type;
    this._order = order;
    this._completedAt = completedAt;
    this._badge = badge;
    this._sideImageUrl = sideImageUrl;
  }

  /**
   * @return {Stage}
   */
  markBadgeAsCompleted() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._badge = draft._badge?.markAsCompleted();
    });
  }

  /**
   * @param {Date} completedAt
   * @return {Stage}
   */
  markAsCompleted(completedAt) {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      draft._completedAt = completedAt;
    });
  }

  /**
   * @return {boolean}
   */
  isCompleted() {
    return this._completedAt !== null;
  }

  /**
   * @return {?Badge}
   */
  possibleBadge() {
    return this._badge;
  }

  /**
   * @return {String}
   */
  get id() {
    return this._id;
  }

  /**
   * @return {String}
   */
  get sideImageUrl() {
    return this._sideImageUrl;
  }

  /**
   * @param {Date} completedAt
   * @return {?Badge}
   */
  get badge() {
    return this._badge;
  }
}
