<template>
  <transition
    name="fade-down-animation"
    mode="out-in"
  >
    <div
      v-if="!isCookie && hasRecruitmentProcessIdParam"
      class="cookies-bar"
    >
      <span>{{ $t("cookies.title") }}</span>
      <router-link
        class="cookies-bar__link"
        :to="cookiesRoute"
      >
        {{ $t("cookies.link") }}
      </router-link>
      <span>🍪!</span>
      <button
        class="cookies-bar__btn"
        @click="setCookie"
      >
        {{ $t("cookies.button") }}
      </button>
    </div>
  </transition>
</template>
<script>
import { COOKIES } from '@/router/routes.names';

export default {
  name: 'CookieBar',
  data() {
    return {
      isCookie: false,
      cookiesRoute: {
        name: COOKIES,
      },
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    hasRecruitmentProcessIdParam() {
      return this.$route.params.recruitmentProcessId;
    },
  },
  created() {
    this.getCookie();
  },
  updated() {
    this.getCookie();
  },
  methods: {
    setCookie() {
      this.$cookies.set('cookies', true);
      this.isCookie = true;
    },
    getCookie() {
      if (this.$cookies.get('cookies')) {
        this.isCookie = true;
      }
    },
  },
};
</script>
<style lang="scss">
.cookies-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: $white;
  padding: 1.2rem 1rem;
  text-align: center;
  z-index: 9999;
  font-size: 1.6rem;
  line-height: 2.8rem;
  box-shadow: 0px -41px 80px rgba(0, 0, 0, 0.05),
    0px -17.1288px 33.4221px rgba(0, 0, 0, 0.0359427),
    0px -9.15789px 17.869px rgba(0, 0, 0, 0.0298054),
    0px -5.13384px 10.0172px rgba(0, 0, 0, 0.025),
    0px -2.72654px 5.32008px rgba(0, 0, 0, 0.0201946),
    0px -1.13458px 2.21381px rgba(0, 0, 0, 0.0140573);
  &__link {
    color: var(--primary-color);
    font-weight: 500;
  }
  &__btn {
    background-color: var(--primary-color);
    color: $white;
    border-radius: 4px;
    text-transform: uppercase;
    margin: 0.5rem 2rem;
    padding: 1.2rem 3rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6rem;
  }
}
</style>
