export default {
  choose: 'Kies',
  badges: {
    title: 'Insignes',
    badge: 'Insigne',
    my_badges: 'Mijn insigne',
    received: 'Je krijgt een insigne',
    continue: 'Speel verder',
    show_badges: 'Bekijk insignes',
    show_badge: 'Zie de beloning',
  },
  record_test: {
    intro: 'CAMERA- EN MICROFOONINSTELLINGEN TEST',
    content: 'Voordat u begint met het gereedschap, test uw instellingen. In het veld hieronder zou u het beeld van uw camera moeten zien. Als u dit ziet, betekent het dat uw instellingen waarschijnlijk correct zijn - om zeker te zijn, neem een video op en speel deze af. Als u het beeld van de camera niet ziet, de opname niet kunt afspelen, of andere twijfels heeft, klik dan op de knop "PROBLEEM OPLOSSEN".',
    troubleshoot: 'PROBLEEM OPLOSSEN',
  },
  questions: {
    progress: 'Vraag',
    accept: 'Bevestig',
    question_has_already_been_answered: 'Deze vraag is al beantwoord.',
  },
  not_found: {
    code: '404',
    title: 'Pagina bestaat niet',
    content: 'De pagina die je zoekt is niet gevonden. Het kan zijn verwijderd, de naam is veranderd of het bestaat gewoon niet.',
  },
};
