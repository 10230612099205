<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="label"
    :rules="rules"
    mode="eager"
  >
    <Label
      :label="label"
      :tooltip="tooltip"
      :required="required"
    />
    <div
      class="base-calendar"
      :class="{ error: errors.length }"
    >
      <DatePicker
        v-model="date"
        :masks="masks"
        :locale="locale"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <div class="base-calendar__wrapper">
            <button
              type="button"
              @click="togglePopover()"
            >
              <Icon name="calendar" />
            </button>
            <input
              :value="inputValue"
              :required="required"
              readonly
            >
          </div>
        </template>
      </DatePicker>
    </div>
    <ValidationError :errors="errors" />
  </ValidationProvider>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatDate } from '@/common/dates/dates';
import Icon from '@/components/Icon/Icon';
import Label from '@/components/Form/Partials/Label';
import ValidationError from '@/components/Form/Partials/ValidationError';

export default {
  name: 'BaseCalendar',
  components: {
    Icon,
    Label,
    ValidationError,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {
      date: '',
      masks: {
        input: 'DD-MM-YYYY',
      },
    };
  },
  computed: {
    ...mapGetters('settings', ['locale']),
  },
  watch: {
    date(value) {
      this.$emit('input', this.formatDate(value));
    },
  },
  methods: {
    formatDate,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .vc-nav-popover-container {
  background-color: $white;
}
::v-deep .vc-nav-title {
  color: var(--primary-color);
}
::v-deep .vc-highlight {
  background-color: var(--primary-color) !important;
}
.base-calendar {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    border: 0;
    border: 1px solid $gray400;
    border-radius: 4px;
    padding: 2rem 1.6rem;
    transition: 0.5s border cubic-bezier(0.56, 0.33, 0.54, 0.95);
    &:focus {
      border: 1px solid var(--primary-color);
      outline: 0;
    }
  }
  &__wrapper {
    position: relative;
    button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: transparent;
      margin: 0.5rem;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  &.error {
    input {
      border-color: $red;
    }
  }
}
</style>
