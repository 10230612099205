export default {
  warning: {
    title: 'Сталася помилка',
    content: 'Спробуйте ще раз, і якщо помилка повторюється, повідомте нас за адресою',
    content_without_email: 'Спробуйте ще раз, і якщо помилка повторюється, зверніться до технічної підтримки.',
    confirm: 'Закрити',
  },
  end_time: {
    title: 'Повідомити про помилку',
    content: 'Якщо під час вашої гри сталася помилка, будь ласка, зв\'яжіться з нами за адресою електронної пошти:',
  },
  record_video_error: {
    title: 'Відсутній доступ до камери та/або мікрофона.',
    content: 'Щоб завершити це завдання, змініть налаштування та дозвольте додатку отримати доступ до вашої камери та/або мікрофона.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Непідтримуваний пристрій</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Дляпродовження,будьласка,використовуйте <strong>іншийпристрійі/абоіншийбраузер.</strong></p>
        <p>Інструментдлярекрутингу,якийвиплануєтевикористовувати,міститьпринаймніоднезавдання,якевимагаєвасзаписатисвоювідповідьуформатівідео.Нажаль,невсіпристроїі/абобраузеридозволяютьцезробитиправильно.</p>
        <p><strong>Якщовибачитецейекран,цеозначає,щовашпристрійі/абобраузернегарантуютьправильнезаписуваннятанадсиланнявідеофайлу.</strong> Нижченаведенорекомендованіналаштування.</p>
        <br>
        <p><strong>Настільніпристрої:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Мобільніпристрої:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Ви впевнені, що бажаєте пропустити завдання?',
    content: "<p>Запис відеовідповіді для цього завдання не обов'язковий, але рекомендується - це допоможе нам краще вас познати. Ви впевнені, що бажаєте пропустити запис відеовідповіді?</p>",
  },
  skip_stage_with_record: {
    title: 'Ви впевнені, що бажаєте пропустити завдання?',
    content: "<p>Ви збираєтеся пропустити збереження записаної відеовідповіді - вона не буде доступна для особи, яка проводить відбір. Відправлення відеовідповіді для цього завдання не є обов'язковим, але рекомендується - це допоможе нам краще вас познати. Ви впевнені, що бажаєте пропустити збереження записаної відеовідповіді?</p>",
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Усунення проблем з камерою та мікрофоном</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Якщо запис або відтворення не коректне, ви можете слідувати кільком крокам, які мають вирішити проблему. Найчастіші причини проблем виникають через налаштування пристрою, включаючи інші програми або додатки, які одночасно використовують камеру та/або мікрофон.</p>
      <p>Можливі рішення проблеми:</p>
      <ol>
        <li>Переконайтеся, що браузер має дозволи на використання мікрофону та камери. Якщо ні, змініть налаштування та оновіть сторінку.</li>
        <li>Переконайтеся, що жодна інша програма або додаток не використовує камеру/мікрофон наразі (наприклад, MS Teams, Photo Booth або FaceTime) - закрийте додатки, які блокують можливість використання камери браузером, та оновіть сторінку.</li>
        <li>Перевірте, чи ваш браузер оновлено до останньої версії - якщо ні, виконайте оновлення та повторіть тест.</li>
        <li>Якщо вищевказані кроки не допомогли, спробуйте використати інший браузер або пристрій.</li>
      </ol>
      <p>Якщо проблема залишається, повідомте нас на <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a>, надсилаючи інформацію про пристрій та браузер, на яких виникає проблема.</p>
      </div>
      <hr>
    `,
  },
};
