import api from '@/common/axios/api';

export default {
  async login(state, {
    phoneNumber,
    sessionId,
  }) {
    await api.post('login', {
      phone_number: phoneNumber,
      session_id: sessionId,
    });
  },

  async register(state, {
    recruitmentProcessId,
    sessionId = null,
    params,
  }) {
    const endpoint = sessionId === null ? `candidates/${recruitmentProcessId}` : `candidates/${recruitmentProcessId}/${sessionId}`;
    const { data: { data } } = await api.post(endpoint, params);

    return data;
  },
};
