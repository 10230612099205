<template>
  <button
    ref="button"
    class="base-button"
    :class="{ 'base-button--alternative': alternative, 'base-button--loading': isLoading }"
    :style="colorProps"
    :type="type"
    :disabled="disabled"
    @click="click"
  >
    <transition name="zoom-in">
      <div
        v-if="isLoading"
        class="base-button__spinner"
      >
        <Icon
          name="loading"
          :color="alternative ? interfaceColor : variables.white"
          class="base-button__icon"
        />
      </div>
    </transition>
    <slot />
    <transition-group>
      <span
        v-for="(ripple, index) in ripples"
        v-show="ripple.show === true"
        :key="`ripple-${index}`"
        class="base-button__ripple"
        :style="{ top: ripple.y + 'px', left: ripple.x + 'px' }"
        @animationend="animationFinished(index)"
      />
    </transition-group>
  </button>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon/Icon';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'BaseButton',
  components: {
    Icon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    rippleColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.2)',
    },
    alternative: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variables,
      ripples: [],
    };
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor']),
    colorProps() {
      return {
        '--rippleColor': this.rippleColor,
      };
    },
  },
  methods: {
    click(e) {
      this.$emit('click', e);
      const el = this.$refs.button;
      const pos = el.getBoundingClientRect();

      this.ripples.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true,
      });
    },
    animationFinished(i) {
      this.ripples[i].show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.base-button {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: $white;
  background: var(--primary-color);
  padding: 0 2rem;
  height: 6rem;
  width: 100%;
  border-radius: 0.4rem;
  user-select: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: opacity 0.3s, box-shadow 0.2s ease-out, padding 0.3s;
  @include media-min($md) {
    width: auto;
    min-width: 250px;
    &--loading {
      padding: 0 7.4rem 0 8.4rem;
    }
  }
  &:hover {
    opacity: 0.85;
  }
  &:disabled {
    background-color: $gray500;
    color: $black;
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:active {
    box-shadow: none;
  }
  &:focus {
    box-shadow: 0 0 0 8px var(--lighter-primary-color);
  }
  &--alternative {
    background: rgb(255,255,255,0.5);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  &__ripple {
    background-color: var(--rippleColor);
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-radius: 50%;
    transform: translateX(-100%) translateY(-100%);
    animation: rippleAnimation 1.2s ease-out forwards, fadeAnimation 1.5s ease-out forwards;
  }
  &__spinner {
    position: absolute;
    left: 4rem;
    margin-right: 1.5rem;
  }
  &__icon {
    animation: loaderAnimation 1.5s linear 0s infinite;
  }
}
</style>
