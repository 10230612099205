<template>
  <div class="label">
    <label>{{ label }}</label>
    <span
      v-if="required"
      class="primary-color"
    >
      *
    </span>
    <span v-if="tooltip">
      <Tooltip :tooltip="tooltip" />
    </span>
  </div>
</template>
<script>
import Tooltip from '@/components/Form/Tooltip';

export default {
  name: 'BaseInput',
  components: {
    Tooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $black;
  padding-bottom: 0.6rem;
  min-height: 28px;
}
.primary-color {
  color: var(--primary-color);
}
</style>
