<template>
  <div class="recorder">
    <video
      ref="videoRecorder"
      class="video-js vjs-default-skin"
      playsinline
    />
    <div
      v-if="isRecording"
      class="recorder__status"
    >
      <div class="recorder__recording-status" />
    </div>
    <RecorderOverlay
      :is-loading="isLoading"
      :error="error"
    />
    <Loader
      v-if="isLoading"
      :is-component-loader="true"
    />
    <RecorderControls
      :is-loading="isLoading"
      :is-recording="isRecording"
      :finished="finished"
      :error="error"
      :progress="progress"
      @start-recording="startRecording"
      @stop-recording="stopRecording"
      @reset-video="resetVideo"
    />
    <Progress
      v-if="progress"
      :percentage="progress"
    />
  </div>
</template>
<script>
import videojs from 'video.js';
// eslint-disable-next-line import/extensions
import 'videojs-record/dist/videojs.record.js';
import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';
import 'recordrtc';
import Loader from '@/components/Loader/Loader';
import Progress from '@/components/Video/Partials/Progress';
import RecorderControls from '@/components/Video/Partials/RecorderControls';
import RecorderOverlay from '@/components/Video/Partials/RecorderOverlay';

const DetectRTC = require('detectrtc');

function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    )
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

if (isIOS()) {
  const DefaultMediaRecorder = window.MediaRecorder;
  const kb = 8 * 1024;
  const preferredBitRatePerSecond = 100 * kb;
  window.MediaRecorder = class extends DefaultMediaRecorder {
    constructor(stream, options) {
      super(stream, {
        ...options,
        audioBitsPerSecond: preferredBitRatePerSecond,
        videoBitsPerSecond: preferredBitRatePerSecond,
      });
    }
  };
}

export default {
  name: 'Recorder',
  components: {
    Progress,
    Loader,
    RecorderControls,
    RecorderOverlay,
  },
  props: {
    timeLimit: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    player: null,
    isRecording: false,
    isLoading: false,
    finished: false,
    error: false,
    options: {
      controls: true,
      autoplay: false,
      fluid: true,
      loop: false,
      bigPlayButton: false,
      controlBar: {
        recordToggle: false,
        fullscreenToggle: false,
      },
    },
  }),
  mounted() {
    this.isLoading = true;
    const hasError = this.checkUserDeviceAndBrowser();
    if (hasError) {
      this.isLoading = false;
      this.error = true;

      return;
    }
    this.player = videojs(this.$refs.videoRecorder, {
      ...this.options,
      plugins: {
        record: {
          audio: true,
          video: true,
          debug: false,
          maxLength: this.timeLimit,
        },
      },
    });
    this.player.record().getDevice();

    this.player.on('deviceReady', () => {
      this.isLoading = false;
    });

    this.player.on('startRecord', () => {
      this.isRecording = this.player.record().isRecording();
    });

    this.player.on('finishRecord', () => {
      this.isRecording = this.player.record().isRecording();
      this.finished = true;
      this.$emit('finish-record', this.player.recordedData);
    });

    this.player.on('error', (element, error) => {
      this.error = error;
    });

    this.player.on('deviceError', () => {
      this.error = this.player.deviceErrorCode;
      this.isLoading = false;
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    checkUserDeviceAndBrowser() {
      const { userAgent } = window.navigator;
      if (
        (userAgent.includes('CriOS') && /iPad|iPhone|iPod/.test(userAgent))
        || !DetectRTC.isWebRTCSupported
      ) {
        return true;
      }

      return false;
    },
    startRecording() {
      this.player.record().start();
    },
    stopRecording() {
      this.player.record().stop();
    },
    resetVideo() {
      this.finished = false;
      this.$emit('remove-recorded-data');

      this.player.record().reset();
      this.player.record().getDevice();
    },
  },
};
</script>
<style lang="scss" scoped>
.recorder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.8rem;
  margin: 1rem 0 4rem;
  max-width: 600px;
  min-height: 420px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  @include media-min($md) {
    min-height: 420px;
  }

  &__status {
    position: absolute;
    bottom: 10.7rem;
    right: 13.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 1.5rem;
    margin-bottom: 0;
  }
  &__recording-status {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background: $red;
    box-shadow: 0 0 8px $red;
    animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  }
}

::v-deep .vjs-control-bar {
  background: rgba(0, 0, 0, 0.7) !important;
  padding: 0.7rem 1rem;
  height: auto;
}
::v-deep .video-js .vjs-time-control {
  font-size: 1.2em;
}

::v-deep .vjs-button > .vjs-icon-placeholder:before {
  font-size: 2.1em;
}

::v-deep .video-js .vjs-volume-bar {
  margin: 1.7em 0.45em;
}
::v-deep .video-js .vjs-current-time {
  margin-left: auto;
}
::v-deep .video-js .vjs-time-control {
  padding: 0 0.3em;
  min-width: auto;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
