<template>
  <div class="topbar">
    <div class="topbar__wrapper">
      <div class="topbar__name">
        <div class="topbar__logo">
          <BaseImage
            :src="logo"
            :name="$t('game.name')"
          />
        </div>
        <span class="topbar__type">
          {{ topBarTitle || $t("game.recruitment_game") }}
        </span>
      </div>
      <div
        v-if="hasSessionData && !isGameFinished && isGameStarted && badges.length"
        class="topbar__badges"
        @click="goToBadges"
      >
        <BadgeList :badges="badges" />
      </div>
      <div
        v-if="hasSessionData && !isGameFinished && isGameStarted"
        class="topbar__progressbar"
      >
        <ProgressBarDesktop :percentage="percentage" />
      </div>
      <div class="topbar__details--wrapper">
        <Countdown
          v-if="hasSessionData && hasGameTimeLimit && remainingTime && !isGameFinished && isGameStarted"
          :game-time-left="remainingTime"
          class="topbar__details"
        />
        <div
          v-if="!isFaqRoute && hasFrequentlyAskedQuestionsData"
          class="topbar__details topbar__icons"
        >
          <div
            class="topbar__icon"
            @click="goToFaq"
          >
            <Icon name="help" />
          </div>
        </div>
        <div
          v-if="!isFaqRoute && hasFrequentlyAskedQuestionsData || (hasSessionData && badges.length)"
          class="topbar__burger"
        >
          <Burger />
        </div>
      </div>
    </div>
    <ProgressBarMobile
      class="topbar__progressbar--mobile"
      :percentage="percentage"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ProgressBarDesktop from '@/components/TopBar/ProgressBar/ProgressBarDesktop';
import ProgressBarMobile from '@/components/TopBar/ProgressBar/ProgressBarMobile';
import BadgeList from '@/components/TopBar/Badge/BadgeList';
import Countdown from '@/components/TopBar/Countdown/Countdown';
import Burger from '@/components/MobileMenu/Burger';
import Icon from '@/components/Icon/Icon';
import BaseImage from '@/components/Image/BaseImage';
import { BADGES, FAQ } from '@/router/routes.names';

export default {
  name: 'TopBar',
  components: {
    ProgressBarDesktop,
    ProgressBarMobile,
    BadgeList,
    Countdown,
    Burger,
    Icon,
    BaseImage,
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('settings', ['logo', 'hasFrequentlyAskedQuestionsData', 'topBarTitle']),
    ...mapGetters('session', ['hasSessionData', 'hasGameTimeLimit', 'remainingTime', 'isGameStarted', 'isGameFinished']),
    ...mapGetters('flow', ['badges']),
    isBadgesRoute() {
      return this.$route.name === BADGES;
    },
    isFaqRoute() {
      return this.$route.name === FAQ;
    },
  },
  methods: {
    goToBadges() {
      if (this.isBadgesRoute) return;

      this.$router.push({ name: BADGES, params: this.$route.params });
    },
    goToFaq() {
      if (this.isFaqRoute) return;

      this.$router.push({ name: FAQ, params: this.$route.params });
    },
  },
};
</script>
<style lang="scss" scoped>
.topbar {
  width: 100%;
  height: 100px;
  z-index: 9999;
  position: fixed;
  @include media-min($lg) {
    height: 95px;
  }
  &__wrapper {
    display: flex;
    height: 84px;
    justify-content: space-between;
    align-items: center;
    background: $white;
    border: 1px solid $gray400;
    @include media-min($lg) {
      height: 95px;
    }
  }
  &__name {
    display: flex;
    align-items: center;
  }
  &__logo {
    max-height: 31px;
    height: auto;
    max-width: 100%;
    margin: 0 0 0 2.4rem;
    @include media-min($lg) {
      max-height: 37px;
      margin: 0 3.2rem 0 2.4rem;
    }
  }
  &__type {
    display: none;
    color: $black;
    font-size: 1.8rem;
    line-height: 2.8rem;
    padding-right: 6rem;
    text-transform: uppercase;
    @include media-min($xxxxl) {
      display: block;
    }
  }
  &__details {
    height: 5.6rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 0;
    @include media-min($lg) {
      padding: 0 1.6rem;
      border-left: 1px solid $gray200;
    }
    &--wrapper {
      display: flex;
    }
    img {
      padding: 0 1.5rem 0 0;
    }
  }
  &__badges {
    cursor: pointer;
  }
  &__progressbar {
    display: none;
    flex: 1;
    @include media-min($lg) {
      display: block;
    }
    &--mobile {
      display: block;
      @include media-min($lg) {
        display: none;
      }
    }
  }
  &__burger {
    height: 5.6rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-left: 1px solid $gray200;
    @include media-min($lg) {
      display: none;
    }
  }
  &__icons {
    padding: 0 1rem;
    display: none;
    @include media-min($lg) {
      display: block;
    }
  }
  &__icon {
    cursor: pointer;
    padding: 0 0.5rem;
    svg {
      width: 46px;
      height: 46px;
    }
  }
}
</style>
