import store from '@/store';
import GameLayout from '@/layouts/GameLayout';
import Game from '@/views/Game';
import Badges from '@/views/Badges';
import EndOfTime from '@/views/EndOfTime';
import {
  BADGES, END_OF_TIME, GAME, GAME_LAYOUT, NOT_FOUND,
} from '@/router/routes.names';
import { HTTP_NOT_FOUND, HTTP_UNAUTHORIZED } from '@/common/axios/codes';

export default {
  path: 'game',
  component: GameLayout,
  name: GAME_LAYOUT,
  beforeEnter: async (to, from, next) => {
    try {
      store.dispatch('layout/startLoading');
      await store.dispatch('session/fetchSessionGameData');
      if (to.name === GAME_LAYOUT) {
        await store.dispatch('flow/redirectToCurrentStage');
      }

      next();
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) {
        next();

        return;
      }
      if (e.response?.status === HTTP_NOT_FOUND) {
        next({ name: NOT_FOUND });

        return;
      }
      throw new Error(e);
    } finally {
      store.dispatch('layout/stopLoading');
    }
  },
  children: [
    {
      path: 'badges',
      name: BADGES,
      component: Badges,
      beforeEnter: async (to, from, next) => {
        const allStagesCompleted = store.getters['flow/areAllStagesCompleted'];
        const isGameFinished = store.getters['session/isGameFinished'];
        if (allStagesCompleted || isGameFinished) {
          await store.dispatch('flow/redirectToCurrentStage');
        }

        next();
      },
    },
    {
      path: 'time-out',
      name: END_OF_TIME,
      component: EndOfTime,
      beforeEnter: async (to, from, next) => {
        try {
          const { recruitmentProcessId, sessionId } = to.params;
          store.dispatch('layout/startLoading');
          // TODO refactor needed in the future
          // due to problems with the global loader, we decided to put the data fetching here
          // in the future should be transferred to the component
          await store.dispatch('settings/fetchPageTranslation', {
            pageType: END_OF_TIME,
            recruitmentProcessId,
            sessionId,
          });

          next();
        } catch (e) {
          if (e.response?.status === HTTP_UNAUTHORIZED) {
            next();

            return;
          }
          if (e.response?.status === HTTP_NOT_FOUND) {
            next({ name: NOT_FOUND });

            return;
          }
          throw new Error(e);
        } finally {
          store.dispatch('layout/stopLoading');
        }
      },
    },
    {
      path: 'stage/:stageNumber',
      name: GAME,
      component: Game,
      beforeEnter: async (to, from, next) => {
        const currentStageIndex = store.getters['flow/currentStageIndex'];
        const allStagesCompleted = store.getters['flow/areAllStagesCompleted'];
        const isGameStarted = store.getters['session/isGameStarted'];
        if (
          !isGameStarted
          || +to.params.stageNumber - 1 !== currentStageIndex
          || allStagesCompleted
        ) {
          await store.dispatch('flow/redirectToCurrentStage');
        }

        next();
      },
    },
  ],
};
