export default class GameQuestionSessionAnswer {
  /**
   * @param {Date} createAt
   * @param {?String} answer
   */
  constructor({ createAt, answer }) {
    this._createdAt = createAt;
    this._answer = answer;
  }

  /**
   * @return {Date}
   */
  get createdAt() {
    return this._createdAt;
  }

  /**
   * @return {String}
   */
  get answer() {
    return this._answer;
  }
}
