<template>
  <BaseLayout :side-image="sideImageOption">
    <template v-slot:main>
      <Content :has-footer="false">
        <div
          class="finish"
          v-html="sanitizeHtml(translatedPage)"
        />
      </Content>
    </template>
  </BaseLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import sanitize from '@/common/sanitize/sanitizeHtml';
import BaseLayout from '@/layouts/BaseLayout';
import Content from '@/components/Content/Content';
import { GAME } from '@/router/routes.names';
import { THANK_YOU } from '@/names/pages.names';

export default {
  name: 'Finish',
  components: {
    BaseLayout,
    Content,
  },
  computed: {
    ...mapGetters('settings', ['translation', 'sideImage']),
    translatedPage() {
      return this.translation(THANK_YOU).body;
    },
    sideImageOption() {
      return this.sideImage(THANK_YOU);
    },
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  methods: {
    ...sanitize,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === GAME) {
      next(false);

      return;
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
.finish {
  padding-bottom: 6rem;
}
::v-deep .score {
  u {
    font-weight: 500;
    color: var(--primary-color);
  }
}
::v-deep .feedback {
  font-weight: 500;
  color: var(--primary-color);
}
</style>
