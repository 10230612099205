<template>
  <BaseLayout :side-image="sideImageOption">
    <template #main>
      <Content>
        <div
          class="welcome"
          v-html="sanitizeHtml(translatedPage)"
        />
      </Content>
    </template>
    <template #footer>
      <div class="welcome__footer">
        <div class="welcome__wrapper">
          <BaseButton
            :is-loading="isLoading"
            @click="redirectToFirstUncompletedStage"
          >
            {{ translatedContinueButton }}
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import alerts from '@/common/alerts/alerts';
import sanitize from '@/common/sanitize/sanitizeHtml';
import BaseLayout from '@/layouts/BaseLayout';
import Content from '@/components/Content/Content';
import BaseButton from '@/components/Form/BaseButton';
import { HTTP_UNAUTHORIZED } from '@/common/axios/codes';
import { RECORD_TEST, WELCOME } from '@/names/pages.names';
import { CONTINUE_BTN } from '@/names/buttons.names';

export default {
  name: 'Welcome',
  components: {
    BaseLayout,
    Content,
    BaseButton,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['translation', 'sideImage']),
    ...mapGetters('flow', ['hasGameRecords']),
    translatedPage() {
      return this.translation(WELCOME).body;
    },
    translatedContinueButton() {
      return this.translation(WELCOME).additional_fields.find((button) => button.data.name === CONTINUE_BTN).data.value;
    },
    sideImageOption() {
      return this.sideImage(WELCOME);
    },
  },
  methods: {
    ...alerts,
    ...sanitize,
    ...mapActions('flow', ['redirectToCurrentStage']),
    ...mapActions('session', ['markGameAsStarted']),
    async redirectToFirstUncompletedStage() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        if (this.hasGameRecords) {
          this.$router.push({ name: RECORD_TEST, params: this.$route.params });

          return;
        }
        await this.markGameAsStarted();
        await this.redirectToCurrentStage();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          return;
        }
        this.warningAlert();
        throw new Error(JSON.stringify('meta' in (e.response?.data || {}) ? (({ meta, ...errors }) => errors)(e.response.data) : e.response?.data));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.welcome {
  padding-bottom: 3rem;
  &__wrapper {
    padding: 0 1.5rem;
    @include media-min($md) {
      padding: 0 2.4rem;
    }
    @include media-min($lg) {
      padding: 0 6rem 0 8rem;
    }
  }
  &__footer {
    display: flex;
    background: $white;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    @include media-min($md) {
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 2.4rem;
    }
    @include media-min($lg) {
      right: auto;
      width: 65%;
      height: 140px;
      padding-bottom: 6rem;
    }
  }
}
</style>
