<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="label"
    :rules="rules"
    mode="eager"
  >
    <Label
      :label="label"
      :tooltip="tooltip"
      :required="required"
    />
    <div
      class="base-input"
      :class="{ 'error': errors.length }"
    >
      <input
        :value="value"
        :name="label"
        :placeholder="placeholder"
        :type="type"
        @input="event => updateValue(event.target.value)"
      >
    </div>
    <ValidationError :errors="errors" />
  </ValidationProvider>
</template>
<script>
import Label from '@/components/Form/Partials/Label';
import ValidationError from '@/components/Form/Partials/ValidationError';

export default {
  name: 'BaseInput',
  components: {
    Label,
    ValidationError,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-input {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    border: 0;
    border: 1px solid $gray400;
    border-radius: 4px;
    padding: 2rem 1.6rem;
    transition: 0.5s border cubic-bezier(0.56, 0.33, 0.54, 0.95);
    &:focus {
      border: 1px solid var(--primary-color);
      outline: 0;
    }
  }
  &.error {
    input {
      border-color: $red;
    }
  }
}
</style>
