<template>
  <div
    class="player-status"
    :class="{ 'player-status--dark': !isPlaying }"
  >
    <div
      v-show="!isPlaying && !isStarted"
      class="state-icons state-icons__play"
    />
    <div
      v-show="isPlaying && isStarted"
      class="state-icons state-icons__play"
      :class="{ 'state-icons--animation': isStarted }"
    />
    <div
      v-show="!isPlaying && isStarted"
      class="state-icons state-icons__pause"
      :class="{ 'state-icons--animation': isStarted }"
    />
  </div>
</template>
<script>
export default {
  name: 'PlayerStatus',
  props: {
    isPlaying: {
      type: Boolean,
      required: true,
    },
    isStarted: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.player-status {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: all 0.5s ease;

  &--dark {
    background: rgba(0, 0, 0, 0.4);
  }
}

.state-icons {
  box-sizing: border-box;
  width: 40px;
  height: 50px;
  position: relative;
  opacity: 0.8;

  &__pause {
    &:before,
    &:after {
      display: block;
      border-radius: 20px;
      width: 36%;
      content: "";
      background-color: $white;
      height: 100%;
      position: absolute;
    }

    &:before {
      left: 0;
      top: 0;
    }

    &:after {
      right: 0;
      top: 0;
    }
  }

  &__play {
    &:before {
      display: block;
      content: "";
      height: 100%;
      position: absolute;
      width: 0;
      height: 0;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 40px solid $white;
    }
  }

  &--animation {
    animation: scaleAnimation 0.8s ease forwards;
  }
}
</style>
