import api from '@/common/axios/api';
import flowFactory from '@/domain/factories/flowFactory';
import {
  SET_SESSION,
  SET_FINISHED_DATE,
  SET_CURRENT_ROUTE,
} from '@/store/session/mutations.names';
import { SET_FLOW } from '@/store/flow/mutations.names';

export default {
  async fetchSessionGameData({ commit }) {
    const response = await api.get('session');
    const flow = flowFactory(response.data.data.stages.data);

    commit(`flow/${SET_FLOW}`, flow, { root: true });
    commit(SET_SESSION, response.data.data.session.data);
  },
  async fetchSession({ commit }, { sessionId }) {
    const response = await api.get(`session/${sessionId}`);
    const { data } = response.data;

    commit(SET_SESSION, data);
  },
  async setSessionLocale(state, { recruitmentProcessId, sessionId = null, locale }) {
    const endpoint = sessionId === null ? `locale/${recruitmentProcessId}` : `locale/${recruitmentProcessId}/${sessionId}`;
    const { data: { data } } = await api.post(endpoint, {
      locale,
    });

    return data;
  },
  async markGameAsStarted({ commit }) {
    const response = await api.post('session/start');

    commit(SET_SESSION, response.data.data);
  },
  async markGameAsFinished({ commit }) {
    await api.post('session/finish');

    commit(SET_FINISHED_DATE, new Date());
  },
  async markGameAsTimedOut({ commit }) {
    await api.post('session/end-of-time');

    commit(SET_FINISHED_DATE, new Date());
  },
  setCurrentRoute({ commit }, { currentRoute }) {
    commit(SET_CURRENT_ROUTE, currentRoute);
  },
};
