<template>
  <div class="modal-player">
    <div class="modal-player__wrapper">
      <Player
        :videos="badge.videos"
        @close-video="closeVideo"
      />
      <div class="player-gradient" />
    </div>
    <PlayerCloseButton @close-video="closeVideo" />
  </div>
</template>
<script>
import gsap from 'gsap';
import Player from '@/components/Video/Partials/Player';
import PlayerCloseButton from '@/components/Video/Partials/PlayerCloseButton';

export default {
  name: 'ModalPlayer',
  components: {
    Player,
    PlayerCloseButton,
  },
  props: {
    badge: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.mountAnimation();
  },
  methods: {
    mountAnimation() {
      gsap
        .timeline()
        .set('.modal-player', {
          duration: 0.1,
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          ease: 'expo.inOut',
        })
        .from(
          '.modal-player__wrapper',
          {
            autoAlpha: 0,
            scale: 0.6,
            duration: 0.5,
            ease: 'power3.out',
          },
          '0.1',
        );
    },
    closeVideo() {
      gsap
        .timeline()
        .to('.modal-player', {
          backdropFilter: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          autoAlpha: 0,
          duration: 0.1,
          ease: 'power3.out',
        })
        .to('.modal-player__wrapper', {
          autoAlpha: 0,
          scale: 0.8,
          duration: 0.2,
          ease: 'power3.out',
          onComplete: () => {
            this.$emit('close-video');
          },
        });
    },
  },
};
</script>
<style lang="scss" scoped>
video::-webkit-media-controls-timeline {
  display: none;
}
.modal-player {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: 0.5s all;

  &__wrapper {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    margin: 0 auto;
    overflow-y: visible;
    background: #000;
    height: 0;
    width: calc(100% - 12em);
    padding-top: calc((100% - 12em) * 0.5625);
    cursor: pointer;

    @media (min-aspect-ratio: 16/9) {
      width: 0;
      height: calc(100vh - 10em);
      height: calc(100svh - 10em);
      padding-top: 0;
      padding-left: calc((100vh - 10em) * 1.7778);
      padding-left: calc((100svh - 10em) * 1.7778);
    }

    @media (max-width: $md) {
      width: calc(100% - 1em);
      padding-top: calc((100% - 1em) * 0.5625);
    }

    ::v-deep video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.player-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 280px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}
</style>
