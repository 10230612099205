import GameQuestion from '@/domain/entities/flow/GameQuestion';
import BestWorstGameQuestionAnswer from '@/domain/entities/flow/BestWorstGameQuestionAnswer';
import { immerable, produce } from 'immer';

export default class BestWorstGameQuestion extends GameQuestion {
  [immerable] = true;

  /**
   * @param {String} id
   * @param {String} type
   * @param {Number} order
   * @param {String} body
   * @param {Array<BestWorstGameQuestionAnswer>} answers
   * @param {?Date} completedAt
   * @param {String} questionId
   * @param {?Badge} badge
   * @param {?String} instruction
   * @param {?String} imageUrl
   * @param {String} sideImageUrl
   * @param {String} header
   */
  constructor({
    id,
    type,
    order,
    body,
    answers,
    completedAt,
    questionId,
    badge,
    instruction,
    imageUrl,
    sideImageUrl,
    header,
  }) {
    super({
      id,
      type,
      order,
      body,
      completedAt,
      questionId,
      badge,
      instruction,
      imageUrl,
      sideImageUrl,
      header,
    });

    this._answers = answers;
  }

  /**
   * @return {BestWorstGameQuestion}
   */
  markBadgeAsCompleted() {
    const chosenBestAnswer = this.chosenBestAnswer();

    return produce(this, (draft) => {
      if (chosenBestAnswer.badge) {
        const answerIndex = this._answers.findIndex(
          (findAnswer) => findAnswer.id === chosenBestAnswer.id,
        );

        // eslint-disable-next-line no-param-reassign
        draft._answers[answerIndex] = draft._answers[answerIndex].markBadgeAsCompleted();

        return;
      }

      // eslint-disable-next-line no-param-reassign
      draft._badge = draft._badge?.markAsCompleted();
    });
  }

  /**
   * @return {BestWorstGameQuestionAnswer}
   */
  chosenBestAnswer() {
    return this._answers.find((answer) => answer.isChosenAsBestAnswer);
  }

  /**
   * @return {BestWorstGameQuestionAnswer}
   */
  chosenWorstAnswer() {
    return this._answers.find((answer) => answer.isChosenAsWorstAnswer);
  }

  /**
   * @return {boolean}
   */
  hasChosenAnswer() {
    return (
      this._answers.some((answer) => answer.isChosenAsBestAnswer)
      && this._answers.some((answer) => answer.isChosenAsWorstAnswer)
    );
  }

  /**
   * @param {BestWorstGameQuestionAnswer} answer
   * @return {BestWorstGameQuestion}
   */
  chooseBestAnswer(answer) {
    const answerIndex = this._answers.findIndex((findAnswer) => findAnswer.id === answer.id);

    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._answers[answerIndex] = draft._answers[answerIndex].unChoose();
      // eslint-disable-next-line no-param-reassign
      draft._answers = draft._answers.map((mapAnswer, index) => (answerIndex === index
        ? mapAnswer.chooseBestAnswer()
        : mapAnswer.unChooseBestAnswer()
      ));
    });
  }

  /**
   * @param {BestWorstGameQuestionAnswer} answer
   * @return {BestWorstGameQuestion}
   */
  chooseWorstAnswer(answer) {
    const answerIndex = this._answers.findIndex((findAnswer) => findAnswer.id === answer.id);

    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._answers[answerIndex] = draft._answers[answerIndex].unChoose();
      // eslint-disable-next-line no-param-reassign
      draft._answers = draft._answers.map((mapAnswer, index) => (answerIndex === index
        ? mapAnswer.chooseWorstAnswer()
        : mapAnswer.unChooseWorstAnswer()
      ));
    });
  }

  /**
   * @return {({answer: string, answer_id: String}|{answer: string, answer_id: String})[]}
   */
  chosenAnswersRequestData() {
    const chosenAsBestAnswer = {
      answer_id: this.chosenBestAnswer().id,
      answer: BestWorstGameQuestionAnswer.BEST_TYPE_NAME,
    };
    const chosenAsWorstAnswer = {
      answer_id: this.chosenWorstAnswer().id,
      answer: BestWorstGameQuestionAnswer.WORST_TYPE_NAME,
    };

    return [chosenAsBestAnswer, chosenAsWorstAnswer];
  }

  /**
   * @param sessionAnswers{Array<GameQuestionSessionAnswer>}
   * @return {BestWorstGameQuestion}
   */
  updateSessionAnswers(sessionAnswers) {
    const sessionBestAnswer = sessionAnswers.find(
      (findSessionAnswer) => findSessionAnswer.answer === BestWorstGameQuestionAnswer.BEST_TYPE_NAME,
    );
    const sessionWorstAnswer = sessionAnswers.find(
      (findSessionAnswer) => findSessionAnswer.answer === BestWorstGameQuestionAnswer.WORST_TYPE_NAME,
    );

    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._answers = draft._answers.map(
        // eslint-disable-next-line no-nested-ternary
        (mapAnswer) => (mapAnswer.id === this.chosenBestAnswer().id
          ? mapAnswer.updateSessionAnswer(sessionBestAnswer)
          : mapAnswer.id === this.chosenWorstAnswer().id
            ? mapAnswer.updateSessionAnswer(sessionWorstAnswer)
            : mapAnswer),
      );
    });
  }

  /**
   * @return {?Badge}
   */
  possibleBadge() {
    return this._badge || this._answers.find((answer) => answer.badge)?.badge;
  }

  /**
   * @return {Array<BestWorstGameQuestionAnswer>}
   */
  get answers() {
    return this._answers;
  }
}
