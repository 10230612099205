export default {
  checkbox_required: 'Selectați %{name}',
  required: 'Introduceți %{name}',
  phone_incorrect: 'Introduceți un număr de telefon valid',
  min_length: 'Numărul minim de caractere este: %{length}',
  max_length: 'Numărul maxim de caractere este: %{length}',
  max_file_size: 'Dimensiunea maximă a fișierului este %{size} mb',
  recaptcha: 'Selectați Recaptcha',
  email_incorrect: 'Format incorect',
  max_value: 'Valoarea maximă trebuie să fie %{value} sau mai mică',
  min_value: 'Valoarea minimă trebuie să fie %{value} sau mai mare',
  numeric: 'Format incorect al numărului',
  numeric_required: '%{name} este obligatoriu și poate conține doar numere întregi',
};
