import * as names from '@/store/layout/mutations.names';

export default {
  [names.START_LOADING](state) {
    state.isLoading = true;
  },
  [names.STOP_LOADING](state) {
    state.isLoading = false;
  },
  [names.SET_PROGRESS](state, value) {
    state.progress = value;
  },
  [names.RESET_PROGRESS](state) {
    state.progress = 0;
  },
};
