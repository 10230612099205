<template>
  <div class="badge-list">
    <div class="badge-list__desktop">
      <span>{{ $t("general.badges.title") }}:</span>
      <BadgeItem
        v-for="(badge, index) in badges"
        :key="`badge-${index}`"
        :badge="badge"
      />
    </div>
    <div class="badge-list__mobile">
      <span>
        {{ $t("general.badges.title") }}
      </span>
      <a
        href="/"
        class="badge-list__link"
      >
        <Icon name="arrow-right" />
      </a>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/Icon/Icon';
import BadgeItem from '@/components/TopBar/Badge/BadgeItem';

export default {
  name: 'BadgeList',
  components: {
    Icon,
    BadgeItem,
  },
  props: {
    badges: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.badge-list {
  display: none;
  height: 5.6rem;
  padding: 0 1.6rem;
  border-left: 1px solid $gray200;
  @include media-min($lg) {
    display: block;
  }
  &__desktop {
    display: flex;
    align-items: center;
    height: 100%;
    // TODO: turn on when we make the screen of all badges
    // @include media-min($xl) {
    //   display: flex;
    // }
    a {
      span {
        font-weight: 500;
        padding-right: 0.5rem;
      }
      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--primary-color);
      }
    }
  }
  &__mobile {
    display: none;
    align-items: center;
    height: 100%;
    // TODO: turn on when we make the screen of all badges
    // @include media-min($xl) {
    //   display: none;
    // }
    span {
      color: var(--primary-color);
      font-weight: 500;
      padding-right: 0.5rem;
    }
  }
  &__link {
    background-color: var(--lighter-primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    border-radius: 4px;
    margin-left: 1rem;
  }
}
</style>
