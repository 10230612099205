export default {
  name: '313c',
  recruitment_game: 'Gra Rekrutacyjna',
  back: 'Wróć',
  cancel: 'Anuluj',
  continue: 'Kontynuuj',
  skip: 'Pomiń',
  start: 'Start',
  close: 'Zamknij',
  help: 'Pomoc',
  time_left: 'Pozostały czas',
  progress: 'Postęp',
  privacy_policy: 'Polityka Prywatności',
  cookies_policy: 'Polityka cookies i Warunki',
  faq: 'Pytania i odpowiedzi',
  messages: 'Wiadomości',
  unread: 'nieprzeczytana',
  start_recording: 'Rozpocznij nagrywanie',
  stop_recording: 'Zatrzymaj nagrywanie',
  remove_record: 'Usuń nagranie',
};
