export default {
  name: '313C',
  recruitment_game: 'Test de recrutement',
  back: 'Retour',
  cancel: 'Annuler',
  continue: 'Continuer',
  skip: 'Ignorer',
  start: 'Démarrer',
  close: 'Fermez',
  help: 'Aide',
  time_left: 'Temps restant',
  progress: 'Progrès',
  privacy_policy: 'Politique de confidentialité ',
  cookies_policy: 'Politique de cookies et Conditions',
  faq: 'Questions et réponses ',
  messages: 'Messages',
  unread: 'courrier non lu',
  start_recording: "Démarrer l'enregistrement",
  stop_recording: "Arrêter l'enregistrement",
  remove_record: "Supprimer l'enregistrement",
};
