<template>
  <div class="not-found">
    <div class="not-found__topbar">
      <div>
        <Logo class="not-found__topbar--logo" />
      </div>
    </div>
    <div class="not-found__content">
      <h2 v-if="!isHebeMTClient">
        {{ $t("general.not_found.code") }}
      </h2>
      <span>{{ isHebeMTClient ? $t("general.not_found.title_hebe") : $t("general.not_found.title") }}</span>
      <span v-html="isHebeMTClient ? $t('general.not_found.content_hebe') : $t('general.not_found.content')" />
    </div>
  </div>
</template>
<script>
import sanitize from '@/common/sanitize/sanitizeHtml';
import Logo from '@/assets/images/logo.svg';

export default {
  name: 'NotFound',
  components: {
    Logo,
  },
  computed: {
    isHebeMTClient() {
      return window.location.href.includes('hebemt');
    },
  },
  methods: {
    ...sanitize,
  },
};
</script>
<style lang="scss" scoped>
.not-found {
  position: relative;
  min-height: 100vh;
  min-height: 100svh;
  &__topbar {
    display: flex;
    align-items: center;
    height: 84px;
    width: 100%;
    border: 1px solid $gray400;
    position: relative;
    @include media-min($lg) {
      height: 95px;
    }
    &--logo {
      max-width: 121px;
      margin: 0 0 0 2.4rem;
      @include media-min($lg) {
        max-width: 143px;
        margin: 0 3.2rem 0 2.4rem;
      }
    }
  }
  &__content {
    padding: 60px 20px 60px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-min($lg) {
      padding: 100px 0 80px;
    }
    h2 {
      font-size: 10rem;
      line-height: 12rem;
      margin: 0;
      font-weight: 700;
      @include media-min($lg) {
        font-size: 16rem;
        line-height: 22rem;
      }
    }
    span {
      &:nth-of-type(1) {
        padding: 2rem 0;
        text-transform: uppercase;
        font-size: 2.2rem;
        line-height: 2.6rem;
        @include media-min($lg) {
          font-size: 3.4rem;
          line-height: 4rem;
        }
      }
      &:nth-of-type(2) {
        font-size: 1.6rem;
        line-height: 2rem;
        @include media-min($lg) {
          font-size: 2rem;
          line-height: 2.8rem;
        }
      }
    }
  }
}
</style>
