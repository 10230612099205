export default {
  choose: 'Choisissez',
  badges: {
    title: 'Insignes',
    badge: 'Insigne',
    my_badges: 'Mes Insignes',
    received: 'Vous recevez un insigne',
    continue: 'Jouez encore',
    show_badges: 'Voir les insignes',
    show_badge: 'Voir la récompense',
  },
  record_test: {
    intro: 'TEST DES RÉGLAGES DE LA CAMÉRA ET DU MICROPHONE',
    content: "Avant de commencer l'outil, testez vos réglages. Dans le champ ci-dessous, vous devriez voir l'image de votre caméra. Si vous la voyez, cela signifie que vos réglages sont probablement corrects - pour en être sûr, enregistrez et lisez une vidéo. Si vous ne voyez pas l'image de la caméra, vous ne pouvez pas lire l'enregistrement, ou si vous avez d'autres doutes, cliquez sur le bouton « DÉPANNAGE ».",
    troubleshoot: 'DÉPANNAGE',
  },
  questions: {
    progress: 'Question',
    accept: 'Approuver',
    question_has_already_been_answered: 'Cette question a déjà été répondue.',
  },
  not_found: {
    code: '404',
    title: 'La page n\'existe pas',
    content: 'La page que vous recherchez n\'a pas été trouvée. Il se peut qu\'elle ait été supprimée, son nom modifié ou qu\'elle n\'ait jamais existé.',
  },
};
