export default {
  warning: {
    title: 'Došla k chybe',
    content: 'Skúste to znovu a ak sa chyba bude opakovať, dajte nám vedieť na',
    content_without_email: 'Skúste to znovu a ak sa chyba bude opakovať, dajte nám vedieť na našu technickú podporu',
    confirm: 'Zavrieť',
  },
  end_time: {
    title: 'Nahlásiť chybu',
    content: 'Ak sa počas hrania objaví chyba, kontaktujte nás prosím e-mailom na adresu:',
  },
  record_video_error: {
    title: 'Žiadny prístup k fotoaparátu a/alebo mikrofónu.',
    content: 'Pre dokončenie tohto úlohy zmeňte svoje nastavenia a dovoľte aplikácii prístup k fotoaparátu a/alebo mikrofónu.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nepodporované zariadenie</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Pre pokračovanie, prosím, použite <strong>iné zariadenie a/alebo iný prehliadač.</strong></p>
        <p>Nástroj na nábor, ktorý sa chystáte použiť, obsahuje aspoň jednu úlohu, pri ktorej budete musieťnahrávať svoju odpoveď vo formáte videa. Bohužiaľ, nie všetky zariadenia a/alebo prehliadačeumožňujú tento krok vykonať správne.</p>
        <p><strong>Ak vidíte túto obrazovku, znamená to, že vaše zariadenie a/alebo prehliadač nedokážu zaručiťsprávne nahratie a odoslanie video súboru.</strong> Nižšie nájdete odporúčané nastavenia.</p>
        <br>
        <p><strong>Stolné zariadenia:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobilné zariadenia:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Ste si istý, že chcete úlohu preskočiť?',
    content: '<p>Nahrávanie videoodpovede na túto úlohu nie je povinné, ale odporúča sa - pomôže nám lepšie vás spoznať. Ste si istí, že chcete preskočiť nahrávanie videoodpovede?</p>',
  },
  skip_stage_with_record: {
    title: 'Ste si istý, že chcete úlohu preskočiť?',
    content: '<p>Máte v úmysle preskočiť uloženie nahraného videoodpovede - nebude dostupné osobe vykonávajúcej výberový proces. Nahranie videoodpovede pre túto úlohu nie je povinné, ale odporúča sa - pomôže nám lepšie vás spoznať. Ste si istí, že chcete preskočiť uloženie nahraného videoodpovede?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Riešenie problémov s kamerou a mikrofónom</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Ak nahrávanie alebo prehrávanie nie je správne, môžete nasledovať niekoľko krokov, ktoré by mali problém vyriešiť. Najčastejšie príčiny problémov vyplývajú z nastavení zariadenia, vrátane iných programov alebo aplikácií, ktoré súčasne používajú kameru a/alebo mikrofón.</p>
      <p>Možné riešenia problému:</p>
      <ol>
        <li>Uistite sa, že prehliadač má povolenia na používanie mikrofónu a kamery. Ak nie, zmeňte nastavenia a obnovte stránku.</li>
        <li>Uistite sa, že žiadna iná aplikácia alebo program práve nepoužíva kameru/mikrofón (napr. MS Teams, Photo Booth alebo FaceTime) - zatvorte aplikácie, ktoré blokujú možnosť použitia kamery prehliadačom a obnovte stránku.</li>
        <li>Skontrolujte, či je váš prehliadač aktualizovaný na najnovšiu verziu - ak nie, vykonajte aktualizáciu a test znova.</li>
        <li>Ak predchádzajúce kroky nepomohli, skúste použiť iný prehliadač alebo zariadenie.</li>
      </ol>
      <p>Ak problém pretrváva, dajte nám vedieť na <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> posielaním informácií o zariadení a prehliadači, na ktorých sa problém vyskytuje.</p>
      </div>
      <hr>
    `,
  },
};
