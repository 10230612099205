<template>
  <FadeIn>
    <div
      class="full-page-loader"
      :class="{
        'full-page-loader--component': isComponentLoader
      }"
    >
      <div
        class="full-page-loader__spinner"
      >
        <svg
          width="84"
          height="84"
          viewBox="0 0 90 90"
          fill="none"
        >
          <path
            d="M45 3.33329C36.7591 3.33329 28.7032 5.777 21.8512 10.3554C14.9992
            14.9338 9.65863 21.4412 6.50498 29.0548C3.35133 36.6684 2.52619 45.0462
            4.1339 53.1287C5.74162 61.2113 9.70998 68.6356 15.5372 74.4627C21.3644
            80.2899 28.7886 84.2583 36.8712 85.866C44.9537 87.4737 53.3315 86.6486
            60.9451 83.4949C68.5587 80.3413 75.0661 75.0008 79.6445 68.1487C84.2229
            61.2967 86.6666 53.2409 86.6666 45"
            :stroke="convertColorToRgb(interfaceColor)"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </FadeIn>
</template>
<script>
import { mapGetters } from 'vuex';
import colors from '@/common/colors/colors';
import FadeIn from '@/components/Transition/FadeInTransition';

export default {
  name: 'Loader',
  components: {
    FadeIn,
  },
  props: {
    isComponentLoader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor']),
  },
  methods: {
    ...colors,
  },
};
</script>
<style lang="scss" scoped>
.full-page-loader {
  position: fixed;
  z-index: 9998;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &--component {
    position: absolute;
    @include blur-background();
  }
  &__spinner {
    height: 84px;
    width: 84px;
    animation: loaderAnimation 1s linear infinite;
  }
}
</style>
