<template>
  <div class="progress">
    <div class="progress__bar">
      <div
        class="progress__fill"
        :style="{ width: `${percentage}%` }"
      />
    </div>
    <div class="progress__percent">
      <span> {{ percent }}% </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Progress',
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percent() {
      return this.percentage.toFixed();
    },
  },
};
</script>
<style lang="scss" scoped>
.progress {
  padding: 2rem 0 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  &__bar {
    display: block;
    position: relative;
    width: 100%;
    height: 16px;
    border-radius: 7px;
    overflow: hidden;
    background: $gray400;
  }
  &__fill {
    background-color: var(--primary-color);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition: 0.3s width;
  }
  &__percent {
    padding: 0 0 0 1.6rem;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid $gray200;
    span {
      display: block;
      background: $gray400;
      padding: 0.2rem 1rem;
      border-radius: 4px;
    }
  }
}
</style>
