<template>
  <ValidationProvider
    v-slot="{ errors, validate }"
    :vid="vid"
    :name="label"
    :rules="rules"
    mode="eager"
  >
    <Label
      :label="label"
      :tooltip="tooltip"
      :required="required"
    />
    <div
      class="file-input"
      :class="{ 'error': errors.length }"
    >
      <input
        type="file"
        @change="uploadFile($event, validate)"
      >
      <div class="file-input__wrapper">
        <span class="button">{{ $t('general.choose') }}</span>
        <span class="label">{{ inputValue ? inputValue.name : placeholder }}</span>
      </div>
    </div>
    <ValidationError :errors="errors" />
  </ValidationProvider>
</template>
<script>
import Label from '@/components/Form/Partials/Label';
import ValidationError from '@/components/Form/Partials/ValidationError';

export default {
  name: 'BaseFileUpload',
  components: {
    Label,
    ValidationError,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, File, null],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    inputValue(value) {
      this.$emit('input', value);
    },
  },
  methods: {
    async uploadFile(event, validate) {
      this.inputValue = '';
      const file = event.target.files[0];
      const result = await validate(event);
      if (!result.valid) {
        return;
      }

      if (file) {
        this.inputValue = file;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.primary-color {
  color: var(--primary-color);
}
.file-input {
  position: relative;
  width: 100%;
  border: 0;
  border: 1px solid $gray400;
  border-radius: 4px;
  padding: 1.2rem 1.6rem;
  [type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button {
    display: inline-block;
    background: $gray400;
    padding: 8px 16px;
    border-radius: 2px;
    margin-right: 8px;
    cursor: pointer;
    transition: 0.5s border cubic-bezier(0.56, 0.33, 0.54, 0.95);
  }
  .label {
    word-break: break-all;
  }
  &.error {
    border-color: $red;
  }
}
</style>
