import GameQuestionAnswer from '@/domain/entities/flow/GameQuestionAnswer';
import { immerable, produce } from 'immer';

export default class ClosedGameQuestionAnswer extends GameQuestionAnswer {
  [immerable] = true

  /**
   * @param {String} id
   * @param {?String} body
   * @param {?Feedback} feedback
   * @param {?Badge} badge
   * @param {?GameQuestionSessionAnswer} sessionAnswer
   * @param {Boolean} choosen
   */
  constructor({
    id, body, feedback, badge, sessionAnswer, choosen,
  }) {
    super({
      id, body, feedback, badge, sessionAnswer,
    });

    this._choosen = choosen;
  }

  /**
   * @return {ClosedGameQuestionAnswer}
   */
  choose() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._choosen = true;
    });
  }

  /**
   * @return {ClosedGameQuestionAnswer}
   */
  unChoose() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._choosen = false;
    });
  }

  /**
   * @param {GameQuestionSessionAnswer} sessionAnswer
   * @return {BestWorstGameQuestionAnswer}
   */
  updateSessionAnswer(sessionAnswer) {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._sessionAnswer = sessionAnswer;
    });
  }

  /**
   * @return {boolean}
   */
  get isChosen() {
    return this._choosen;
  }

  /**
   * @return {?GameQuestionSessionAnswer}
   */
  get sessionAnswer() {
    return this._sessionAnswer;
  }

  /**
   * @return {?Badge}
   */
  get badge() {
    return this._badge;
  }
}
