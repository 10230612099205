<template>
  <Document
    :title="$t('game.privacy_policy')"
    :content="sanitizeHtml(translatedPage)"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import sanitize from '@/common/sanitize/sanitizeHtml';
import Document from '@/components/Document/Document';
import { PRIVACY_POLICY } from '@/names/pages.names';

export default {
  name: 'PrivacyPolicy',
  components: {
    Document,
  },
  computed: {
    ...mapGetters('settings', ['translation']),
    translatedPage() {
      return this.translation(PRIVACY_POLICY).body;
    },
  },
  methods: {
    ...sanitize,
  },
};
</script>
