import {
  START_LOADING,
  STOP_LOADING,
  SET_PROGRESS,
  RESET_PROGRESS,
} from '@/store/layout/mutations.names';

export default {
  async startLoading({ commit }) {
    commit(START_LOADING);
  },
  async stopLoading({ commit }) {
    commit(STOP_LOADING);
  },
  async setProgress({ commit }, value) {
    commit(SET_PROGRESS, value);

    return value;
  },
  async resetProgress({ commit }) {
    commit(RESET_PROGRESS);
  },
};
