<template>
  <div>
    <ScrollButton>
      <div class="document">
        <div class="document__wrapper">
          <div class="document__nav">
            <BackButton class="document__nav--btn" />
            <span class="document__header--desktop">
              {{ title }}
            </span>
            <span />
          </div>
          <div>
            <span class="document__header--mobile">
              {{ title }}
            </span>
            <span v-html="content" />
          </div>
        </div>
      </div>
    </ScrollButton>
  </div>
</template>
<script>
import ScrollButton from '@/components/Content/ScrollButton';
import BackButton from '@/components/Content/BackButton';

export default {
  name: 'Document',
  components: {
    ScrollButton,
    BackButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.document {
  position: relative;
  height: calc(100vh - 100px);
  height: calc(100svh - 100px);
  padding: 0;
  overflow-y: auto;
  @include media-min($lg) {
    height: calc(100vh - 95px);
    height: calc(100svh - 95px);
    padding: 2rem 5rem 10rem;
  }
  &__wrapper {
    max-width: 1180px;
    max-height: none;
    padding: 1.5rem;
    margin: 0 auto;
    @include media-min($lg) {
      padding: 0 2rem;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 1rem 0 2rem;
    border-bottom: 1px solid $gray400;
    @include media-min($lg) {
      padding: 2rem 0;
      justify-content: center;
      margin-bottom: 4rem;
    }
    &--btn {
      @include media-min($lg) {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__header {
    &--mobile {
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 2rem;
      margin-top: 2.5rem;
      color: var(--primary-color);
      @include media-min($lg) {
        display: none;
      }
    }
    &--desktop {
      display: none;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 2rem;
      padding: 1rem 0;
      color: var(--primary-color);
      @include media-min($lg) {
        display: block;
      }
    }
  }
}
</style>
