<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="label"
    :rules="required ? 'checkbox_required' : null"
    mode="eager"
  >
    <label
      class="base-checkbox"
      :class="{ 'error': errors.length }"
    >
      <input
        v-model="inputValue"
        type="checkbox"
        :name="label"
        :value="value"
      >
      <div
        class="svg-container"
        :class="{ 'checked-svg': inputValue === true }"
      >
        <svg
          width="22"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
        >
          <path
            d="M20 2L8 14L2 8"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="checkbox-text">
        <span
          v-if="required"
          class="primary-color checkbox-dot"
        >
          *
        </span>
        <div v-html="sanitizeHtml(description)" />
      </div>
    </label>
    <ValidationError :errors="errors" />
  </ValidationProvider>
</template>
<script>
import sanitize from '@/common/sanitize/sanitizeHtml';
import ValidationError from '@/components/Form/Partials/ValidationError';

export default {
  name: 'BaseCheckbox',
  components: {
    ValidationError,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {
      inputValue: false,
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    inputValue(value) {
      this.$emit('input', value);
    },
  },
  methods: {
    ...sanitize,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .checkbox-text p {
  margin: 0;
}

.base-checkbox {
  display: flex;
  position: relative;
  align-items: flex-start;
  cursor: pointer;
  &.error {
    span {
      color: red;
    }
  }
  .checkbox-text {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    transition: 0.3s color;
  }
  .checkbox-dot {
    position: absolute;
    left: -8px;
    top: 0;
  }
  .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color);
    background: $white;
    margin-right: 1.5rem;
    padding: 0.1rem;
    border-radius: 4px;
    transition: 0.3s border;
    &.checked-svg {
      background: var(--primary-color);
      svg path {
        stroke-dashoffset: 0;
        stroke: $white;
      }
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
  }

  svg path {
    transition: stroke-dashoffset 0.4s linear;
  }

  input[type="checkbox"] + div svg path {
    stroke: transparent;
    transition: 0.3s all;
  }

  input[type="checkbox"]:checked label {
    text-decoration: line-through;
  }
}

.primary-color {
  color: var(--primary-color);
}
</style>
