import alerts from '@/trans/de/alerts';
import cookies from '@/trans/de/cookies';
import general from '@/trans/de/general';
import game from '@/trans/de/game';
import validation from '@/trans/de/validation';
import auth from '@/trans/de/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
