import alerts from '@/trans/pl/alerts';
import cookies from '@/trans/pl/cookies';
import general from '@/trans/pl/general';
import game from '@/trans/pl/game';
import validation from '@/trans/pl/validation';
import auth from '@/trans/pl/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
