import sanitizeHtml from 'sanitize-html';

const defaultTags = ['h2', 'h3', 'h4', 'p', 'strong', 'i', 'u', 's', 'a', 'ul', 'ol', 'li', 'br'];
const allowedClasses = { p: ['score', 'feedback'] };

export default {
  /**
   * @param {String} html
   * @param {Array} allowedTags
   */
  sanitizeHtml(html, allowedTags = defaultTags) {
    return sanitizeHtml(html, {
      allowedTags,
      allowedClasses,
    });
  },
};
