export default {
  warning: {
    title: 'Wystąpił błąd',
    content: 'Spróbuj ponownie, a jeśli błąd będzie się powtarzał daj nam znać na',
    content_without_email:
      'Spróbuj ponownie, a jeśli błąd będzie się powtarzał skontaktuj się z obsługą techniczną.',
    confirm: 'Zamknij',
  },
  end_time: {
    title: 'Zgłoś błąd',
    content: 'Jeżeli podczas Twojej gry wystąpił błąd prosimy o kontakt pod adresem email:',
  },
  record_video_error: {
    title: 'Brak dostępu do kamery i/lub mikrofonu.',
    content: 'Aby ukończyć to zadanie zmień ustawienia i zezwól aplikacji na korzystanie z kamery i/lub mikrofonu.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nieobsługiwane urządzenie</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Aby kontynuować prosimy o skorzystanie z <strong>innego urządzenia i/lub przeglądarki.</strong></p>
        <p>Narzędzie rekrutacyjne, z którego zamierzasz skorzystać zawiera przynajmniej jedno zadanie wymagające nagranie przez Ciebie odpowiedzi w formie video. Niestety nie wszystkie urządzenia i/lub przeglądarki umożliwiają realizację tego w prawidłowy sposób.</p>
        <p><strong>Jeśli widzisz ten ekran, oznacza to, że Twoje urządzenie i/lub przeglądarka nie gwarantuje poprawnego nagrania i przesłania pliku video.</strong> Poniżej znajdziesz rekomendowane ustawienia.</p>
        <br>
        <p><strong>Urządzenia stacjonarne:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Urządzenia mobilne:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Czy na pewno chcesz pominąć zadanie?',
    content: '<p>Nagranie odpowiedzi video w tym zadaniu nie jest obowiązkowe, ale zalecane - pozwoli nam lepiej Cię poznać. Czy na pewno chcesz pominąć nagranie odpowiedzi video?</p>',
  },
  skip_stage_with_record: {
    title: 'Czy na pewno chcesz pominąć zadanie?',
    content: '<p>Zamierzasz pominąć zapisanie nagranej odpowiedzi video – nie będzie ona dostępna dla osoby prowadzącej proces rekrutacyjny. Przesłanie odpowiedzi video w tym zadaniu nie jest obowiązkowe, ale zalecane - pozwoli nam lepiej Cię poznać. Czy na pewno chcesz pominąć zapisanie nagranej odpowiedzi video?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Rozwiązywanie problemów z kamerą i mikrofonem</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Jeśli nagranie lub odtworzenie video jest nieprawidłowe, możesz skorzystać z kilku kroków, które powinny rozwiązać problem. Najczęściej przyczyny problemów wynikają z ustawień urządzenia, w tym inne równocześnie działające programy lub aplikacje korzystające z kamery i/lub mikrofonu.</p>
      <p>Możliwe rozwiązania problemu:</p>
      <ol>
        <li>Upewnij się, że przeglądarka posiada uprawnienia do korzystania z mikrofonu i kamery. Jeśli nie, zmień ustawienia i odśwież stronę.</li>
        <li>Upewnij się, że żadna inna aplikacja lub program nie wykorzystują obecnie kamery/mikrofonu (np. MS Teams, Photo Booth czy FaceTime) - wyłącz aplikacje, które blokują możliwość wykorzystania kamery przez przeglądarkę i odśwież stronę.</li>
        <li>Sprawdź czy Twoja przeglądarka jest zaktualizowana do najnowszej wersji - jeśli nie, przeprowadź aktualizację i uruchom test ponownie.</li>
        <li>Jeśli powyższe nie pomogły spróbuj skorzystać z innej przeglądarki lub urządzenia.</li>
      </ol>
      <p>Jeśli problem nadal występuje, daj nam znać na <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> przesyłając informację o urządzeniu i przeglądarce, na których pojawia się problem.</p>
      </div>
      <hr>
    `,
  },
};
