export default {
  checkbox_required: 'Označte %{name}',
  required: 'Vložte %{name}',
  phone_incorrect: 'Vložte platné telefónne číslo',
  min_length: 'Minimálny počet znakov je: %{length}',
  max_length: 'Maximálny počet znakov je: %{length}',
  max_file_size: 'Maximálna veľkosť súboru je %{size} mb',
  recaptcha: 'Zaškrtnite Recaptcha',
  email_incorrect: 'Nesprávny formát',
  max_value: 'Maximálna hodnota musí byť %{value} alebo menej',
  min_value: 'Minimálna hodnota musí byť %{value} alebo viac',
  numeric: 'Nesprávny formát čísla',
  numeric_required: '%{name} je povinné a môže obsahovať iba celé čísla',
};
