<template>
  <div class="content__shadow">
    <div class="content">
      <div
        class="content__wrapper"
        :class="{ 'content__wrapper--full-height': !hasFooter }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Content',
  props: {
    hasFooter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss">
.content {
  overflow-y: auto;
  &__shadow {
    position: relative;
    &::before,
    &::after {
      display: block;
      height: 50px;
      left: 0;
      right: 25px;
      position: absolute;
    }
    &::before {
      content: "";
      box-shadow: 0px 50px 20px -20px $white inset;
      top: 0;
    }
    &::after {
      content: "";
      box-shadow: 0px -50px 20px -20px $white inset;
      bottom: 0;
    }
  }
  &__wrapper {
    height: calc(100vh - 80px - 82px);
    height: calc(100svh - 80px - 82px);
    display: block;
    max-width: 880px;
    padding: 2rem 2rem 4rem 2rem;
    @include media-min($lg) {
      height: calc(100vh - 140px - 140px);
      height: calc(100svh - 140px - 140px);
    }
    &--full-height {
      height: calc(100vh - 82px);
      height: calc(100svh - 82px);
      @include media-min($lg) {
        height: calc(100vh - 140px);
        height: calc(100svh - 140px);
      }
    }
  }
}
</style>
