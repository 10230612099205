export default {
  choose: 'Vybrať',
  badges: {
    title: 'Odznaky',
    badge: 'Odznak',
    my_badges: 'Moje Odznaky',
    received: 'Získate odznak',
    continue: 'Pokračujte v hraní',
    show_badges: 'Zobraziť odznaky',
    show_badge: 'Pozri si odmeňu',
  },
  record_test: {
    intro: 'TEST NASTAVENÍ KAMERY A MIKROFÓNU',
    content: 'Pred začatím s nástrojom si otestujte svoje nastavenia. V poli nižšie by ste mali vidieť obraz zo svojej kamery. Ak ho vidíte, znamená to, že vaše nastavenia sú pravdepodobne správne - pre istotu nahrajte a prehraťe video. Ak nevidíte obraz z kamery, nemôžete prehrať nahrávku alebo máte iné pochybnosti, kliknite na tlačidlo „RIEŠENIE PROBLÉMOV“.',
    troubleshoot: 'RIEŠENIE PROBLÉMOV',
  },
  questions: {
    progress: 'Otázka',
    accept: 'Potvrdiť',
    question_has_already_been_answered: 'Táto otázka už bola zodpovedaná.',
  },
  not_found: {
    code: '404',
    title: 'Stránka neexistuje',
    content: 'Stránka, ktorú ste hľadali, nebola nájdená. Mohlo byť odstránené, meno bolo zmenené alebo to vôbec neexistovalo',
  },
};
