import swal from 'sweetalert2';
import store from '@/store';
import warningIcon from '@/assets/images/warning.png';

const ALERT_PADDING = '2.5rem 0.5rem 2rem';
const ICON_WIDTH = '100';
const ICON_HEIGHT = '100';

export default {
  warningAlert(params = {}) {
    const defaultParams = {
      confirmButtonText: this.$t('alerts.warning.confirm'),
      imageUrl: warningIcon,
      imageWidth: ICON_WIDTH,
      imageHeight: ICON_HEIGHT,
      width: '44em',
      padding: ALERT_PADDING,
      confirmButtonColor: store.getters['settings/interfaceColor'],
      customClass: {
        confirmButton: 'swal2-confirm-custom',
      },
      title: this.$t('alerts.warning.title'),
      html: store.getters['settings/supportEmail'] ? `${this.$t('alerts.warning.content')} <a href="mailto:${store.getters['settings/supportEmail']}" />${store.getters['settings/supportEmail']}</a>` : this.$t('alerts.warning.content_without_email'),
    };

    return swal.fire({
      ...defaultParams,
      ...params,
    });
  },
  wrongDeviceAlert(params = {}) {
    const defaultParams = {
      confirmButtonText: this.$t('game.back'),
      width: '80em',
      padding: ALERT_PADDING,
      confirmButtonColor: store.getters['settings/interfaceColor'],
      customClass: {
        confirmButton: 'swal2-confirm-custom',
      },
      html: `
      <div style="text-align: left">
        ${this.$t('alerts.unsupported_os.content')}
      </div>
      `,
    };

    return swal.fire({
      ...defaultParams,
      ...params,
    });
  },
  skipStageAlert(hasRecordedData, params = {}) {
    const defaultParams = {
      confirmButtonText: this.$t('game.skip'),
      cancelButtonText: this.$t('game.back'),
      showCancelButton: true,
      reverseButtons: true,
      width: '80em',
      padding: ALERT_PADDING,
      confirmButtonColor: store.getters['settings/interfaceColor'],
      cancelButtonColor: 'white',
      customClass: {
        confirmButton: 'swal2-confirm-custom',
        cancelButton: 'swal2-cancel-custom',
      },
      title: hasRecordedData ? this.$t('alerts.skip_stage_with_record.title') : this.$t('alerts.skip_stage_without_record.title'),
      html: `
      <div style="text-align: center">
        ${hasRecordedData ? this.$t('alerts.skip_stage_with_record.content') : this.$t('alerts.skip_stage_without_record.content')}
      </div>
      `,
      didOpen: () => {
        const customButton = document.querySelector('.swal2-cancel-custom');
        customButton.style.border = `1px solid ${store.getters['settings/interfaceColor']}`;
        customButton.style.color = store.getters['settings/interfaceColor'];
      },
    };

    return swal.fire({
      ...defaultParams,
      ...params,
    });
  },
  instructionCameraAlert(params = {}) {
    const defaultParams = {
      confirmButtonText: this.$t('game.back'),
      width: '100em',
      padding: ALERT_PADDING,
      confirmButtonColor: store.getters['settings/interfaceColor'],
      customClass: {
        confirmButton: 'swal2-confirm-custom',
      },
      html: `
      <div style="text-align: left">
        ${this.$t('alerts.instruction_camera.content')}
      </div>
      `,
    };

    return swal.fire({
      ...defaultParams,
      ...params,
    });
  },
};
