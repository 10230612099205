export default {
  warning: {
    title: 'Došlo k chybě',
    content: 'Zkus to znovu a pokud se chyba bude opakovat dejte nám vědět na',
    content_without_email: 'Zkus to znovu a pokud se chyba bude opakovat, dejte nám vědět na naší technickou podporu',
    confirm: 'Zavřít',
  },
  end_time: {
    title: 'Nahlásit chybu',
    content: 'Pokud během hry dochází k chybě, kontaktujte nás prosím e-mailem na adresu:',
  },
  record_video_error: {
    title: 'Žádný přístup kamerou a/nebo mikrofonem.',
    content: 'Pro dokončení tohoto úkolu změňte svá nastavení a povolte aplikaci přístup k vaší kameře a/nebo mikrofonu.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nepodporované zařízení</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Pro pokračování použijte <strong>jiné zařízení a/nebo jiný prohlížeč.</strong></p>
        <p>Nástroj pro nábor, který chcete použít, obsahuje alespoň jednu úlohu, která vyžaduje zaznamenání vaší odpovědi ve formátu videa. Bohužel ne všechna zařízení a/nebo prohlížeče to umožňujísprávněprovést.</p>
        <p><strong>Pokud vidíte tuto obrazovku, znamená to, že vaše zařízení a/nebo prohlížeč nedokáže zajistit správné nahrání a odeslání video souboru.</strong> Níže najdete doporučená nastavení.</p>
        <br>
        <p><strong>Stolní zařízení:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobilní zařízení:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Jste si jisti, že chcete úkol přeskočit?',
    content: '<p>Nahrávání videodopisu k tomuto úkolu není povinné, ale doporučuje se – pomůže nám vás lépe poznat. Opravdu chcete přeskočit nahrávání videodopisu?</p>',
  },
  skip_stage_with_record: {
    title: 'Jste si jisti, že chcete úkol přeskočit?',
    content: '<p>Chystáte se přeskočit uložení nahrané video odpovědi - nebude dostupná osobě, která provádí výběrový proces. Nahrání video odpovědi pro tuto úlohu není povinné, ale doporučuje se - pomůže nám vás lépe poznat. Jste si jisti, že chcete přeskočit uložení nahrané video odpovědi?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Řešení problémů s kamerou a mikrofonem</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Pokud je nahrávání nebo přehrávání nesprávné, můžete postupovat podle několika kroků, které by měly problém vyřešit. Nejčastější příčiny problémů vyplývají z nastavení zařízení, včetně jiných programů nebo aplikací, které současně využívají kameru a/nebo mikrofon.</p>
      <p>Možná řešení problému:</p>
      <ol>
        <li>Ujistěte se, že prohlížeč má oprávnění k používání mikrofonu a kamery. Pokud ne, změňte nastavení a obnovte stránku.</li>
        <li>Ujistěte se, že žádná jiná aplikace nebo program v současné době nevyužívá kameru/mikrofon (např. MS Teams, Photo Booth nebo FaceTime) - zavřete aplikace, které blokují možnost využití kamery prohlížečem a obnovte stránku.</li>
        <li>Zkontrolujte, zda je váš prohlížeč aktualizován na nejnovější verzi - pokud ne, proveďte aktualizaci a test znovu spusťte.</li>
        <li>Pokud výše uvedené kroky nepomohly, zkuste použít jiný prohlížeč nebo zařízení.</li>
      </ol>
      <p>Pokud problém přetrvává, dejte nám vědět na <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> zasláním informací o zařízení a prohlížeči, na kterých se problém vyskytuje.</p>
      </div>
      <hr>
    `,
  },
};
