<template>
  <div
    v-if="isLoading || error"
    class="recorder-overlay"
  >
    <div
      v-if="error"
      class="recorder-error"
    >
      <span>{{ $t("alerts.record_video_error.title") }}</span>
      <span>{{ $t("alerts.record_video_error.content") }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RecorderOverlay',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.recorder {
  &-error {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 4rem;

    span {
      padding-bottom: 2rem;
      &:nth-child(1) {
        font-size: 1.8rem;
        font-weight: 500;
      }
    }
  }
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $white;
  }
}
</style>
