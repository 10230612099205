import { immerable, produce } from 'immer';

export default class GameQuestionAnswer {
  [immerable] = true

  /**
   * @param {String} id
   * @param {?String} body
   * @param {?Feedback} feedback
   * @param {?Badge} badge
   * @param {?Object} sessionAnswer
   */
  constructor({
    id, body, feedback, badge, sessionAnswer,
  }) {
    this._id = id;
    this._body = body;
    this._feedback = feedback;
    this._badge = badge;
    this._sessionAnswer = sessionAnswer;
  }

  /**
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  updateSessionAnswer() {
  }

  /**
   * @return {GameQuestionAnswer}
   */
  markBadgeAsCompleted() {
    return produce(this, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft._badge = draft._badge?.markAsCompleted();
    });
  }

  /**
   * @return {?String}
   */
  get body() {
    return this._body;
  }

  /**
   * @return {String}
   */
  get id() {
    return this._id;
  }

  /**
   * @return {?Feedback}
   */
  get feedback() {
    return this._feedback;
  }

  /**
   * @return {?Badge}
   */
  get badge() {
    return this._badge;
  }
}
