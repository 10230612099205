<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(submit)">
      <div class="choose-lang__input">
        <SelectLang
          v-model="formData.lang"
          :languages="availableLanguages"
        />
      </div>
      <div class="choose-lang__button">
        <BaseButton
          :is-loading="isLoading"
          type="submit"
        >
          {{ $t("game.start") }}
        </BaseButton>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex';
import SelectLang from '@/components/ChooseLang/SelectLang';
import BaseButton from '@/components/Form/BaseButton';
import * as localeTypes from '@/names/locales.names';

const LANGUAGE_COLLECTION_TYPE = 'LANGUAGE';

export default {
  name: 'ChooseLangForm',
  components: {
    SelectLang,
    BaseButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      languages: [
        {
          value: localeTypes.PL,
          name: 'Polski',
        },
        {
          value: localeTypes.EN,
          name: 'English',
        },
        {
          value: localeTypes.CS,
          name: 'Čeština',
        },
        {
          value: localeTypes.DE,
          name: 'Deutsch',
        },
        {
          value: localeTypes.FR,
          name: 'Français',
        },
        {
          value: localeTypes.HU,
          name: 'Magyar',
        },
        {
          value: localeTypes.IT,
          name: 'Italiano',
        },
        {
          value: localeTypes.NL,
          name: 'Nederlands',
        },
        {
          value: localeTypes.RO,
          name: 'Română',
        },
        {
          value: localeTypes.SK,
          name: 'Slovenčina',
        },
        {
          value: localeTypes.HR,
          name: 'Hrvatski',
        },
        {
          value: localeTypes.PT,
          name: 'Português',
        },
        {
          value: localeTypes.SL,
          name: 'Slovenščina',
        },
        {
          value: localeTypes.UA,
          name: 'Українська',
        },
      ],
      availableLanguages: [],
      formData: {
        lang: '',
      },
    };
  },
  computed: {
    ...mapGetters('settings', ['gameCollections']),
  },
  created() {
    this.checkAvailableLanguages();
  },
  methods: {
    checkAvailableLanguages() {
      const gameCollectionsLanguages = this.gameCollections
        .find((collection) => collection.type === LANGUAGE_COLLECTION_TYPE).games.data
        .map((game) => game.locale);
      this.availableLanguages = this.languages.filter((language) => gameCollectionsLanguages.includes(language.value));
    },
    submit() {
      if (this.isLoading) return;
      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      return {
        locale: this.formData.lang,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.choose-lang {
  &__input {
    position: relative;
    margin: 8.5rem 0 5.8rem;
    max-width: 800px;
    @include media-min($lg) {
      margin: 6.5rem 0 7.2rem;
      max-width: 350px;
    }
    @include media-min($xxl) {
      max-width: 800px;
    }
  }
  &__button {
    padding-bottom: 6rem;
    @include media-min($lg) {
      padding-bottom: 10rem;
    }
  }
  &__error {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding-top: 1rem;
    color: $red;
  }
}
</style>
