<template>
  <div>
    <div class="container__main-photo">
      <BaseImage
        :src="require(`@/assets/images/sideImages/faq.png`)"
        :name="$t('game.name')"
        lazy-mode
      />
    </div>
    <ScrollButton>
      <div class="container">
        <div class="container__wrapper">
          <BackButton class="container__back-button" />
          <div class="container__heading">
            <div class="container__heading--nav">
              <span class="container__heading--intro">
                {{ $t("game.faq") }}
              </span>
              <div class="policies policies--dekstop">
                <DocumentLink />
              </div>
            </div>
          </div>
          <div
            ref="accordion"
            class="container__faq-tab"
          >
            <Accordion>
              <AccordionItem
                v-for="(frequentlyAskedQuestion, index) in frequentlyAskedQuestions"
                :key="`question-${index}`"
              >
                <template #accordion-trigger>
                  <h3>
                    {{ frequentlyAskedQuestion.question }}
                  </h3>
                </template>
                <template #accordion-content>
                  <span v-html="sanitizeHtml(frequentlyAskedQuestion.answer, ['a', 'br', 'b', 'strong'])" />
                </template>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div class="policies policies--mobile">
          <DocumentLink />
        </div>
      </div>
    </ScrollButton>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import sanitize from '@/common/sanitize/sanitizeHtml';
import DocumentLink from '@/components/Document/DocumentLink';
import BaseImage from '@/components/Image/BaseImage';
import ScrollButton from '@/components/Content/ScrollButton';
import BackButton from '@/components/Content/BackButton';
import Accordion from '@/components/Accordion/Accordion';
import AccordionItem from '@/components/Accordion/AccordionItem';

export default {
  name: 'Faq',
  components: {
    DocumentLink,
    BaseImage,
    ScrollButton,
    BackButton,
    Accordion,
    AccordionItem,
  },
  computed: {
    ...mapGetters('settings', ['frequentlyAskedQuestions']),
  },
  methods: {
    ...sanitize,
  },
};
</script>
<style lang="scss" scoped>
.container {
  &__wrapper {
    max-height: none;
  }
  &__back-button {
    padding: 1rem 0 2rem;
    border-bottom: 1px solid $gray400;
    @include media-min($lg) {
      padding: 0 0 2rem;
    }
  }
  &__faq-tab {
    border-top: 3px solid var(--lighter-primary-color);
    margin-top: 1rem;
    h3 {
      font-weight: 500;
      font-size: 1.6rem;
    }
  }
  &__heading {
    position: relative;
    margin-top: 1.5rem;
    &--nav {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
    &--intro {
      color: var(--primary-color);
      text-transform: uppercase;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.8rem;
      margin: 0.8rem 0 0.8rem;
    }
  }
}

.policies {
  align-items: center;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  @include media-min($lg) {
    margin-bottom: 0;
  }
  &--dekstop {
    display: none;
    @include media-min($lg) {
      font-size: 1.4rem;
      display: flex;
    }
  }
  &--mobile {
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    @include media-min($lg) {
      display: none;
    }
  }
}
</style>
