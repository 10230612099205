export default {
  checkbox_required: 'Označi %{name}',
  required: 'Unesite %{name}',
  phone_incorrect: 'Unesite ispravan broj telefona',
  min_length: 'Minimalan broj znakova je: %{length}',
  max_length: 'Maksimalan broj znakova je: %{length}',
  max_file_size: 'Maksimalna veličina datoteke je %{size} mb',
  recaptcha: 'Označi Recaptcha',
  email_incorrect: 'Neispravan format',
  max_value: 'Maksimalna vrijednost mora biti %{value} ili manje',
  min_value: 'Minimalna vrijednost mora biti %{value} ili više',
  numeric: 'Pogrešan format broja',
  numeric_required: '%{name} je obavezno i može sadržavati samo cijele brojeve',
};
