import store from '@/store';
import RecordTest from '@/views/RecordTest';
import {
  RECORD_TEST,
  NOT_FOUND,
} from '@/router/routes.names';
import {
  HTTP_NOT_FOUND,
  HTTP_UNAUTHORIZED,
} from '@/common/axios/codes';

export default {
  path: 'record-test',
  name: RECORD_TEST,
  component: RecordTest,
  beforeEnter: async (to, from, next) => {
    try {
      store.dispatch('layout/startLoading');
      await store.dispatch('session/fetchSessionGameData');
      if (store.getters['session/isGameStarted']) {
        await store.dispatch('flow/redirectToCurrentStage');

        return;
      }

      next();
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) {
        next();

        return;
      }
      if (e.response?.status === HTTP_NOT_FOUND) {
        next({ name: NOT_FOUND });

        return;
      }
      throw new Error(e);
    } finally {
      store.dispatch('layout/stopLoading');
    }
  },
};
