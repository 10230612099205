import api from '@/common/axios/api';
import {
  SET_GAME_DATA,
  SET_LOCALE,
  UPDATE_PAGE_TRANSLATION,
} from '@/store/settings/mutations.names';
import { SET_TIME_LIMIT } from '@/store/session/mutations.names';

import setSiteData from '@/common/siteData/siteData';

export default {
  async fetchData({ commit }, { recruitmentProcessId, sessionId = null }) {
    const endpoint = sessionId === null
      ? `data/${recruitmentProcessId}`
      : `data/${recruitmentProcessId}/${sessionId}`;
    const response = await api.get(endpoint);
    const { data } = response.data;

    commit(`session/${SET_TIME_LIMIT}`, data.game.data.time_limit, { root: true });
    commit(SET_GAME_DATA, data);
    commit(SET_LOCALE, data.game.data.locale);
    setSiteData();
  },
  async fetchPageTranslation({ commit }, { recruitmentProcessId, sessionId, pageType }) {
    const {
      data: { data },
    } = await api.get(`page/${pageType}/${recruitmentProcessId}/${sessionId}`);

    commit(UPDATE_PAGE_TRANSLATION, data);
  },
  setLocale({ commit }, locale) {
    commit(SET_LOCALE, locale);
  },
};
