<template>
  <div class="base-image">
    <Loader
      v-if="!isLoaded && lazyMode"
      :is-component-loader="true"
    />
    <img
      :src="src"
      :alt="name"
      :class="{ 'contain': contain }"
      @load="onImgLoad"
    >
    <div class="base-image__overlay" />
  </div>
</template>
<script>
import Loader from '@/components/Loader/Loader';

export default {
  name: 'BaseImage',
  components: {
    Loader,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    lazyMode: {
      type: Boolean,
      default: false,
    },
    contain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.base-image {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    &.contain {
      object-fit: contain;
    }
  }
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
  }
}
</style>
