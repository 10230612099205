import store from '@/store';
import GameLayout from '@/layouts/GameLayout';
import Faq from '@/views/Faq';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import Cookies from '@/views/Cookies';
import {
  COOKIES,
  PRIVACY_POLICY,
  FAQ,
  NOT_FOUND,
} from '@/router/routes.names';

export default {
  path: 'documents',
  component: GameLayout,
  children: [
    {
      path: 'faq',
      name: FAQ,
      component: Faq,
      beforeEnter: (to, from, next) => {
        const hasFrequentlyAskedQuestionsData = store.getters['settings/hasFrequentlyAskedQuestionsData'];
        if (!hasFrequentlyAskedQuestionsData) {
          next({ name: NOT_FOUND });

          return;
        }

        next();
      },
    },
    {
      path: 'privacy-policy',
      name: PRIVACY_POLICY,
      component: PrivacyPolicy,
    },
    {
      path: 'cookies',
      name: COOKIES,
      component: Cookies,
    },
  ],
};
