<template>
  <BaseLayout :side-image="sideImageOption">
    <template #main>
      <Content :has-footer="false">
        <div class="record-test">
          <span
            class="record-test__intro"
          >
            {{ $t("general.record_test.intro") }}
          </span>
          <p
            class="record-test__content"
            v-html="$t('general.record_test.content')"
          />
          <Recorder :time-limit="60" />
          <div class="record-test__buttons">
            <BaseButton
              class="record-test__button-modal"
              alternative
              @click="instructionCameraAlert"
            >
              <Icon
                name="settings"
                :color="variables.primary"
              />
              <span>{{ $t("general.record_test.troubleshoot") }}</span>
            </BaseButton>
            <BaseButton
              :is-loading="isLoading"
              @click="redirectToFirstUncompletedStage"
            >
              {{ $t("game.continue") }}
            </BaseButton>
          </div>
        </div>
      </Content>
    </template>
  </BaseLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import alerts from '@/common/alerts/alerts';
import BaseLayout from '@/layouts/BaseLayout';
import Content from '@/components/Content/Content';
import BaseButton from '@/components/Form/BaseButton';
import Recorder from '@/components/Video/Recorder';
import { HTTP_UNAUTHORIZED } from '@/common/axios/codes';
import { WELCOME } from '@/names/pages.names';
import Icon from '@/components/Icon/Icon';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'RecordTest',
  components: {
    BaseLayout,
    Content,
    BaseButton,
    Recorder,
    Icon,
  },
  data() {
    return {
      variables,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['sideImage']),
    sideImageOption() {
      return this.sideImage(WELCOME);
    },
  },
  methods: {
    ...alerts,
    ...mapActions('flow', ['redirectToCurrentStage']),
    ...mapActions('session', ['markGameAsStarted']),
    async redirectToFirstUncompletedStage() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        await this.markGameAsStarted();
        await this.redirectToCurrentStage();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          return;
        }
        this.warningAlert();
        throw new Error(JSON.stringify('meta' in (e.response?.data || {}) ? (({ meta, ...errors }) => errors)(e.response.data) : e.response?.data));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.record-test {
  padding: 1.5rem 0 1rem;
  &__intro {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.8rem;
    color: var(--primary-color);
  }
  &__content {
    padding: 1rem 0 1rem;
  }
  &__buttons {
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    @include media-min($sm) {
      flex-direction: row;
    }
  }
  &__button-modal {
    svg {
      display: block;
      min-width: 2.4rem;
      min-height: 2.4rem;
      width: 2.4rem;
      height: 2.4rem;
    }
    span {
      padding-left: 1.6rem;
    }
  }
}
</style>
