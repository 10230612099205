export default {
  warning: {
    title: 'Ocorreu um erro',
    content: 'Tente novamente, e se o erro persistir, avise-nos em',
    content_without_email: 'Tente novamente, e se o erro persistir, entre em contato com o suporte técnico.',
    confirm: 'Fechar',
  },
  end_time: {
    title: 'Relatar um erro',
    content: 'Se ocorrer um erro durante o seu jogo, entre em contato pelo email:',
  },
  record_video_error: {
    title: 'Sem acesso à câmera e/ou microfone.',
    content: 'Para completar esta tarefa, altere as suas configurações e permita que a aplicação tenha acesso à sua câmera e/ou microfone.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Dispositivo não suportado</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Para continuar, por favor, utilize <strong>outro dispositivo e/ou navegador.</strong></p>
        <p>A ferramenta de recrutamento que você pretende utilizar inclui pelo menos uma tarefa que requer que você grave sua resposta em formato de vídeo. Infelizmente, nem todos os dispositivos e/ou navegadores permitem fazer isso corretamente.</p>
        <p><strong>Se você está vendo esta tela, significa que o seu dispositivo e/ou navegador não garantem a gravação e o envio corretos de um arquivo de vídeo.</strong> Abaixo, você encontrará as configurações recomendadas.</p>
        <br>
        <p><strong>Dispositivos de desktop:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Dispositivos móveis:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Tem a certeza de que deseja ignorar a tarefa?',
    content: '<p>A gravação de uma resposta em vídeo para esta tarefa não é obrigatória, mas é recomendada - ajudar-nos-á a conhecer-te melhor. Tem a certeza de que desejas saltar a gravação de uma resposta em vídeo?</p>',
  },
  skip_stage_with_record: {
    title: 'Tem a certeza de que deseja ignorar a tarefa?',
    content: '<p>Pretende pular o salvamento da resposta em vídeo gravada - não estará acessível para a pessoa que conduz o processo de recrutamento. O envio de uma resposta em vídeo para esta tarefa não é obrigatório, mas é recomendado - ajudar-nos-á a conhecer-te melhor. Tem a certeza de que desejas saltar o salvamento da resposta em vídeo gravada?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Resolução de Problemas com Câmera e Microfone</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Se a gravação ou reprodução não estiver correta, você pode seguir alguns passos que devem resolver o problema. As causas mais comuns de problemas vêm das configurações do dispositivo, incluindo outros programas ou aplicações que estão utilizando a câmera e/ou o microfone simultaneamente.</p>
      <p>Soluções possíveis:</p>
      <ol>
        <li>Certifique-se de que o navegador tem permissões para usar o microfone e a câmera. Se não, altere as configurações e atualize a página.</li>
        <li>Garanta que nenhum outro aplicativo ou programa esteja usando a câmera/microfone no momento (por exemplo, MS Teams, Photo Booth ou FaceTime) - feche os aplicativos que estão bloqueando a possibilidade de usar a câmera pelo navegador e atualize a página.</li>
        <li>Verifique se o seu navegador está atualizado para a versão mais recente - se não, faça a atualização e tente o teste novamente.</li>
        <li>Se as etapas acima não ajudaram, tente usar outro navegador ou dispositivo.</li>
      </ol>
      <p>Se o problema persistir, nos informe em <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> enviando informações sobre o dispositivo e navegador nos quais o problema ocorre.</p>
      </div>
      <hr>
    `,
  },
};
