<template>
  <BaseCountdown
    v-if="counting"
    ref="countdown"
    :auto-start="false"
    :left-time="gameTimeLimitInMilliseconds"
    @finish="handleCountdownFinish"
  >
    <template
      v-slot:process="{ timeObj: { h, m, s } }"
    >
      <span class="countdown__timer">{{ `${h}:${m}:${s}` }}</span>
    </template>
    <template
      v-slot:finish
    >
      <span class="countdown__timer">00:00:00</span>
    </template>
    <span class="countdown__text">{{ $t("game.time_left") }}</span>
  </BaseCountdown>
</template>
<script>
import { mapActions } from 'vuex';
import eventBus from '@/common/eventBus';
import alerts from '@/common/alerts/alerts';
import { END_OF_TIME } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED } from '@/common/axios/codes';
import { PAUSE_COUNTDOWN, START_COUNTDOWN } from '@/names/events.names';

export default {
  name: 'Countdown',
  props: {
    gameTimeLeft: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      counting: false,
    };
  },
  computed: {
    gameTimeLimitInMilliseconds() {
      return this.gameTimeLeft * 1000;
    },
  },
  created() {
    this.gameTimeLeft > 0 && this.startCountdown();

    eventBus.$on(START_COUNTDOWN, () => {
      this.startCountdown();
    });
    eventBus.$on(PAUSE_COUNTDOWN, () => {
      this.pauseCountdown();
    });
  },
  methods: {
    ...alerts,
    ...mapActions('session', ['markGameAsTimedOut']),
    async handleCountdownFinish() {
      try {
        await this.markGameAsTimedOut();
        this.$router.push({ name: END_OF_TIME, params: this.$route.params });
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          return;
        }
        this.warningAlert();
        throw new Error(e);
      }
    },
    startCountdown() {
      this.counting = true;
      this.$nextTick(() => {
        this.$refs.countdown.startCountdown();
      });
    },
    pauseCountdown() {
      this.$refs.countdown.pauseCountdown();
    },
  },
};
</script>
<style lang="scss" scoped>
.countdown {
  &__timer {
    background: $gray400;
    padding: 0.2rem 1rem;
    border-radius: 4px;
    margin-right: 1.2rem;
  }
  &__text {
    display: none;
    @include media-min($lg) {
      display: block;
    }
  }
}
</style>
