export default {
  choose: 'Wybierz',
  badges: {
    title: 'Odznaki',
    badge: 'Odznak',
    my_badges: 'Moje Odznaki',
    received: 'Otrzymujesz odznakę',
    continue: 'Graj dalej',
    show_badges: 'Zobacz odznaki',
    show_badge: 'Zobacz nagrodę',
  },
  record_test: {
    intro: 'TEST USTAWIEŃ KAMERY I MIKROFONU',
    content: 'Przed przystąpieniem do narzędzia przetestuj swoje ustawienia. W polu poniżej powinieneś_aś widzieć obraz ze swojej kamery. <strong>Jeśli go widzisz</strong> oznacza to, że Twoje ustawienia są najprawdopodobniej poprawne - <strong>dla pewności nagraj i odtwórz video.</strong> Jeśli nie widzisz obrazu z kamery, nie możesz odtworzyć nagrania lub masz inne wątpliwości kliknij w przycisk “ROZWIĄZYWANIE PROBLEMÓW”.',
    troubleshoot: 'ROZWIĄZYWANIE PROBLEMÓW',
  },
  questions: {
    progress: 'Pytanie',
    accept: 'Zatwierdź',
    question_has_already_been_answered: 'Odpowiedź na pytanie została już udzielona.',
  },
  not_found: {
    code: '404',
    title: 'Strona nie istnieje',
    content: 'Strona, której szukałeś, nie została odnaleziona. Mogła zostać usunięta, zmieniono jej nazwę lub w ogóle nie istniała',
    title_hebe: 'Cześć!',
    content_hebe: 'Proces rekrutacyjny do tegorocznej edycji Management Trainee został zakończony. Zachęcamy do śledzenia innych ofert na naszej stronie <a href="https://kariera.hebe.pl">kariera.hebe.pl</a>',
  },
};
