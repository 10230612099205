<template>
  <button
    class="player-close-button"
    type="button"
    @click="$emit('close-video')"
  >
    <Icon
      name="close"
      :color="variables.white"
    />
  </button>
</template>
<script>
import Icon from '@/components/Icon/Icon';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'PlayerCloseButton',
  components: {
    Icon,
  },
  data() {
    return {
      variables,
    };
  },
};
</script>
<style lang="scss" scoped>
.player-close-button {
  position: fixed;
  top: 10px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 4rem;
  height: 4rem;

  svg {
    fill: $gray400;
    transition: 0.2s fill;
    &:hover {
      fill: $white;
    }
  }
}
</style>
