export default {
  choose: 'Scegli',
  badges: {
    title: 'Distintivi',
    badge: 'Distintivo',
    my_badges: 'I miei Distintivi',
    received: 'Riceverai un distintivo',
    continue: 'Gioca ancora',
    show_badges: 'Visualizza i distintivi',
    show_badge: 'Vedi la ricompensa',
  },
  record_test: {
    intro: 'TEST DELLE IMPOSTAZIONI DI CAMERA E MICROFONO',
    content: "Prima di iniziare con lo strumento, testa le tue impostazioni. Nel campo sottostante, dovresti vedere l'immagine dalla tua camera. Se la vedi, significa che le tue impostazioni sono molto probabilmente corrette - per essere sicuro, registra e riproduci un video. Se non vedi l'immagine dalla camera, non riesci a riprodurre la registrazione o hai altri dubbi, clicca sul bottone “RISOLUZIONE PROBLEMI”.",
    troubleshoot: 'RISOLUZIONE PROBLEMI',
  },
  questions: {
    progress: 'Domanda',
    accept: 'Conferma',
    question_has_already_been_answered: 'Questa domanda è già stata risposta.',
  },
  not_found: {
    code: '404',
    title: 'La pagina non esiste',
    content: 'La pagina che stavi cercando non è stata trovata. Potrebbe essere stata rimossa, rinominata o addirittura non esistere.',
  },
};
