<template>
  <svg
    class="checkmark"
    :class="{
      active: isActive,
    }"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
  >
    <path
      class="checkmark__check"
      fill="none"
      d="M14.1 27.2l7.1 7.2 16.7-16.8"
    />
  </svg>
</template>
<script>

export default {
  name: 'CheckmarkAnimated',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  stroke-width: 2;
  stroke: var(--primary-color);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--primary-color);
  animation: scale .5s ease-in-out .4s both;
  &.active {
    display: block;
  }
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s cubic-bezier(0.65, 0, 0.45, 1) .2s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.3, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $white;
  }
}
</style>
