export default {
  name: '313C',
  recruitment_game: 'Jogo de Recrutamento',
  back: 'Voltar',
  cancel: 'Cancelar',
  continue: 'Continuar',
  skip: 'Ignorar',
  start: 'Iniciar',
  close: 'Fechar',
  help: 'Ajuda',
  time_left: 'Tempo Restante',
  progress: 'Progresso',
  privacy_policy: 'Política de Privacidade',
  cookies_policy: 'Política de Cookies e Termos',
  faq: 'Perguntas e Respostas',
  messages: 'Mensagens',
  unread: 'não lida',
  start_recording: 'Iniciar a gravação',
  stop_recording: 'Parar a gravação',
  remove_record: 'Remover registo',
};
