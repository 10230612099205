<template>
  <div>
    <FadeIn appear>
      <TopBar :percentage="percentageCompleted * 100" />
    </FadeIn>
    <div class="game-layout">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TopBar from '@/components/TopBar/TopBar';
import FadeIn from '@/components/Transition/FadeInTransition';

export default {
  name: 'GameLayout',
  components: {
    TopBar,
    FadeIn,
  },
  computed: {
    ...mapGetters('flow', ['percentageCompleted']),
  },
};
</script>
<style lang="scss" scoped>
.game-layout {
  padding-top: 100px;
  @include media-min($lg) {
    padding-top: 95px;
  }
}
</style>
