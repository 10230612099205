export default {
  checkbox_required: 'Contrassegna %{name}',
  required: 'Scrivi %{name}',
  phone_incorrect: 'Inserisci un numero di telefono valido',
  min_length: 'Il numero minimo di caratteri è: %{length}',
  max_length: 'Il numero massimo di caratteri è: %{length}',
  max_file_size: 'La dimensione massima del file è %{size} mb',
  recaptcha: 'Seleziona ReCaptcha',
  email_incorrect: 'Formato non valido',
  max_value: 'Il valore massimo deve essere %{value} o meno',
  min_value: 'Il valore minimo deve essere %{value} o più',
  numeric: 'Formato numerico non corretto',
  numeric_required: '%{name} è obbligatorio e può contenere solo numeri interi',
};
