import * as names from '@/store/flow/mutations.names';

export default {
  /**
   * @param state
   * @param {Flow} flow
   */
  [names.SET_FLOW](state, flow) {
    state.flow = flow;
  },
  /**
   * @param state
   * @param {Stage} stage
   */
  [names.SET_STAGE](state, stage) {
    state.flow = state.flow.updateStage(stage);
  },
};
