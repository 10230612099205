export default {
  checkbox_required: 'Sélectionner %{name}',
  required: 'Entrer %{name}',
  phone_incorrect: 'Veuillez entrer un numéro de téléphone valide',
  min_length: 'Le nombre minimum de caractères est: %{length}',
  max_length: 'Le nombre maximum de caractères est: %{length}',
  max_file_size: 'La taille maximum du fichier est %{size} mb',
  recaptcha: 'Cochez Recaptcha',
  email_incorrect: 'Format non valide',
  max_value: 'La valeur maximale doit être de %{value} ou moins',
  min_value: 'La valeur minimale doit être de %{value} ou plus',
  numeric: 'Format incorrect du nombre',
  numeric_required: '%{name} est obligatoire et ne peut contenir que des nombres entiers',
};
