<template>
  <li class="accordion__item">
    <div
      class="accordion__trigger"
      :class="{ 'accordion__trigger--active': active }"
      @click="open"
    >
      <slot name="accordion-trigger" />
      <div class="accordion__arrow">
        <i
          class="arrow"
          :class="[{ 'arrow--active': active }, arrowDirection]"
        />
      </div>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="active"
        class="accordion__content"
      >
        <slot name="accordion-content" />
      </div>
    </transition>
  </li>
</template>
<script>
export default {
  name: 'AccordionItem',
  inject: ['Accordion'],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    active() {
      return this.index === this.Accordion.active;
    },
    arrowDirection() {
      return this.active ? 'arrow-up' : 'arrow-down';
    },
  },
  created() {
    this.Accordion.count += 1;
    this.index = this.Accordion.count;
  },
  methods: {
    open() {
      if (this.active) {
        this.Accordion.active = null;

        return;
      }

      this.Accordion.active = this.index;
    },
    start(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion {
  &__item {
    border: 2px solid var(--lightest-primary-color);
    border-width: 0 2px 2px 2px;
    position: relative;
  }
  &__content {
    font-size: 1.6rem;
    margin: 20px 20px;
    word-wrap: break-word;
    @include media-min($md) {
      margin: 20px 30px;
    }
  }
  &__trigger {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    @include media-min($md) {
      padding: 5px 20px;
    }
    @include media-min($lg) {
      &:hover {
        color: var(--primary-color);
        background-color: var(--lightest-primary-color);
      }
    }
    &--active {
      color: var(--primary-color);
    }
  }
  &__arrow {
    margin: auto 0 auto auto;
    .arrow {
      border: solid $black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transition: transform 0.3s ease-out;
      &.arrow-up {
        transform: rotate(-135deg);
      }
      &.arrow-down {
        transform: rotate(45deg);
      }
      &--active {
        border-color: var(--primary-color);
      }
    }
  }
  &-enter-active,
  &-leave-active {
    will-change: height, opacity, margin;
    transition: all 0.3s cubic-bezier(0.22, 0.61, 0.35, 1);
    overflow: hidden;
  }
  &-enter,
  &-leave-to {
    height: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 0;
  }
}
</style>
