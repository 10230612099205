export default {
  warning: {
    title: 'A apărut o eroare',
    content: 'Vă rugăm să încercați din nou și să ne comunicați dacă eroarea se repetă',
    content_without_email: 'Încercați din nou și, dacă eroarea se repetă, contactați asistența tehnică',
    confirm: 'Închidere',
  },
  end_time: {
    title: 'Raportați eroarea',
    content: 'Dacă observați o eroare în timpul jocului, contactați-ne vă rugăm prin e-mail la această adresă:',
  },
  record_video_error: {
    title: 'Fără acces la cameră și/sau microfon.',
    content: 'Pentru a finaliza această sarcină, modificați setările și permiteți aplicației să aibă acces la camera și/sau microfonul dumneavoastră.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Dispozitiv neacceptat</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Pentru a continua, vărugămsăutilizați un <strong>alt dispozitiv și/sau alt browser.</strong></p>
        <p>Instrumentul de recrutare pe care intenționațisă-lutilizați include cel puțin o sarcină care necesităînregistrarea răspunsuluidumneavoastră în format video. Din păcate, nu toate dispozitivele și/saubrowserele permit acest lucru în mod corect.</p>
        <p><strong>Dacăvedeți acest ecran, înseamnăcă dispozitivul și/sau browserul dumneavoastră nu garanteazăînregistrarea și trimiterea corectă a unui fișier video.</strong> Mai jos vețigăsisetările recomandate.</p>
        <br>
        <p><strong>Dispozitive desktop:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Dispozitive mobile:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Sunteți sigur că doriți să săriți peste sarcină?',
    content: '<p>Înregistrarea unui răspuns video pentru această sarcină nu este obligatorie, dar recomandată - ne va ajuta să vă cunoaștem mai bine. Sunteți sigur că doriți să săriți înregistrarea unui răspuns video?</p>',
  },
  skip_stage_with_record: {
    title: 'Sunteți sigur că doriți să săriți peste sarcină?',
    content: '<p>Aveți de gând să săriți peste salvarea răspunsului video înregistrat - acesta nu va fi accesibil persoanei care efectuează procesul de recrutare. Încărcarea unui răspuns video pentru această sarcină nu este obligatorie, dar recomandată - ne va ajuta să vă cunoaștem mai bine. Sunteți sigur că doriți să săriți peste salvarea răspunsului video înregistrat?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Soluționarea Problemelor cu Camera și Microfonul</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Dacă înregistrarea sau redarea este incorectă, puteți urma câțiva pași care ar trebui să rezolve problema. Cele mai comune cauze ale problemelor provin din setările dispozitivului, inclusiv alte programe sau aplicații care folosesc simultan camera și/sau microfonul.</p>
      <p>Soluții posibile:</p>
      <ol>
        <li>Asigurați-vă că browserul are permisiuni pentru a folosi microfonul și camera. Dacă nu, schimbați setările și reîmprospătați pagina.</li>
        <li>Asigurați-vă că nicio altă aplicație sau program nu folosește în acel moment camera/microfonul (de exemplu, MS Teams, Photo Booth sau FaceTime) - închideți aplicațiile care blochează posibilitatea de utilizare a camerei de către browser și reîmprospătați pagina.</li>
        <li>Verificați dacă browserul dvs. este actualizat la ultima versiune - dacă nu, efectuați o actualizare și repetați testul.</li>
        <li>Dacă pașii de mai sus nu au ajutat, încercați să folosiți un alt browser sau dispozitiv.</li>
      </ol>
      <p>Dacă problema persistă, anunțați-ne la <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> trimițând informații despre dispozitiv și browserul pe care apare problema.</p>
      </div>
      <hr>
    `,
  },
};
