<template>
  <BaseLayout
    :is-loading="isPreloading"
    :side-image="sideImageOption"
  >
    <template #main>
      <div class="login__content__shadow">
        <div class="login__content">
          <div class="login__content__wrapper">
            <div v-html="sanitizeHtml(translatedPage)" />
            <LoginForm
              :is-loading="isLoading"
              :continue-btn-name="translatedContinueButton"
              :error="error"
              @handle-submit="submit"
            />
            <div class="login__politics">
              <DocumentLink />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="login__footer">
        <div class="login__politics login__politics--footer">
          <DocumentLink />
        </div>
      </div>
    </template>
  </BaseLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import alerts from '@/common/alerts/alerts';
import sanitize from '@/common/sanitize/sanitizeHtml';
import isVideoRecordingWorks from '@/common/checkDevice/isVideoRecordingWorks';
import LoginForm from '@/components/Login/Form';
import BaseLayout from '@/layouts/BaseLayout';
import DocumentLink from '@/components/Document/DocumentLink';
import { HTTP_BAD_REQUEST } from '@/common/axios/codes';
import { LOGIN } from '@/names/pages.names';
import { CONTINUE_BTN } from '@/names/buttons.names';

export default {
  name: 'Login',
  components: {
    LoginForm,
    BaseLayout,
    DocumentLink,
  },
  data() {
    return {
      error: false,
      isLoading: false,
      isPreloading: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['translation', 'sideImage', 'personalDataAccess']),
    ...mapGetters('flow', ['questionsCount', 'hasGameRecords', 'hasQuestions']),
    translatedPage() {
      return this.translation(LOGIN).body;
    },
    translatedContinueButton() {
      return this.translation(LOGIN).additional_fields.find(
        (button) => button.data.name === CONTINUE_BTN,
      ).data.value;
    },
    sideImageOption() {
      return this.sideImage(LOGIN);
    },
  },
  async mounted() {
    if (!this.personalDataAccess) {
      const { sessionId, recruitmentProcessId } = this.$route.params;
      try {
        this.isPreloading = true;
        await this.login({
          phoneNumber: null,
          sessionId,
        });
        await this.fetchDataAndRedirect(sessionId, recruitmentProcessId);
      } catch (e) {
        this.warningAlert();
        throw new Error(
          JSON.stringify(
            'meta' in (e.response?.data || {})
              ? (({ meta, ...errors }) => errors)(e.response.data)
              : e.response?.data,
          ),
        );
      } finally {
        this.isPreloading = false;
      }
    }
  },
  methods: {
    ...alerts,
    ...sanitize,
    ...isVideoRecordingWorks,
    ...mapActions('auth', ['login']),
    ...mapActions('flow', ['redirectToCurrentStage']),
    ...mapActions('session', ['fetchSessionGameData', 'markGameAsStarted', 'markGameAsFinished']),
    ...mapActions('settings', ['fetchData']),

    async submit({ phoneNumber }) {
      try {
        const { sessionId, recruitmentProcessId } = this.$route.params;
        this.error = false;
        this.isLoading = true;
        await this.login({
          phoneNumber,
          sessionId,
        });
        await this.fetchDataAndRedirect(sessionId, recruitmentProcessId);
      } catch (e) {
        if (e.response?.status === HTTP_BAD_REQUEST) {
          this.error = true;

          return;
        }
        this.warningAlert();
        throw new Error(
          JSON.stringify(
            'meta' in (e.response?.data || {})
              ? (({ meta, ...errors }) => errors)(e.response.data)
              : e.response?.data,
          ),
        );
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDataAndRedirect(sessionId, recruitmentProcessId) {
      await this.fetchData({
        sessionId,
        recruitmentProcessId,
      });
      await this.fetchSessionGameData();
      if (!this.hasQuestions) {
        await this.markGameAsStarted();
        await this.markGameAsFinished();
      }
      if (this.hasGameRecords && !this.isVideoRecordingWorks()) {
        this.wrongDeviceAlert();

        return;
      }
      await this.redirectToCurrentStage();
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  &__content {
    overflow-y: auto;
    &__shadow {
      position: relative;
      &::before,
      &::after {
        display: block;
        height: 5rem;
        left: 0;
        right: 1.5rem;
        position: absolute;
      }
      &::before {
        content: "";
        box-shadow: 0px 50px 20px -20px $white inset;
        top: 0;
      }
      &::after {
        content: "";
        box-shadow: 0px -50px 20px -20px $white inset;
        bottom: 0;
      }
    }
    &__wrapper {
      height: calc(100vh - 82px);
      height: calc(100svh - 82px);
      display: block;
      max-width: 880px;
      padding: 4rem 1.5rem;
      @include media-min($lg) {
        height: calc(100vh - 90px - 140px);
        height: calc(100svh - 90px - 140px);
      }
    }
  }
  &__footer {
    display: flex;
    background: $white;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @include media-min($md) {
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 2.4rem;
    }
    @include media-min($lg) {
      right: auto;
      width: 65%;
      height: 90px;
      padding-bottom: 4rem;
    }
  }
  &__politics {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 3.6rem 0;
    font-size: 1.4rem;
    line-height: 2rem;
    @include media-min($md) {
      justify-content: flex-start;
    }
    @include media-min($lg) {
      display: none;
    }
    &--footer {
      display: none;
      @include media-min($lg) {
        display: flex;
        width: 100%;
        padding: 1rem 0 0 7.5rem;
      }
    }
  }
}
::v-deep .login__content__wrapper {
  h2 {
    text-transform: uppercase;
  }
}
</style>
