import api from '@/common/axios/api';
import store from '@/store';
import eventBus from '@/common/eventBus';
import router from '@/router/index';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';
import GameRecord from '@/domain/entities/flow/GameRecord';
import { SET_STAGE } from '@/store/flow/mutations.names';
import { SET_PROGRESS } from '@/store/layout/mutations.names';
import {
  FINISH, GAME, WELCOME, END_OF_TIME,
} from '@/router/routes.names';
import { START_COUNTDOWN } from '@/names/events.names';

const redirectRoute = (getters) => {
  const basicRouteParams = {
    recruitmentProcessId: store.getters['settings/recruitmentProcessId'],
    sessionId: store.getters['session/sessionId'],
  };

  if (getters.areAllStagesCompleted || store.getters['session/isGameFinished']) {
    return {
      name: FINISH,
      params: basicRouteParams,
    };
  }

  if (store.getters['session/hasGameTimeLimit'] && store.getters['session/remainingTime'] <= 0) {
    return {
      name: END_OF_TIME,
      params: basicRouteParams,
    };
  }

  if (!store.getters['session/isGameStarted'] && router.currentRoute.name !== WELCOME) {
    return {
      name: WELCOME,
      params: basicRouteParams,
    };
  }

  return {
    name: GAME,
    params: {
      ...basicRouteParams,
      stageNumber: getters.currentStageIndex + 1,
    },
  };
};

export default {
  /**
   * @param commit
   * @param {Stage} stage
   * @return {Promise<void>}
   */
  async markStageAsCompleted({ commit }, stage) {
    const response = await api.post(`game-stage/${stage.id}/game-session-stage`);
    const completedAt = new Date(response.data.completed_at);
    if (store.getters['session/hasGameTimeLimit'] && stage instanceof GameRecord) {
      eventBus.$emit(START_COUNTDOWN);
    }

    commit(SET_STAGE, stage.markAsCompleted(completedAt));
  },

  /**
   * @param commit
   * @param {Stage} stage
   * @return {Promise<void>}
   */
  markStageBadgeAsCompleted({ commit }, stage) {
    commit(SET_STAGE, stage.markBadgeAsCompleted());
  },

  /**
   * @param commit
   * @param {GameQuestion} question
   * @return {Promise<void>}
   */
  async markQuestionAsAnswered({ commit }, question) {
    const chosenAnswers = question.chosenAnswersRequestData();
    const response = await api.post('session-question-answer', {
      question_id: question.questionId,
      answers: chosenAnswers,
    });
    const sessionAnswers = response.data.data.map(
      (sessionAnswer) => new GameQuestionSessionAnswer({
        createAt: new Date(sessionAnswer.created_at),
        answer: sessionAnswer.answer,
      }),
    );

    commit(SET_STAGE, question.updateSessionAnswers(sessionAnswers));
  },

  /**
   * @param {Object} params
   * @return {Promise<void>}
   */
  async uploadRecordedData({ commit }, { data, cancelToken }) {
    await api.post('session-record', data, {
      onUploadProgress: ({ loaded, total }) => {
        const progress = Math.round((loaded / total) * 100);

        commit(`layout/${SET_PROGRESS}`, progress, { root: true });
      },
      cancelToken,
    });
  },

  async redirectToCurrentStage({ getters }) {
    const newRoute = redirectRoute(getters);

    if (
      JSON.stringify(newRoute)
      !== JSON.stringify({ name: router.currentRoute.name, params: router.currentRoute.params })
    ) {
      await router.push(newRoute);
    }
  },

  async stopGameTime() {
    await api.post('session/pause');
  },
};
