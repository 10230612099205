<template>
  <div class="progressbar">
    <div class="progressbar__title">
      {{ $t("game.progress") }}
    </div>
    <div class="progressbar__loader">
      <div
        class="progressbar__percentage"
        :style="{ width: `${percentage}%` }"
      />
    </div>
    <div class="progressbar__text">
      <span>
        {{ percent }}%
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressBarDesktop',
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percent() {
      return this.percentage.toFixed();
    },
  },
};
</script>
<style lang="scss" scoped>
.progressbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  color: $black;
  height: 56px;
  @include media-min($xl) {
    flex-direction: row;
    justify-content: flex-end;
  }
  @include media-min($xxl) {
    justify-content: flex-start;
    margin-left: 2rem;
  }
  &__title {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1.6rem;
    @include media-min($xl) {
      border-left: 1px solid $gray200;
      padding: 0 0 0 1.6rem;
    }
  }
  &__text {
    padding: 0 0 0 1.6rem;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid $gray200;
    @include media-min($xl) {
      padding: 0 1.6rem 0 0;
      border-left: none;
    }
    span {
      display: block;
      background: $gray400;
      padding: .2rem 1rem;
      border-radius: 4px;
      @include media-min($xl) {
        background: $white;
        padding: 0;
      }
    }
  }
  &__loader {
    display: none;
    position: relative;
    max-width: 500px;
    width: 100%;
    height: 16px;
    border-radius: 7px;
    overflow: hidden;
    background: $gray400;
    margin: 0 1.4rem;
    @include media-min($xl) {
      display: block;
    }
  }
  &__percentage {
    background-color: var(--primary-color);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition: 0.3s width;
  }
}
</style>
