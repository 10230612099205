import alerts from '@/trans/it/alerts';
import cookies from '@/trans/it/cookies';
import general from '@/trans/it/general';
import game from '@/trans/it/game';
import validation from '@/trans/it/validation';
import auth from '@/trans/it/auth';

export default {
  alerts,
  cookies,
  general,
  game,
  validation,
  auth,
};
