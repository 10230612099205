<template>
  <transition
    name="fade-up"
    mode="out-in"
  >
    <span
      v-if="errors.length"
      class="validation-error"
    >
      {{ errors[0] }}
    </span>
  </transition>
</template>
<script>
export default {
  name: 'ValidationError',
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.validation-error {
  display: block;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 1rem;
  color: $red;
}
</style>
