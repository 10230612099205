<template>
  <component
    :is="require(`@/assets/icons/${name}.svg`)"
    :style="colorProps"
  />
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor']),
    colorProps() {
      return {
        '--fillColor': this.color ? this.color : this.interfaceColor,
        '--strokeColor': this.color ? this.color : this.interfaceColor,
      };
    },
  },
};
</script>
<style scoped>
path[fill],
circle[fill] {
  fill: var(--fillColor);
}
path[stroke],
circle[stroke] {
  stroke: var(--strokeColor);
}
</style>
