export default {
  choose: 'Válassz',
  badges: {
    title: 'Jelvények',
    badge: 'Jelvény',
    my_badges: 'Az én Jelvényeim',
    received: 'Kapsz egy jelvényt',
    continue: 'Játssz tovább',
    show_badges: 'Jelvények megtekintése',
    show_badge: 'Nézd meg a jutalmadat',
  },
  record_test: {
    intro: 'KAMERA ÉS MIKROFON BEÁLLÍTÁSOK TESZTELÉSE',
    content: 'Mielőtt elkezdené használni az eszközt, tesztelje a beállításait. Az alábbi mezőben látnia kellene a kamerájának képét. Ha látja, az jelenti, hogy a beállításai valószínűleg helyesek - a biztonság kedvéért vegyen fel és játsszon le egy videót. Ha nem látja a kamerájának képét, nem tudja lejátszani a felvételt, vagy más kétségei vannak, kattintson a „HIBAELHÁRÍTÁS” gombra.',
    troubleshoot: 'HIBAELHÁRÍTÁS',
  },
  questions: {
    progress: 'Kérdés',
    accept: 'Megerősít',
    question_has_already_been_answered: 'Ez a kérdés már meg lett válaszolva.',
  },
  not_found: {
    code: '404',
    title: 'Az oldal nem létezik',
    content: 'A keresett oldal nem található. Lehet, hogy eltávolították, átnevezték vagy egyszerűen soha nem is létezett.',
  },
};
