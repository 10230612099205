export default {
  name: '313C',
  recruitment_game: 'Rekruteringsspel',
  back: 'Terug',
  cancel: 'Annuleren',
  continue: 'Doorgaan',
  skip: 'Overslaan',
  start: 'Start',
  close: 'Sluiten',
  help: 'Help',
  time_left: 'Resterende tijd',
  progress: 'Voortgang',
  privacy_policy: 'Privacybeleid',
  cookies_policy: 'Cookies beleid en Voorwaarden',
  faq: 'Vragen en antwoorden',
  messages: 'Berichten',
  unread: 'ongelezen',
  start_recording: 'Opname starten',
  stop_recording: 'Opname stoppen',
  remove_record: 'Opname verwijderen',
};
