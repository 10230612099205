export default {
  warning: {
    title: 'Une erreur s\'est produite',
    content: 'Essayez à nouveau et si l\'erreur persiste, contactez-nous par',
    content_without_email: 'Essayez à nouveau et si l\'erreur persiste, contactez le support technique.',
    confirm: 'Fermez',
  },
  end_time: {
    title: 'Signaler une erreur',
    content: 'Si une erreur se produit pendant votre jeu, veuillez nous contacter à l\'adresse email suivante:',
  },
  record_video_error: {
    title: 'Nincs hozzáférés a kamerához és/vagy mikrofonhoz.',
    content: 'A feladat befejezéséhez módosítsa a beállításait, és engedélyezze az alkalmazásnak a kamera és/vagy mikrofon használatát.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Appareil non pris en charge</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Pour continuer, veuillez utiliser un <strong>autre appareil et/ou un autre navigateur.</strong></p>
        <p>L'outil de recrutement que vous avez l'intention d'utiliser comprend au moins une tâche qui nécessite l'enregistrement de votre réponse au format vidéo. Malheureusement, tous les appareils et/ou navigateurs ne permettent pas de le faire correctement.</p>
        <p><strong>Si vous voyez cet écran, cela signifie que votre appareil et/ou votre navigateur ne garantissent pas l'enregistrement correct et l'envoi d'un fichier vidéo.</strong> Ci-dessous, vous trouverez les paramètres recommandés.</p>
        <br>
        <p><strong>Appareils de bureau:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Appareils mobiles:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Êtes-vous sûr de vouloir ignorer la tâche?',
    content: "<p>L'enregistrement d'une réponse vidéo pour cette tâche n'est pas obligatoire, mais recommandé - cela nous permettra de mieux vous connaître. Êtes-vous sûr de vouloir ignorer l'enregistrement d'une réponse vidéo?</p>",
  },
  skip_stage_with_record: {
    title: 'Êtes-vous sûr de vouloir ignorer la tâche?',
    content: "<p>Vous avez l'intention de sauter l'enregistrement de la réponse vidéo enregistrée - elle ne sera pas accessible à la personne qui mène le processus de recrutement. Le téléchargement d'une réponse vidéo pour cette tâche n'est pas obligatoire, mais recommandé - cela nous aidera à mieux vous connaître. Êtes-vous sûr de vouloir sauter l'enregistrement de la réponse vidéo enregistrée?</p>",
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Dépannage de la caméra et du microphone</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Si l'enregistrement ou la lecture est incorrect, vous pouvez suivre quelques étapes qui devraient résoudre le problème. Les causes les plus courantes de problèmes proviennent des paramètres de l'appareil, y compris d'autres programmes ou applications utilisant simultanément la caméra et/ou le microphone.</p>
      <p>Solutions possibles:</p>
      <ol>
        <li>Assurez-vous que le navigateur a les autorisations pour utiliser le microphone et la caméra. Si ce n'est pas le cas, modifiez les paramètres et rafraîchissez la page.</li>
        <li>Assurez-vous qu'aucune autre application ou programme n'utilise actuellement la caméra/le microphone (par exemple, MS Teams, Photo Booth ou FaceTime) - fermez les applications qui bloquent la possibilité d'utiliser la caméra par le navigateur et rafraîchissez la page.</li>
        <li>Vérifiez si votre navigateur est mis à jour vers la dernière version - si ce n'est pas le cas, effectuez une mise à jour et relancez le test.</li>
        <li>Si les étapes ci-dessus n'ont pas aidé, essayez d'utiliser un autre navigateur ou appareil.</li>
      </ol>
      <p>Si le problème persiste, faites-le nous savoir à <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> en envoyant des informations sur l'appareil et le navigateur sur lesquels le problème se produit.</p>
      </div>
      <hr>
    `,
  },
};
