import store from '@/store';
import Finish from '@/views/Finish';
import {
  FINISH,
  NOT_FOUND,
} from '@/router/routes.names';
import {
  HTTP_NOT_FOUND,
  HTTP_UNAUTHORIZED,
} from '@/common/axios/codes';
import { THANK_YOU } from '@/names/pages.names';

export default {
  path: 'finish',
  name: FINISH,
  component: Finish,
  beforeEnter: async (to, from, next) => {
    try {
      const { recruitmentProcessId, sessionId } = to.params;
      store.dispatch('layout/startLoading');
      // TODO refactor needed in the future
      // due to problems with the global loader, we decided to put the data fetching here
      // in the future should be transferred to the component
      await store.dispatch('settings/fetchPageTranslation', {
        pageType: THANK_YOU,
        recruitmentProcessId,
        sessionId,
      });
      await store.dispatch('session/fetchSessionGameData');
      if (!store.getters['flow/areAllStagesCompleted'] && !store.getters['session/isGameFinished']) {
        await store.dispatch('flow/redirectToCurrentStage');
      }

      next();
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) {
        next();

        return;
      }
      if (e.response?.status === HTTP_NOT_FOUND) {
        next({ name: NOT_FOUND });

        return;
      }
      throw new Error(e);
    } finally {
      store.dispatch('layout/stopLoading');
    }
  },
};
