import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/auth';
import settings from '@/store/settings';
import flow from '@/store/flow';
import session from '@/store/session';
import layout from '@/store/layout';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    settings,
    flow,
    session,
    layout,
  },
});
