import { env } from '@/helper';

const SUPPORT_EMAIL = 'SUPPORT_EMAIL';
const LOGO = 'LOGO';
const FAVICON = 'FAVICON';
const PRIMARY_COLOR = 'PRIMARY_COLOR';
const FAQ = 'FAQ';

export default {
  hasGameData(store) {
    return store.data !== null;
  },
  clientName(store) {
    return store.data ? store.data.client.data.name : null;
  },
  gameId(store) {
    return store.data ? store.data.game.data.id : null;
  },
  gameTitle(store) {
    return store.data ? store.data.game.data.name : env.VUE_APP_NAME;
  },
  metaTitle(store) {
    return store.data ? store.data.game.data.meta_title : null;
  },
  topBarTitle(store) {
    return store.data ? store.data.game.data.top_bar_title : null;
  },
  recruitmentProcessId(store) {
    return store.data ? store.data.recruitmentProcess.data.id : null;
  },
  logo(store) {
    return store.data ? store.data.options.client_options.data.find((option) => option.key === LOGO).value : null;
  },
  favicon(store) {
    return store.data ? store.data.options.client_options.data.find((option) => option.key === FAVICON).value : null;
  },
  supportEmail(store) {
    return store.data ? store.data.options.client_options.data.find((option) => option.key === SUPPORT_EMAIL).value : null;
  },
  interfaceColor(store) {
    return store.data ? store.data.options.client_options.data.find((option) => option.key === PRIMARY_COLOR).value : '#FCAF3B';
  },
  frequentlyAskedQuestions(store) {
    return store.data ? store.data.options.game_options.data.find((option) => option.key === FAQ).value : null;
  },
  hasFrequentlyAskedQuestionsData(store) {
    return !!store.data.options.game_options.data.find((option) => option.key === FAQ);
  },
  translation: (store) => (pageTitle) => (store.data ? store.data.pages.find((page) => page.data.type === pageTitle).data : null),
  sideImage: (store) => (pageTitle) => (store.data ? store.data.pages.find((page) => page.data.type === pageTitle).data.side_image_url : null),
  locale(store) {
    return store.locale;
  },
  gameHasCollections(store) {
    return store.data.collections.data.length !== 0;
  },
  gameCollections(store) {
    return store.data.collections.data;
  },
  registerFormBasicFields(store) {
    return store.data.form.basic.data;
  },
  registerFormAdditionalFields(store) {
    return store.data.form.additional.data;
  },
  personalDataAccess(store) {
    return store?.data?.candidate?.data?.personal_data_access ?? false;
  },
  isRegistered(store) {
    return store?.data?.candidate?.data?.is_registered ?? false;
  },
};
