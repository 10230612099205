<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="label"
    :rules="rules"
    mode="eager"
  >
    <Label
      :label="label"
      :tooltip="tooltip"
      :required="required"
    />
    <div
      class="base-select"
      :class="{ 'error': errors.length }"
    >
      <Multiselect
        :value="value"
        :options="items"
        :searchable="false"
        :show-labels="false"
        :multiple="multiple"
        :placeholder="placeholder"
        @select="value => selectValue(value)"
        @remove="value => removeValue(value)"
      />
    </div>
    <ValidationError :errors="errors" />
  </ValidationProvider>
</template>
<script>
import Label from '@/components/Form/Partials/Label';
import ValidationError from '@/components/Form/Partials/ValidationError';

export default {
  name: 'BaseSelect',
  components: {
    Label,
    ValidationError,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Array],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  methods: {
    selectValue(value) {
      if (this.multiple) {
        this.value.push(value);
        this.$emit('input', this.value);

        return;
      }
      this.$emit('input', value);
    },
    removeValue(value) {
      if (this.multiple) {
        const index = this.value.indexOf(value);
        if (index !== -1) {
          this.value.splice(index, 1);
        }
        this.$emit('input', this.value);

        return;
      }
      this.$emit('input', value);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss">
.multiselect__tags {
  min-height: 60px;
  display: flex;
  align-items: center;
}
.multiselect__single,
.multiselect__input,
.multiselect__element,
.multiselect__no-result {
  font-size: 16px;
}
.multiselect__select {
  top: 10px;
}
.multiselect__single,
.multiselect__placeholder {
  padding-left: 10px;
}
.multiselect__tag,
.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight {
  background: var(--primary-color);
  span {
    color: $white !important;
  }
}
.multiselect__tag-icon {
  &:hover {
    background: var(--primary-color);
  }
  &::after {
    color: $white !important
  }
}
.base-select {
  position: relative;
  &.error {
    .multiselect__tags {
      border-color: $red;
    }
  }
}
</style>
