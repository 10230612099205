export default {
  name: '313C',
  recruitment_game: 'Gra Recrutare',
  back: 'Înapoi',
  cancel: 'Anulează',
  continue: 'Continuă',
  skip: 'Sari',
  start: 'Start',
  close: 'Închidere',
  help: 'Ajutor',
  time_left: 'Timp rămas ',
  progress: 'Progres',
  privacy_policy: 'Politica de Confidențialitate',
  cookies_policy: 'Politica cookie-urilor si Conditii',
  faq: 'Intrebari si Răspunsuri',
  messages: 'Mesaje',
  unread: 'necitită',
  start_recording: 'Începeți înregistrarea',
  stop_recording: 'Oprire înregistrare',
  remove_record: 'Ștergeți înregistrarea',
};
