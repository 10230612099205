<template>
  <div>
    <Loader
      v-if="isLoading"
      :is-component-loader="true"
    />
    <div class="base-layout">
      <div class="base-layout__header">
        <div class="base-layout__logo">
          <BaseImage
            :src="logo"
            :name="$t('game.name')"
          />
        </div>
        <router-link
          v-if="hasFrequentlyAskedQuestionsData && !isChooseLangRoute"
          :to="{ name: faqRoute, params: $route.params }"
        >
          <Icon
            name="help"
            class="base-layout__icon"
          />
        </router-link>
      </div>
      <div class="base-layout__container">
        <transition
          appear
          @before-enter="beforeEnter"
          @enter="enter"
        >
          <slot name="main" />
        </transition>
      </div>
      <slot name="footer" />
    </div>
    <div class="base-layout__side-image">
      <BaseImage
        :src="sideImage ? sideImage : require('@/assets/images/sideImages/default.jpg')"
        :name="$t('game.name')"
        lazy-mode
      />
    </div>
  </div>
</template>
<script>
import gsap from 'gsap';
import { mapGetters } from 'vuex';
import { CHOOSE_LANG, FAQ } from '@/router/routes.names';
import BaseImage from '@/components/Image/BaseImage';
import Icon from '@/components/Icon/Icon';
import Loader from '@/components/Loader/Loader';

export default {
  name: 'BaseLayout',
  components: {
    BaseImage,
    Icon,
    Loader,
  },
  props: {
    sideImage: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faqRoute: FAQ,
    };
  },
  computed: {
    ...mapGetters('settings', ['logo', 'clientName', 'hasFrequentlyAskedQuestionsData']),
    isChooseLangRoute() {
      return this.$route.name === CHOOSE_LANG;
    },
  },
  methods: {
    beforeEnter(el) {
      gsap.set(el, { autoAlpha: 0 });
    },
    enter(el, done) {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.4,
          onComplete: done,
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.base-layout {
  position: relative;
  height: 100vh;
  height: 100svh;
  @include media-min($lg) {
    width: 72%;
  }
  @include media-min($xxxxl) {
    width: 76%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.8rem 1.5rem;
    border-bottom: 1px solid $gray400;
    height: 82px;
    @include media-min($lg) {
      height: 140px;
      padding: 6rem 4% 2rem 6%;
      border-bottom: none;
    }
  }
  &__logo {
    max-height: 31px;
    height: auto;
    max-width: 100%;
    @include media-min($md) {
      max-height: 37px;
    }
  }
  &__icon {
    width: 46px;
    height: 46px;
  }
  &__container {
    padding: 0;
    @include media-min($md) {
      padding: 0 2.4rem;
    }
    @include media-min($lg) {
      padding: 0 5%;
    }
  }
  &__side-image {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    height: 100%;
    @include media-min($lg) {
      display: block;
      width: 28%;
    }
    @include media-min($xxxxl) {
      width: 24%;
    }
  }
}
</style>
