import state from '@/store/flow/state';
import mutations from '@/store/flow/mutations';
import getters from '@/store/flow/getters';
import actions from '@/store/flow/actions';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
