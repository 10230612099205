import Stage from '@/domain/entities/flow/Stage';

export default class GameImage extends Stage {
  /**
   * @param {String} id
   * @param {String} type
   * @param {Number} order
   * @param {?Date} completedAt
   * @param {String} title
   * @param {String} imageUrl
   * @param {String} continueBtn
   */
  constructor({
    id,
    type,
    order,
    completedAt,
    title,
    imageUrl,
    continueBtn,
  }) {
    super({
      id,
      type,
      order,
      completedAt,
    });

    this._title = title;
    this._imageUrl = imageUrl;
    this._continueBtn = continueBtn;
  }

  /**
   * @return {String}
   */
  get title() {
    return this._title;
  }

  /**
   * @return {String}
   */
  get imageUrl() {
    return this._imageUrl;
  }

  /**
   * @return {String}
   */
  get continueBtn() {
    return this._continueBtn;
  }
}
