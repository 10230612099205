export default {
  currentStageIndex(store) {
    return store.flow.currentStageIndex();
  },
  currentStage(store) {
    return store.flow.currentStage();
  },
  areAllStagesCompleted(store) {
    return store.flow.areAllStagesCompleted();
  },
  percentageCompleted(store) {
    return store.flow !== null ? store.flow.percentageCompleted() : null;
  },
  questionsCount(store) {
    return store.flow.questionsCount();
  },
  completedQuestionsCount(store) {
    return store.flow.completedQuestionsCount();
  },
  messagesToCurrentStage(store) {
    return store.flow.messagesToCurrentStage();
  },
  badges(store) {
    return store.flow !== null ? store.flow.badges() : [];
  },
  completedBadgesCount(store) {
    return store.flow.completedBadgesCount();
  },
  hasGameRecords(store) {
    return store.flow !== null ? store.flow.hasGameRecords() : false;
  },
  hasQuestions(store) {
    return store.flow !== null ? store.flow.hasQuestions() : false;
  },
};
