export default {
  warning: {
    title: 'An error occured',
    content: 'Please try again and if the error persists let us know on',
    content_without_email: 'Please try again, and if the error persists contact technical support.',
    confirm: 'Close',
  },
  end_time: {
    title: 'Report an error',
    content: 'If an error occurred during your game, please contact us at the email address:',
  },
  record_video_error: {
    title: 'No access to camera and/or microphone.',
    content: 'To complete this task, change your settings and allow the application to access your camera and/or microphone.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Unsupported device</span>
      <hr>
      <div class="swal2-wrapper">
        <p>To continue, please use a <strong>different device and/or browser.</strong></p>
        <p>The recruitment tool you intend to use includes at least one task that requires you to record your response in video format. Unfortunately, not all devices and/or browsers allow this to be done correctly.</p>
        <p><strong>If you see this screen, it means that your device and/or browser does not guarantee the correct recording and submission of a video file.</strong> Below, you will find recommended settings.</p>
        <br>
        <p><strong>Desktop Devices:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobile Devices:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Are you sure you want to skip the task?',
    content: '<p>Recording a video response for this task is not mandatory, but recommended – it will help us get to know you better. Are you sure you want to skip recording a video response?</p>',
  },
  skip_stage_with_record: {
    title: 'Are you sure you want to skip the task?',
    content: '<p>You are going to skip saving the recorded video response – it will not be accessible to the person conducting the recruitment process. Uploading a video response for this task is not mandatory, but recommended – it will help us get to know you better. Are you sure you want to skip saving the recorded video response?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Troubleshooting Camera and Microphone</span>
      <hr>
      <div class="swal2-wrapper">
      <p>If recording or playback is incorrect, you can follow a few steps that should solve the problem. The most common causes of problems stem from device settings, including other programs or applications running simultaneously that use the camera and/or microphone.</p>
      <p>Possible solutions:</p>
      <ol>
        <li>Make sure that the browser has permissions to use the microphone and camera. If not, change the settings and refresh the page.</li>
        <li>Ensure that no other application or program is currently using the camera/microphone (e.g., MS Teams, Photo Booth, or FaceTime) - close the applications that block the possibility of using the camera by the browser and refresh the page.</li>
        <li>Check if your browser is updated to the latest version - if not, perform an update and run the test again.</li>
        <li>If the above steps did not help, try using a different browser or device.</li>
      </ol>
      <p>If the problem persists, let us know at <a href="mailto:support@newgenerationrecruitment.com">support@newgenerationrecruitment.com</a> by sending information about the device and browser on which the issue occurs.</p>
      </div>
      <hr>
    `,
  },
};
