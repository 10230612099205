<template>
  <svg
    class="iconExternal"
    :data-src="url"
    :style="colorProps"
    :width="width"
    :height="height"
  />
</template>
<script>
export default {
  name: 'IconExternal',
  props: {
    url: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#2D2D2D',
    },
    width: {
      type: String,
      default: '25',
    },
    height: {
      type: String,
      default: '25',
    },
  },
  computed: {
    colorProps() {
      return {
        '--fillColor': this.color,
        '--strokeColor': this.color,
      };
    },
  },
};
</script>
<style lang="scss">
.iconExternal {
  path[fill],
  circle[fill] {
    fill: var(--fillColor) !important;
  }
  path[stroke],
  circle[stroke],
  polygon[stroke] {
    stroke: var(--strokeColor) !important;
  }
}
</style>
