export default {
  choose: 'Alege',
  badges: {
    title: 'Insemne',
    badge: 'Insignă',
    my_badges: 'Insemnele mele',
    received: 'Primiți o insignă ',
    continue: 'Joacă mai departe',
    show_badges: 'Vezi insignele',
    show_badge: 'Vezi recompensa',
  },
  record_test: {
    intro: 'TESTAREA SETĂRILOR PENTRU CAMERA ȘI MICROFON',
    content: 'Înainte de a începe cu instrumentul, testați-vă setările. În câmpul de mai jos, ar trebui să vedeți imaginea de la camera dvs. Dacă o vedeți, înseamnă că setările dvs. sunt probabil corecte - pentru a fi sigur, înregistrați și redați un videoclip. Dacă nu vedeți imaginea de la cameră, nu puteți reda înregistrarea, sau aveți alte îndoieli, faceți clic pe butonul „SOLUȚIONAREA PROBLEMELOR”.',
    troubleshoot: 'SOLUȚIONAREA PROBLEMELOR',
  },
  questions: {
    progress: 'Întrebare',
    accept: 'Confirma',
    question_has_already_been_answered: 'Această întrebare a fost deja răspunsă.',
  },
  not_found: {
    code: '404',
    title: 'Pagina nu există',
    content: 'Pagina pe care o căutați nu a fost găsită. Poate fi fost ștearsă, denumirea sa schimbată sau chiar nu a existat.',
  },
};
