/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  size,
  numeric,
  min_value,
  max_value,
} from 'vee-validate/dist/rules';
import i18n from '@/i18n';

extend('required', {
  ...required,
  message: (value) => i18n.t('validation.required', {
    name: value,
  }),
});

extend('email', {
  ...email,
  message: () => i18n.t('validation.email_incorrect'),
});

extend('min', {
  ...min,
  message: (value, args) => i18n.t('validation.min_length', {
    length: args.length,
  }),
});

extend('max', {
  ...max,
  message: (value, args) => i18n.t('validation.max_length', {
    length: args.length,
  }),
});

extend('size', {
  ...size,
  message: (value, args) => i18n.t('validation.max_file_size', {
    size: args.size / 1024,
  }),
});

extend('checkbox_required', {
  ...required,
  message: (value) => i18n.t('validation.checkbox_required', {
    name: value,
  }),
  validate: (value) => !(!value || value === false),
});

extend('min_value', {
  ...min_value,
  message: (value, args) => i18n.t('validation.min_value', {
    value: args.min,
  }),
});

extend('max_value', {
  ...max_value,
  message: (value, args) => i18n.t('validation.max_value', {
    value: args.max,
  }),
});

extend('numeric', {
  ...numeric,
  message: () => i18n.t('validation.numeric'),
});

extend('numeric_required', {
  ...required,
  message: (value) => i18n.t('validation.numeric_required', {
    name: value,
  }),
});
