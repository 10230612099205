<template>
  <div
    class="end"
    :style="{
      backgroundImage: `url(${require('@/assets/images/endOfTime/dashboard.jpg')})`
    }"
  >
    <div class="end__wrapper">
      <div class="end__main-photo">
        <BaseImage
          :src="sideImageOption"
          :name="$t('game.name')"
          lazy-mode
        />
      </div>
      <div class="end__main">
        <div
          class="end__content"
          v-html="sanitizeHtml(translatedPage)"
        />
        <div class="end__buttons">
          <BaseButton
            class="end__button"
            alternative
            @click="showReportView"
          >
            {{ translatedSubmitErrorButton }}
          </BaseButton>
          <BaseButton
            :is-loading="isLoading"
            class="end__button"
            @click="finishGame"
          >
            {{ translatedContinueButton }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import sanitize from '@/common/sanitize/sanitizeHtml';
import alerts from '@/common/alerts/alerts';
import emailIcon from '@/assets/images/email.png';
import BaseButton from '@/components/Form/BaseButton';
import BaseImage from '@/components/Image/BaseImage';
import { FINISH, GAME } from '@/router/routes.names';
import { END_OF_TIME } from '@/names/pages.names';
import { CONTINUE_BTN, SUBMIT_ERROR_BTN } from '@/names/buttons.names';

export default {
  name: 'EndOfTime',
  components: {
    BaseButton,
    BaseImage,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['translation', 'supportEmail', 'clientName', 'sideImage']),
    translatedPage() {
      return this.translation(END_OF_TIME).body;
    },
    translatedSubmitErrorButton() {
      return this.translation(END_OF_TIME).additional_fields.find(
        (button) => button.data.name === SUBMIT_ERROR_BTN,
      ).data.value;
    },
    translatedContinueButton() {
      return this.translation(END_OF_TIME).additional_fields.find(
        (button) => button.data.name === CONTINUE_BTN,
      ).data.value;
    },
    sideImageOption() {
      return this.sideImage(END_OF_TIME);
    },
  },
  methods: {
    ...sanitize,
    ...alerts,
    showReportView() {
      this.warningAlert({
        imageUrl: emailIcon,
        imageWidth: 93,
        imageHeight: 72,
        title: this.$t('alerts.end_time.title'),
        html: `${this.$t('alerts.end_time.content')} <a href="mailto:${this.supportEmail}" />${
          this.supportEmail
        }</a>`,
      });
    },
    finishGame() {
      this.$router.push({ name: FINISH, params: this.$route.params });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === GAME) {
      next(false);

      return;
    }
    next();
  },
};
</script>
<style lang="scss">
.end__content {
  h2 {
    font-size: 3.4rem;
    line-height: 4rem;
    font-weight: 700;
    text-align: center;
    @include media-min($lg) {
      text-align: left;
    }
  }
}
</style>
<style lang="scss" scoped>
.end {
  height: calc(100vh - 100px);
  height: calc(100svh - 100px);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  @include media-min($lg) {
    height: calc(100vh - 95px);
    height: calc(100svh - 95px);
    padding-top: 8rem;
  }
  @include media-min($xl) {
    padding-top: 12rem;
  }
  &__wrapper {
    max-height: calc(100vh - 8rem);
    max-height: calc(100svh - 8rem);
    height: 100%;
    background: $white;
    width: 100%;
    position: relative;
    overflow: hidden;
    @include media-min($lg) {
      max-width: 80vw;
      margin: 0 auto;
      max-height: calc(100vh - 95px - 15rem);
      max-height: calc(100svh - 95px - 15rem);
      padding: 0;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
      border-radius: 4px;
    }
    @include media-min($xl) {
      max-width: 65vw;
      max-height: calc(100vh - 95px - 25rem);
      max-height: calc(100svh - 95px - 25rem);
    }
  }
  &__main-photo {
    position: absolute;
    display: none;
    width: 25%;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    @include media-min($lg) {
      display: block;
    }
  }
  &__main {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 2rem 2rem;
    @include media-min($lg) {
      margin-left: 25%;
      padding: 4rem 3rem;
    }
  }
  &__content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem 1rem 1rem 0;
    @include media-min($lg) {
      padding: 0 1rem 0 0;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    @include media-min($md) {
      flex-direction: row;
    }
  }
  &__button {
    display: flex;
    margin-top: 1.6rem;
    width: 100%;
    @include media-min($md) {
      &:nth-of-type(2) {
        margin-left: 1.6rem;
      }
    }
  }
}
::v-deep .score {
  u {
    font-weight: 500;
    color: var(--primary-color);
  }
}
::v-deep .feedback {
  font-weight: 500;
  color: var(--primary-color);
}
</style>
